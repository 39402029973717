import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthTokens } from "utils/auth0/auth0API";
import { BASE_URL } from "../../../@Constants";
import { UserTasksType } from "../../../@types";
import { AppendContentTypeHeader } from "./UsersAPI";

export const tasksAPI = createApi({
    reducerPath: "tasksAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: async (headers) => {
            const data = await getAuthTokens();
            const access_token = data?.access_token;
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getTasks: builder.query<UserTasksType, null>({
            query: () => ({
                url: "/tasks",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const { useGetTasksQuery } = tasksAPI;
