import {
    DownOutlined,
    ExclamationCircleTwoTone,
    LoadingOutlined,
    WarningTwoTone,
} from "@ant-design/icons";
import { Divider, Modal, Tooltip, Typography } from "antd";
import { CashupTextStatus } from "Pages/CashupHome/CashupTabs/POS/@types";
import React, { FC, useEffect, useRef, useState } from "react";
import { sanitiseString, toTitleCase } from "utils/utilities";
import {
    StyledCellValue,
    StyledModalEditingContent,
    StyledCell,
    StyledInput,
} from "./Table.style";
import { TableConfigs } from "./TableHeader";
import "./Cell.css";
import { prependDollarSign } from "@Constants";
import { ModalFooter } from "Components/Form/ModalFooter";
import styled from "styled-components";
import { denominationsColumn } from "Pages/CashupHome/CashupTabs/SAFE/SafePanel";

const StyledModal = styled(Modal)`
    .ant-modal-content {
        width: fit-content;
    }
`;
interface Props {
    onClick: () => void;
    onValueChanged: (updatedValue: number) => void;
    readOnly: boolean;
    value: number | string;
    onCloseEditModal: () => void;
    tableConfigs: TableConfigs;
    col: number;
    row: number;
    selected?: boolean;
    editing?: boolean;
    onDoubleClick?: () => void;
    subValue?: string;
    editModal?: (closeModal: () => void) => JSX.Element;
    modalTitle?: string;
    statusField?: CashupTextStatus;
    hideEditModalFooter?: boolean;
    initialEditingValue?: string;
    isExplicitlyNotTitleCell?: boolean;
    errorHighlighting?: boolean;
    nanEmptyInput?: boolean;
    evenDistribution?: boolean;
}

const StatusFieldHelper = ({
    statusField,
    children,
}: {
    statusField?: CashupTextStatus;
    children: JSX.Element;
}) => {
    if (statusField) {
        if (statusField === "calculating") {
            return <LoadingOutlined style={{ fontSize: "20px" }} />;
        } else if (statusField === "no cash count entered") {
            return (
                <Tooltip
                    title={toTitleCase(statusField)}
                    overlayStyle={{ whiteSpace: "pre-line" }}
                >
                    <ExclamationCircleTwoTone
                        twoToneColor={"#0099FF"}
                        style={{ fontSize: "20px" }}
                    />
                </Tooltip>
            );
        } else {
            return (
                <Tooltip
                    title={toTitleCase(statusField)}
                    overlayStyle={{ whiteSpace: "pre-line" }}
                >
                    <WarningTwoTone
                        twoToneColor={"#FFBF00"}
                        style={{ fontSize: "20px" }}
                    />
                </Tooltip>
            );
        }
    } else {
        return children;
    }
};

export const Cell: FC<Props> = ({
    selected = false,
    editing = false,
    onValueChanged,
    value,
    readOnly,
    editModal,
    modalTitle,
    onCloseEditModal,
    tableConfigs,
    col,
    subValue,
    statusField,
    hideEditModalFooter,
    initialEditingValue,
    onDoubleClick,
    onClick,
    row,
    isExplicitlyNotTitleCell,
    errorHighlighting,
    nanEmptyInput,
    evenDistribution,
}) => {
    const titleCell = typeof value === "string" && isExplicitlyNotTitleCell !== true;
    const initialValue = titleCell ? 0 : value;
    const [editingContent, setEditingContent] = useState<string>("");
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setEditingContent(String(initialEditingValue ?? initialValue));
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 50);
    }, [initialEditingValue, editing, initialValue]);

    const cellProps = {
        readOnly,
        highlightBorder: selected && readOnly,
        titleCell,
        errorHighlighting,
    };

    let cellContainerTestId = `Cell-Container-${row}-${col}`;
    let modalEditingCellContainerTestId = `Modal-Editing-Cell-Container-${row}-${col}`;

    if (selected) {
        cellContainerTestId += " selected";
        modalEditingCellContainerTestId += " selected";
    }

    if (editModal && modalTitle) {
        return (
            <StyledCell
                {...cellProps}
                numberOfItems={tableConfigs.length}
                borderRight={tableConfigs[col]?.separateColumn ?? false}
                onClick={onDoubleClick}
                data-testid={modalEditingCellContainerTestId}
                evenDistribution={evenDistribution}
            >
                <StyledCellValue
                    {...cellProps}
                    highlightBorder={selected && !readOnly}
                    data-testid={`Cell-Value-${row}-${col}`}
                    noPaddingLeft={false}
                >
                    <StyledModalEditingContent>
                        <span>{prependDollarSign(value)}</span>
                        <DownOutlined style={{ marginLeft: 8 }} />
                    </StyledModalEditingContent>
                    <StyledModal
                        open={editing && selected}
                        footer={null}
                        onCancel={onCloseEditModal}
                        title={
                            <Typography.Title
                                style={{ marginBottom: 0, padding: 24 }}
                                level={4}
                            >
                                {modalTitle}
                            </Typography.Title>
                        }
                        destroyOnClose
                        keyboard={false}
                        bodyStyle={{ width: "fit-content" }}
                    >
                        <React.Fragment>
                            {hideEditModalFooter ? (
                                <> {editModal(onCloseEditModal)}</>
                            ) : (
                                <>
                                    {editModal(onCloseEditModal)}
                                    <Divider style={{ padding: 0, margin: 0 }} />
                                    <ModalFooter
                                        onClose={onCloseEditModal}
                                        onSave={onCloseEditModal}
                                    />
                                </>
                            )}
                        </React.Fragment>
                    </StyledModal>
                </StyledCellValue>
            </StyledCell>
        );
    }

    return (
        <StyledCell
            {...cellProps}
            numberOfItems={tableConfigs.length}
            borderRight={tableConfigs[col]?.separateColumn ?? false}
            titleCell={titleCell && statusField === undefined}
            onDoubleClick={onDoubleClick}
            onClick={onClick}
            data-testid={cellContainerTestId}
            evenDistribution={evenDistribution}
        >
            {editing && selected && !titleCell && !readOnly ? (
                <StyledInput
                    data-testid={`Cell-Input-${row}-${col}`}
                    value={editingContent}
                    onChange={({ currentTarget: { value } }) => {
                        const sanitisedAmount = sanitiseString(value);
                        setEditingContent(sanitisedAmount);

                        const emptyValue = nanEmptyInput === true ? Number.NaN : 0;
                        // If the field is empty we "zero" out the value
                        onValueChanged(
                            sanitisedAmount.length === 0
                                ? emptyValue
                                : Number(value.replaceAll(",", ""))
                        );
                    }}
                    ref={inputRef}
                    prefix="$"
                />
            ) : (
                <StyledCellValue
                    {...cellProps}
                    titleCell={titleCell && statusField === undefined}
                    highlightBorder={selected && !readOnly}
                    data-testid={`Cell-Value-${row}-${col}`}
                    noPaddingLeft={titleCell || readOnly}
                    isVariance={
                        tableConfigs[col]?.columnTitle
                            .toLowerCase()
                            .includes("variance") && !!Number(value)
                    }
                >
                    <StatusFieldHelper statusField={statusField}>
                        {subValue ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                }}
                            >
                                <span>{value}</span>
                                <span>{subValue}</span>
                            </div>
                        ) : (
                            <span>{prependDollarSign(value)}</span>
                        )}
                    </StatusFieldHelper>
                </StyledCellValue>
            )}
        </StyledCell>
    );
};
