import Pusher from "pusher-js";
import React, { useEffect, createContext, useState, useContext } from "react";
import { useGetAppKeyQuery } from "Redux/StateSlices/Pusher";

export type PusherContextProps = { pusher: Pusher | undefined };

const PusherContext = createContext<PusherContextProps>({
    pusher: undefined,
});

export const PusherProvider: React.FC = ({ children }) => {
    const [pusher, setPusherInstance] = useState<Pusher>();
    const { data: appKey, refetch } = useGetAppKeyQuery(null);

    useEffect(() => {
        window.addEventListener("login", refetch);
        return () => window.removeEventListener("login", refetch);
    }, []);

    useEffect(() => {
        if (!pusher) {
            if (appKey) {
                setPusherInstance(
                    new Pusher(appKey.pusher_app_key, {
                        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                    })
                );
            }
        }
    }, [appKey, pusher]);

    return (
        <PusherContext.Provider value={{ pusher }}>
            {children}
        </PusherContext.Provider>
    );
};

export const usePusher = (): PusherContextProps => {
    const contextValue = useContext(PusherContext);

    if (!contextValue) {
        throw "Please ensure this component is wrapped by PusherProvider";
    }

    return contextValue as PusherContextProps;
};
