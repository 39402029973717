import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";
import styled, { css } from "styled-components";

interface Props {
    openModal?: () => void;
    disabled?: boolean;
    buttonText?: string;
    customerInformationNeeded?: boolean;
    isTenderSplitFormNotBalanced?: boolean;
}

const StyledButtonDropdown = styled(Button)<{
    customerinformationneeded?: boolean;
    isTenderSplitFormBalanced?: boolean;
}>`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    color: #353940;
    background: #f9fafb;
    border-radius: 4px;

    &:not(:first-child) {
        margin-top: 8px;
    }

    border: ${(props) =>
            props.customerinformationneeded || props.isTenderSplitFormBalanced
                ? "1px solid #ff5c5c;"
                : "1px solid #dadfe7;"}
        ${(props) =>
            (props.customerinformationneeded || props.isTenderSplitFormBalanced) &&
            "box-shadow: 0 0 1px 2px #ff8a8a;"}
        ${(props) =>
            (props.customerinformationneeded || props.isTenderSplitFormBalanced) &&
            css`
                &:hover {
                    border: 1px solid #ff5c5c !important;
                    box-shadow: 0 0 1px 2px #ff5c5c !important;
                }
            `};
`;

export const SplitModalField: React.FC<Props> = ({
    openModal,
    disabled,
    buttonText,
    customerInformationNeeded,
    isTenderSplitFormNotBalanced,
}) => {
    return (
        <Tooltip
            overlayInnerStyle={{
                backgroundColor: "rgba(255,255,255,1)",
                color: "#ff5c5c",
            }}
            title={
                isTenderSplitFormNotBalanced ? "Tender Split Form not balanced" : ""
            }
        >
            <StyledButtonDropdown
                onClick={openModal}
                disabled={disabled}
                customerinformationneeded={customerInformationNeeded}
                isTenderSplitFormBalanced={isTenderSplitFormNotBalanced}
            >
                {buttonText ?? " "}
                <EditOutlined />
            </StyledButtonDropdown>
        </Tooltip>
    );
};
