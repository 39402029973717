import { Space } from "antd";
import React from "react";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    ExtendedVenueItem,
    VenuesCashupsResponse,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { CollapsibleTable } from "../POS/utils/Panel";
import { CashupTabHOC, TabHOCComponentProps } from "../POS/utils/PanelHOC";
import { ATMPanel } from "./ATMPanel";

interface Props extends TabHOCComponentProps {
    cashups: VenuesCashupsResponse;
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    selectedDate: string;
    venuesData: ExtendedVenueItem[];
}

const ATMTabComponent: React.FC<Props> = ({
    cashups,
    locations,
    selectedDate,
    hierarchicalLocations,
    venuesData,
    currentTableInFocus,
    setCurrenTableInFocus,
}) => {
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {cashups.locations.atm_locations.map(
                ({ name, cashup_ids, sub_locations }, index) => {
                    const panelId = `${selectedDate}-${index}`;

                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <ATMPanel
                                key={name}
                                name={name}
                                cashupIds={cashup_ids}
                                subLocations={sub_locations}
                                locations={locations}
                                hierarchicalLocations={hierarchicalLocations}
                                venuesData={venuesData}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                            />
                        </CollapsibleTable>
                    );
                }
            )}
        </Space>
    );
};

export const ATMTab = CashupTabHOC(ATMTabComponent);
