export const TOP_BANNER_EVENT_NAME = "top-banner-update-event";

export const handleBalancesEvent = (
    data: any,
    setTopBannerValues: React.Dispatch<
        React.SetStateAction<
            | {
                  total_sales: number;
                  cash_increase: number;
                  bankable: number;
              }
            | undefined
        >
    >
) => {
    try {
        const BannerValues: {
            total_sales: number;
            cash_increase: number;
            bankable: number;
        } = {
            total_sales: data["total_sales"],
            cash_increase: data["cash_increase"],
            bankable: data["bankable"],
        };
        window.dispatchEvent(new Event(TOP_BANNER_EVENT_NAME));
        setTopBannerValues(BannerValues);
    } catch (e) {
        console.log("HBE invalid response", data);
        setTopBannerValues(undefined);
    }
};
