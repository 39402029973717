import {
    PromptCategory,
    ShiftReportPrompt,
} from "Pages/CashupReport/CashupReportModel";
import {
    ShiftReportPromptRecord,
    useCreateShiftReportPromptRecord,
    useUpdateShiftReportPromptRecord,
} from "Pages/CashupReport/Hooks/useShiftReportPromptRecord";
import React, { useEffect, useMemo } from "react";
import { dayjsFormat, toQTCDate } from "utils/date-utils";
import { CashupDailyReportItemNew } from "./CashupDailyReportItemNew";
import { Dictionary } from "lodash";

interface Props {
    date: string;
    venueId: string;
    shiftReportPrompts: ShiftReportPrompt[];
    shiftReportPromptRecordsByPromptId: Record<string, ShiftReportPromptRecord>;
    shiftReportPromptsGroupedById: Dictionary<ShiftReportPrompt[]>;
}

let CashupDailyReportCommentsNew: React.FC<Props> = ({
    date,
    venueId,
    shiftReportPrompts,
    shiftReportPromptRecordsByPromptId,
    shiftReportPromptsGroupedById,
}) => {
    const correctDateFormat = useMemo(
        () =>
            toQTCDate(date, dayjsFormat.dayMonthYearSeparateByDash).format(
                dayjsFormat.default.format
            ),
        [date]
    );
    const shiftTypeKeys = useMemo(() => {
        return Object.keys(shiftReportPromptsGroupedById);
    }, [shiftReportPromptsGroupedById]);

    const { mutate: createShiftReportPromptRecord } =
        useCreateShiftReportPromptRecord({ date, venueId });
    const { mutate: updateShiftReportPromptRecord } =
        useUpdateShiftReportPromptRecord({ date, venueId });

    return (
        <>
            {shiftTypeKeys.map((currentPromptType, index) => {
                return (
                    <div key={index}>
                        <h3
                            style={{
                                fontWeight: "bold",
                                padding: "12px 12px 0px 12px",
                            }}
                        >
                            {PromptCategory[parseInt(currentPromptType)]}
                        </h3>
                        {shiftReportPromptsGroupedById[currentPromptType].map(
                            ({ promptId, promptText, promptType }) => {
                                const promptRecord =
                                    shiftReportPromptRecordsByPromptId[promptId];

                                return (
                                    <CashupDailyReportItemNew
                                        key={promptId}
                                        onEditableColumnContentChanged={(
                                            newContent
                                        ) => {
                                            if (promptRecord?.id) {
                                                updateShiftReportPromptRecord({
                                                    promptRecordId: promptRecord.id,
                                                    body: newContent,
                                                });
                                            } else {
                                                createShiftReportPromptRecord({
                                                    body: newContent,
                                                    date: correctDateFormat,
                                                    promptId,
                                                });
                                            }
                                        }}
                                        columns={[
                                            { content: promptText },
                                            {
                                                content: promptRecord?.body ?? "",
                                                editable: true,
                                            },
                                        ]}
                                        promptType={promptType}
                                    />
                                );
                            }
                        )}
                    </div>
                );
            })}
        </>
    );
};

CashupDailyReportCommentsNew = React.memo(CashupDailyReportCommentsNew);

export { CashupDailyReportCommentsNew };
