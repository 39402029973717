import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthTokens } from "utils/auth0/auth0API";
import { BASE_URL } from "../../../@Constants";
import { ClassItem } from "../../../@generated";
import { AppendContentTypeHeader } from "./UsersAPI";

export type ExtendedClassItem = ClassItem & {
    class_id: string;
    external_id: string;
    active: number;
    name: string;
    group_id: string;
    parent_id: string; // TODO NONE CHECK
    class_type: string;
};

export const classesAPI = createApi({
    reducerPath: "ClassesAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: async (headers) => {
            const data = await getAuthTokens();
            const access_token = data?.access_token;
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getClasses: builder.query<ExtendedClassItem[], null>({
            query: () => ({
                url: "/classes",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const { useGetClassesQuery } = classesAPI;
