import React from "react";
import { FieldData } from "@interfaces";
import { Tooltip } from "antd";
import { ToolTips } from "../../../../tooltip";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";

export const applyTooltipToModalHeader = (
    title:
        | "New Sale Transaction"
        | "New Deposit In Transaction"
        | "New Deposit Out Transaction"
        | "New Transfer Transaction"
        | "New Cash Count Transaction"
        | "New Payment Transaction"
        | "New Eftpos Count Transaction"
        | "Out Type"
        | "Type Select"
        | "Track Balances"
        | "Tender Split"
        | "Class Split"
        | "Customer Details"
) => (
    <Tooltip
        title={ToolTips.ModalHeaderFields[title]}
        mouseEnterDelay={0.5}
        placement="bottom"
    >
        {title}
    </Tooltip>
);
export const appendVenueName = (
    location: ExtendedLocationItemWithChildren,
    venueNameById:
        | {
              [venueID: string]: string;
          }
        | undefined
) => {
    const venueName =
        venueNameById && location.venue ? venueNameById[location.venue] : undefined;

    if (venueName) return `${venueName}: ${location.name}`;
    return location.name;
};

export const updateSpecificFormField = (
    fieldData: FieldData[],
    setFieldData: (input: FieldData[]) => void,
    keyToMatch: string,
    valueToInsert: string | number | undefined,
    resetTouched = false
) => {
    if (valueToInsert === undefined) return;
    if (fieldData.length === 0) return undefined;
    const INDEX_OF_VALUE = fieldData.findIndex((value) => {
        if (Array.isArray(value.name)) {
            return value.name[0] === keyToMatch;
        } else {
            return value.name === keyToMatch;
        }
    });
    const ARRAY_COPY = [...fieldData];

    const ReplacementObject: { name: string[]; value: any; touched?: boolean } = {
        name: [keyToMatch],
        value: valueToInsert.toString(),
    };
    if (resetTouched) {
        ReplacementObject["touched"] = false;
    }
    ARRAY_COPY.splice(INDEX_OF_VALUE, 1, ReplacementObject);

    setFieldData(ARRAY_COPY);
};

// Old approach without "touched field"
export const fetchFromArrayBasedOnKey = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    return (Object.values(RESULT)[0] as string) ?? "";
};

export const fetchFromArrayBasedOnKeyWithTouched = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    return (Object.values(RESULT)[0] as string) ?? "";
};

export const fetchBooleanFromArrayBasedOnKey = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    return (Object.values(RESULT)[0] as boolean) ?? undefined;
};

export const fetchNumberFromArrayBasedOnKey = (
    keyToMatch: string,
    arrayToSearch: any[]
) => {
    if (arrayToSearch.length === 0) return undefined;
    const RESULT = arrayToSearch.find((value) => {
        const IsMatch = Object.keys(value).find((key) => key == keyToMatch);
        return IsMatch !== undefined;
    });

    if (RESULT === undefined) return undefined;
    const VALUE = parseFloat(Object.values(RESULT)[0] as string);

    if (Number.isNaN(VALUE)) {
        return undefined;
    }
    return VALUE;
};
