import React, { useEffect } from "react";
import { DefaultLayout } from "Components/Layout";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { CashupDetailedReport } from "./Components/CashupDetailedReport";
import { Space } from "StyledComponents/Space";
import { CashupReportSelector } from "./Components/CashupReportSelector";
import { useIntializeCashupReport } from "./Hooks/useIntializeCashupReport";
import { useAppSelector } from "Redux/TypedReduxFunctions";
import { CashupReportTable } from "./Components/CashupReportTable";

export const CashupReportSummaryPage = () => {
    const { ready } = useIntializeCashupReport();

    const { selectedReport } = useAppSelector((state) => state.shiftReportSlice);

    useEffect(() => {
        document.title = "Cashup - Summary Report";
    }, []);
    if (!ready) {
        return <QuantacoLoader size={100} />;
    }

    return (
        <DefaultLayout skipLayoutWrapper>
            <Space $fluid size="large" direction="vertical" style={{ padding: 24 }}>
                <CashupReportSelector shouldShowReportSelector />
                {selectedReport === "Detailed" ? (
                    <CashupDetailedReport />
                ) : (
                    <CashupReportTable />
                )}
            </Space>
        </DefaultLayout>
    );
};
