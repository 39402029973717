import { configureStore } from "@reduxjs/toolkit";
import { transactionsAPI } from "./StateSlices/GroupData/TransactionsAPI";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { usersAPI } from "./StateSlices/GroupData/UsersAPI";
import { cashupsAPI } from "./StateSlices/GroupData/CashupsAPI";
import { groupdataAPI } from "./StateSlices/GroupDataAPI";
import { locationsAPI } from "./StateSlices/GroupData/LocationsAPI";
import { tasksAPI } from "./StateSlices/GroupData/TasksAPI";
import { classesAPI } from "./StateSlices/GroupData/ClassesAPI";
import { venuesAPI } from "./StateSlices/GroupData/VenuesAPI";
import { accountsAPI } from "./StateSlices/GroupData/AccountsAPI";
import { entitiesAPI } from "./StateSlices/GroupData/EntitiesAPI";
import { departmentsAPI } from "./StateSlices/GroupData/DepartmentAPI";
import { pusherAPI } from "./StateSlices/Pusher";
import shiftReportSlice from "./StateSlices/ShiftReportState";
import collaborationTableSlice from "./StateSlices/CollaborationTableState";

export const store = configureStore({
    reducer: {
        [transactionsAPI.reducerPath]: transactionsAPI.reducer,
        [usersAPI.reducerPath]: usersAPI.reducer,
        [cashupsAPI.reducerPath]: cashupsAPI.reducer,
        [groupdataAPI.reducerPath]: groupdataAPI.reducer,
        [locationsAPI.reducerPath]: locationsAPI.reducer,
        [tasksAPI.reducerPath]: tasksAPI.reducer,
        [classesAPI.reducerPath]: classesAPI.reducer,
        [venuesAPI.reducerPath]: venuesAPI.reducer,
        [accountsAPI.reducerPath]: accountsAPI.reducer,
        [entitiesAPI.reducerPath]: entitiesAPI.reducer,
        [departmentsAPI.reducerPath]: departmentsAPI.reducer,
        [pusherAPI.reducerPath]: pusherAPI.reducer,
        shiftReportSlice,
        collaborationTableSlice,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            transactionsAPI.middleware,
            usersAPI.middleware,
            cashupsAPI.middleware,
            groupdataAPI.middleware,
            locationsAPI.middleware,
            tasksAPI.middleware,
            classesAPI.middleware,
            venuesAPI.middleware,
            accountsAPI.middleware,
            entitiesAPI.middleware,
            departmentsAPI.middleware,
            pusherAPI.middleware
        ),
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>; // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
