import { TableDataType } from "Pages/CashupHome/CashupTabs/POS/POSPanel";
import { convertFormDataToKeyValueWithTouched } from "utils/utilities";
import { AntDFormStateWithoutSplit } from "@types";
import cloneDeep from "lodash/cloneDeep";
import { TransferV2Item } from "@generated";
import {
    SalesCountTransactionInit,
    TransferTransactionInit,
} from "Pages/CashupHome/CashupTabs/POS/@types";

export const onTransferTransactionSubmission = (
    data: AntDFormStateWithoutSplit[],
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataDeepCopy = cloneDeep(tableData);
    const TransferTransactions: TransferTransactionInit[] = [];

    data.forEach((currentPaymentTransaction) => {
        const formData = convertFormDataToKeyValueWithTouched(
            currentPaymentTransaction.formData
        );

        try {
            const TransferTransaction: TransferV2Item = {
                from_location_id: formData["From"].value,
                to_location_id: formData["To"].value,
                amount: formData["Amount"].value,
            };
            TransferTransactions.push(TransferTransaction);
        } catch (e) {
            return;
        }
    });

    tableDataDeepCopy[rowIndex].transfer_transactions = TransferTransactions;

    onRowDataChange(rowIndex, tableDataDeepCopy);
};

export const onTransferToTransactionSubmission = (
    data: AntDFormStateWithoutSplit[],
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataDeepCopy = cloneDeep(tableData);

    const TransferTransactions: TransferTransactionInit[] = [];
    data.forEach((currentPaymentTransaction) => {
        const formData = convertFormDataToKeyValueWithTouched(
            currentPaymentTransaction.formData
        );

        try {
            const TransferTransaction: TransferV2Item = {
                from_location_id: formData["From"].value,
                to_location_id: formData["To"].value,
                amount: formData["Amount"].value,
            };
            TransferTransactions.push(TransferTransaction);
        } catch (e) {
            console.log({ e });
            return;
        }
    });

    tableDataDeepCopy[rowIndex].transfer_transactions_to = TransferTransactions;

    onRowDataChange(rowIndex, tableDataDeepCopy);
};

export const onSalesCountTransactionSubmission = (
    data: AntDFormStateWithoutSplit[],
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void,
    currentLocationID: string
) => {
    const tableDataDeepCopy = cloneDeep(tableData);

    const SalesCountTransactions: SalesCountTransactionInit[] = [];
    data.forEach((currentPaymentTransaction) => {
        const formData = convertFormDataToKeyValueWithTouched(
            currentPaymentTransaction.formData
        );

        try {
            const SalesCountTransaction: SalesCountTransactionInit = {
                turnover: formData["Turnover"].value,
                wins: formData["Wins"].value,
                unclaimed: formData["Unclaimed"].value,
                memo: formData["Description"].value,
                expected_sales_balance: formData["Expected"].value,
                location_id: currentLocationID,
            };

            if (formData["Action"].value !== "IGNORE") {
                SalesCountTransaction["action_account_id"] =
                    formData["Action"].value;
            }

            SalesCountTransactions.push(SalesCountTransaction);
        } catch (e) {
            return;
        }
    });

    tableDataDeepCopy[rowIndex].sales_count_transactions = SalesCountTransactions;

    onRowDataChange(rowIndex, tableDataDeepCopy);
};
