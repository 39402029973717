import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import React, { ReactElement } from "react";
import { capitaliseFirstLetter } from "..";
import { isNumber } from "../../utilities";

/**
 *    FILTERING FUNCTIONS
 */
const handleSearch = (confirm: () => void): void => {
    confirm();
};

const handleReset = (clearFilters: () => void): void => {
    clearFilters();
};

/**
 * Helper function that changes the colour of the Icon based on if the column has a filter applied.
 *
 * @param {boolean} filtered
 * @returns {ReactElement}
 */
const FilterIcon = (filtered: boolean | undefined): ReactElement => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
);

/**
 * Helper function for adding search based filter to a column in AntD Tables
 *
 *
 * @param dataIndex
 * @returns
 */
export const getColumnSearchProps = (dataIndex: string) => ({
    // Note "filterDropdown" is an internal prop of Antd Table
    // eslint-disable-next-line react/display-name
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }: {
        setSelectedKeys: (input: any) => any;
        // TODO DETERMINE THIS TYPE
        selectedKeys: any;
        confirm: () => void;
        clearFilters: () => void;
    }): ReactElement => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(confirm)}
                style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered: boolean | undefined) => FilterIcon(filtered),
    onFilter: (value: any, record: any) =>
        record[dataIndex]
            ? record[dataIndex]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
            : "",
});

/**
 * Help function for dropdown select approach to filtering (instead of search)
 *
 * @param data
 * @returns
 */
export const filterStringHelper = (
    passedVar: any[],
    field: string,
    recordField?: string,
    valueMapFunction?: (ID: string, entityArray: any[]) => string | undefined
) => {
    // Extracts only unique entries
    const UNIQUE_ENTRIES = [
        ...new Set(passedVar.map((transaction) => transaction[field])),
    ].filter(function (x) {
        // Removes any undefined fields.
        return x !== undefined;
    });

    // Maps the locations into recognised filter options,
    const FILTERS = UNIQUE_ENTRIES.map((location) => {
        if (valueMapFunction) {
            return { text: location, value: valueMapFunction(location, passedVar) };
        }

        return { text: location, value: location };
    });

    const RECORD_FIELD = recordField ?? field;

    return {
        filters: FILTERS,
        onFilter: (value: any, record: any) =>
            record[RECORD_FIELD]
                ? record[RECORD_FIELD].toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : "",
    };
};
/**
 * Help function for dropdown select approach to filtering (instead of search)
 *
 * @param data
 * @returns
 */
export const filterStringHelperWithMap = (
    passedVar: any[],
    field: string,
    map: any
) => {
    // Extracts only unique entries
    const UNIQUE_ENTRIES = [
        ...new Set(passedVar.map((transaction) => transaction[field])),
    ];
    // Maps the fields into recognised filter options,
    const FILTERS = UNIQUE_ENTRIES.map((location) => {
        return { text: capitaliseFirstLetter(map[location]), value: location };
    });

    return {
        filters: FILTERS,
        onFilter: (value: any, record: any) =>
            record[field]
                ? isNumber(record[field]) === false
                    ? record[field]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : record[field] === value
                : "",
    };
};

/**
 *    SORTING FUNCTIONS
 */

/**
 *
 * @param RowA
 * @param RowB
 * @param fieldToSort
 * @returns
 */
export const numberColumnSort = (
    RowA: any,
    RowB: any,
    fieldToSort: string
): number => parseFloat(RowA[fieldToSort]) - parseFloat(RowB[fieldToSort]);

/**
 * This helper function assumes the column being sorted is a string only values.
 *
 *
 * @param RowA
 * @param RowB
 * @param fieldToSort
 * @returns
 */
export const stringColumnSort = (
    RowA: any,
    RowB: any,
    fieldToSort: string
): number => {
    const RowAItem = RowA[fieldToSort] ?? "";
    const RowBItem = RowB[fieldToSort] ?? "";

    return RowAItem.localeCompare(RowBItem);
};
/**
 *  This helper functions assumes the column being sorted is boolean only values.
 * @param RowA
 * @param RowB
 * @param fieldToSort
 * @returns
 */
export const booleanColumnSort = (
    RowA: any,
    RowB: any,
    fieldToSort: string
): number => RowA[fieldToSort] - RowB[fieldToSort];

/**
 * Render helper function for handle dollar values.
 *
 * @param TEMP_VALUE
 * @param decimalPlaces number of decimal places to round/pad to.
 * @returns
 */
export const renderDollarColumn = (
    TEMP_VALUE: number,
    decimalPlaces = 2
): string | null => {
    // TODO APPLY EDGE CASE HANDLING TO ALL TABLE COLUMN HELPER FUNCTION
    if (TEMP_VALUE === null || TEMP_VALUE === undefined) {
        return null;
    }
    return TEMP_VALUE >= 0
        ? `$${TEMP_VALUE.toFixed(decimalPlaces)}`
        : `-$${Math.abs(TEMP_VALUE).toFixed(decimalPlaces)}`;
};
