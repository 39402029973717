import { Space } from "antd";
import React from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    ExtendedVenueItem,
    VenuesCashupsResponse,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { GamingPanel } from "./GamingPanel";
import { CashupTabHOC, TabHOCComponentProps } from "../POS/utils/PanelHOC";
import { CollapsibleTable } from "../POS/utils/Panel";

interface Props extends TabHOCComponentProps {
    cashups: VenuesCashupsResponse;
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    locations: ExtendedLocationItem[];
    selectedDate: string;
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    venuesData: ExtendedVenueItem[];
}

const GamingTabComponent: React.FC<Props> = ({
    cashups,
    accountsData,
    classesData,
    locations,
    selectedDate,
    hierarchicalLocations,
    venuesData,
    setCurrenTableInFocus,
    currentTableInFocus,
}) => {
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {cashups.locations.gaming_locations.map(
                ({ name, sub_locations }, index) => {
                    const panelId = `${selectedDate}-${index}`;
                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <GamingPanel
                                key={name}
                                name={name}
                                subLocations={sub_locations}
                                accountsData={accountsData}
                                classesData={classesData}
                                locations={locations}
                                hierarchicalLocations={hierarchicalLocations}
                                venuesData={venuesData}
                                onClickHandler={(tableIndex?: number) =>
                                    setCurrenTableInFocus?.(
                                        `${panelId}-${tableIndex}`
                                    )
                                }
                                // isInFocus={panelId === currentTableInFocus}
                                currentTableInFocus={currentTableInFocus}
                                panelId={panelId}
                            />
                        </CollapsibleTable>
                    );
                }
            )}
        </Space>
    );
};

export const GamingTab = CashupTabHOC(GamingTabComponent);
