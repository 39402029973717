import { formatDollarField } from "@Constants";
import { AntDFormStateForGaming, AntDFormStateWithoutSplit } from "@types";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import { Table } from "Components/Table/Table";
import { TableRows } from "Components/Table/TableBody";
import { TableConfigs } from "Components/Table/TableHeader";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import { produce } from "immer";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import React, { useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    CashupSubLocationItem,
    ExtendedVenueItem,
    timePeriodValues,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { TransactionsFormTransferAndPayment } from "../../ExcelTable/TransactionsFormTransferAndPayment";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import { InitDataResponseType } from "../POS/@types";
import { TableDataType } from "../POS/POSPanel";
import {
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
} from "../POS/utils";
import { PanelHOCComponentProps } from "../POS/utils/PanelHOC";
import {
    countTransactions,
    LocationDetailByCashup,
    sortCashupIDS,
    sortTableData,
} from "../../../../utils/utilities";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";

interface Props extends PanelHOCComponentProps {
    name: string;
    cashupIds: string[];
    subLocation: CashupSubLocationItem;
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    accountsData: ExtendedAccountItem[];
    venuesData: ExtendedVenueItem[];
}

const initializeWageringFields = (
    cashup: InitDataResponseType
): InitDataResponseType => {
    const wageringData = cashup.wagering_data;
    return {
        ...cashup,
        wagering_data: {
            sales_total: wageringData.sales_total,
            maintenance: wageringData.maintenance,
            commission: wageringData.commission,
        },
    };
};

const transformLocationsToLocationDetailByCashup = (
    subLocation: CashupSubLocationItem
): LocationDetailByCashup =>
    subLocation.cashups.reduce<{
        [cashupid: string]: {
            name: string;
            location_id: string;
            timePeriod: timePeriodValues;
        };
    }>((result, { cashup_id, time_period }) => {
        result[cashup_id] = {
            name: subLocation.name,
            location_id: subLocation.location_id,
            timePeriod: time_period,
        };
        return result;
    }, {});

export const WageringPanel: React.FC<Props> = ({
    cashupIds,
    subLocation,
    accountsData,
    hierarchicalLocations,
    locations,
    venuesData,
    onClickHandler,
    isInFocus,
}) => {
    const [updateCashup] = usePushCashupMutation();

    const locationDetailByCashupId = React.useMemo(() => {
        return transformLocationsToLocationDetailByCashup(subLocation);
    }, [subLocation]);

    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);
    const updateTableData = (cashup: InitDataResponseType) => {
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);
                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });
    };
    const sortedCashupIDS = useMemo(() => {
        const cashupIdsInSublocation = cloneDeep(cashupIds).filter(
            (cashupId) =>
                subLocation.cashups.find(
                    (cashup) => cashup.cashup_id === cashupId
                ) != undefined
        );
        return cashupIdsInSublocation.sort((cashupA_ID, cashupB_ID) =>
            sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
        );
    }, [cashupIds]);

    const { isLoading } = useCashupRealtime({
        cashupIds: sortedCashupIDS,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const handleCashupChanged = (updatedCashup: InitDataResponseType) =>
        updateCashup(updatedCashup).unwrap();

    const onRowDataChange = React.useCallback(
        (rowIndex: number, tableData: TableDataType[]) => {
            handleCashupChanged(tableData[rowIndex]);
        },
        []
    );

    const tableConfigs: TableConfigs = useMemo(() => {
        const columnTitles = [
            { columnTitle: "" },
            {
                columnTitle:
                    subLocation.name === "Keno" ? "Cash Total" : "Imbalance Total",
            },
            {
                columnTitle:
                    subLocation.name === "Keno" ? "Keno Charges" : "TAB Charges",
            },
            { columnTitle: "Commission" },
            { columnTitle: "Transactions", hideTotal: true },
            { columnTitle: "Cash Count", separateColumn: true },
            { columnTitle: "Expected" },
            { columnTitle: "Variance" },
        ];
        return columnTitles;
    }, [subLocation, subLocation.name]);
    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const onPaymentTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateForGaming[], rowIndex: number) =>
            onPaymentTransactionSubmission(
                data,
                rowIndex,
                accountsData,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    if (isLoading) {
        //|| sortedCashupIDS.length !== tableData.length
        return <CentredSpinner />;
    }

    const dataSources: TableRows[] = tableData.map((cashup, rowIndex) => {
        const {
            cashup_id,
            cash_count,
            wagering_data,
            payment_transactions,
            transfer_transactions,
            transfer_transactions_to,
            status,
        } = cashup;

        const initialDataSources = [
            {
                value: locationDetailByCashupId[cashup_id].name,
                readOnly: true,
            },
            {
                value: wagering_data.sales_total ?? 0,
                propertyPath: "wagering_data.sales_total",
            },
            {
                value: wagering_data.maintenance ?? 0,
                propertyPath: "wagering_data.maintenance",
            },
            {
                value: wagering_data.commission ?? 0,
                propertyPath: "wagering_data.commission",
            },

            {
                value: tableData[rowIndex]
                    ? countTransactions([
                          transfer_transactions ?? [],
                          payment_transactions ?? [],
                          transfer_transactions_to ?? [],
                      ])
                    : "",
                editModal: (closeModal: () => void) => (
                    <TransactionsFormTransferAndPayment
                        locations={locations}
                        onTransferTransactionSubmission={(data) =>
                            onTransferTransactionSubmissionDebounce(data, rowIndex)
                        }
                        TransferTransactions={transfer_transactions ?? []}
                        hierarchicalLocations={hierarchicalLocations}
                        currentLocationID={
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        accountsData={accountsData}
                        PaymentTransactionData={payment_transactions ?? []}
                        TransferTransactionsReadOnly={transfer_transactions_to ?? []}
                        onPaymentTransactionSubmission={(data) =>
                            onPaymentTransactionSubmissionDebounce(data, rowIndex)
                        }
                        onPaymentTransactionRemove={(data) =>
                            onPaymentTransactionRemove(
                                data,
                                rowIndex,
                                accountsData,
                                tableData,
                                onRowDataChange
                            )
                        }
                        venuesData={venuesData}
                        onModalClose={closeModal}
                    />
                ),
                modalTitle: "Transactions",
                hideEditModalFooter: true,
            },

            {
                value: cash_count.actual ?? "$0",
                isExplicitlyNotTitleCell: true,
                applyDollarFormatting: true,
                propertyPath: "cash_count.actual",
                nanInsteadOfZeroOut: true,
            },
            {
                value: formatDollarField(cash_count.expected_cash ?? 0),
                readOnly: true,

                statusField: () =>
                    cash_count.expected_cash === null ? status : null,
            },
            {
                value:
                    typeof cash_count.cash_variance !== "string"
                        ? formatDollarField(cash_count.cash_variance ?? 0)
                        : cash_count.cash_variance,
                readOnly: true,
                statusField: () => (status ? status : null),
            },
        ];
        return initialDataSources;
    });

    return (
        <Table
            onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                const updatedCashup = produce(
                    initializeWageringFields(tableData[row]),
                    (draft) => {
                        set(draft, propertyPath, updatedValue);
                    }
                );
                handleCashupChanged(updatedCashup);
            }}
            dataSources={dataSources}
            tableConfigs={tableConfigs}
            onClickHandler={onClickHandler}
            isInFocus={isInFocus}
        />
    );
};
