import { Card, Row } from "antd";
import React, { useCallback, useMemo } from "react";
import { Column } from "@ant-design/plots";
import { StyledSection, SectionTitle, StyledTable } from "./CashupDailyReport";
import { prependDollarSign } from "@Constants";
import styled from "styled-components";
import { useIsMobile } from "Pages/CashupHome/CollaborationTable/useIsMobile";

const Container = styled(Card)`
    min-height: 280px;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    flex: 1;

    @media screen and (max-width: 792px) {
        .ant-card-body {
            padding: 8px !important;
        }
        .ant-card {
            padding: 8px !important;
        }
        margin-bottom: 0px;
    }
`;

export const CHART_COLOUR = [
    "#00225D",
    "#FF6A14",
    "#333976",
    "#FF9957",
    "#6663C3",
    "#FFB88A",
    "#9999E3",
    "#FFD6BE",
    "#CCCCFF",
    "#FFE6E6",
];

const GamingSectionComponent = ({
    individualFields,
    tableData,
    graphData,
}: {
    individualFields: { netProfit: number; returnToHouse: number; turnover: number };
    tableData: { title: string; amount: number }[];
    graphData: { title: string; amount: number }[];
}) => {
    const shouldHide = useMemo(() => {
        let shouldShow = false;
        graphData.forEach((item) => {
            if (item.amount !== 0) shouldShow = true;
        });
        return shouldShow;
    }, [graphData]);
    const isMobile = useIsMobile();

    const fetchRowIndexByTitle = useCallback(
        (title: string) => {
            return graphData.findIndex((item) => item.title === title);
        },
        [graphData]
    );

    if (!shouldHide) return <div></div>;

    return (
        <StyledSection
            shouldHideOnMobile
            style={{ display: "flex", gap: 12, flexDirection: "column" }}
        >
            <Row style={{ justifyContent: "center" }}>
                <SectionTitle title="Gaming" />
            </Row>
            <Container>
                <Column
                    data={graphData}
                    xField="title"
                    yField="amount"
                    style={{ paddingBottom: 20 }}
                    color={(currentColumn) => {
                        const index = fetchRowIndexByTitle(currentColumn.title);
                        return CHART_COLOUR[index];
                    }}
                    yAxis={{
                        label: {
                            formatter: (text) => prependDollarSign(Number(text)),
                        },
                    }}
                    label={{
                        position: "middle",
                        style: {
                            fill: "#FFFFFF",
                            opacity: isMobile ? 0 : 1,
                        },
                        formatter: (text) => {
                            return prependDollarSign(text.amount);
                        },
                    }}
                />
                <StyledTable
                    dataSource={tableData}
                    columns={[
                        {
                            title: "Title",
                            dataIndex: "title",
                            key: "title",
                        },
                        {
                            title: "Amount",
                            dataIndex: "amount",
                            key: "amount",
                            render: (text, record, index) =>
                                index === 2 ? `${text}%` : prependDollarSign(text),
                        },
                    ]}
                    pagination={false}
                />
            </Container>
        </StyledSection>
    );
};

export const GamingSection = React.memo(GamingSectionComponent);
