import React, { useEffect, useMemo } from "react";
import { Space } from "antd";
import {
    ExtendedVenueItem,
    VenuesCashupsResponse,
} from "../../../../Redux/StateSlices/GroupData/VenuesAPI";
import { CollapsePanelPOS } from "./POSPanel";
import { ExtendedClassItem } from "../../../../Redux/StateSlices/GroupData/ClassesAPI";
import { ExtendedAccountItem } from "../../../../Redux/StateSlices/GroupData/AccountsAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { CashupTabHOC, TabHOCComponentProps } from "./utils/PanelHOC";
import { CollapsibleTable } from "./utils/Panel";
import { PosTotal } from "./@types";
import { CollapsePOSTotal } from "./POSTotal";

interface Props extends TabHOCComponentProps {
    cashups: VenuesCashupsResponse;
    // Note "selectedDate" ensures that the keys for each Collapsing panel is unique so that when you switch Date or Venue the collapse panels don't persist from the prior selection.
    selectedDate: string;
    classesData: ExtendedClassItem[];
    accountsData: ExtendedAccountItem[];
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    venuesData: ExtendedVenueItem[];
    posTotal?: PosTotal;
    currentTableInFocus: string;
}

const PosTabComponent = ({
    cashups,
    selectedDate,
    classesData,
    accountsData,
    locations,
    hierarchicalLocations,
    venuesData,
    posTotal,
    setCurrenTableInFocus,
    currentTableInFocus,
}: Props) => {
    const totalPanelId = `${selectedDate}-${cashups.locations.pos_locations.length}`;
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {cashups.locations.pos_locations.map((currentLocation, index) => {
                const panelId = `${selectedDate}-${index}`;
                const posDataFromPosTotal = posTotal
                    ? Object.keys(posTotal).find(
                          (currentKey) => currentKey === currentLocation.location_id
                      ) !== undefined
                        ? posTotal[
                              Object.keys(posTotal).find(
                                  (currentKey) =>
                                      currentKey === currentLocation.location_id
                              )!
                          ]
                        : undefined
                    : undefined;
                const posDataFromCashup =
                    Object.keys(cashups.pos_totals).find(
                        (currentKey) => currentKey === currentLocation.location_id
                    ) !== undefined
                        ? cashups.pos_totals[
                              Object.keys(cashups.pos_totals).find(
                                  (currentKey) =>
                                      currentKey === currentLocation.location_id
                              )!
                          ]
                        : undefined;
                const posData = posDataFromPosTotal ?? posDataFromCashup;
                return (
                    <CollapsibleTable
                        name={currentLocation.name}
                        panelId={panelId}
                        key={panelId}
                    >
                        <CollapsePanelPOS
                            key={currentLocation.name}
                            classesData={classesData}
                            accountsData={accountsData}
                            subLocations={currentLocation.sub_locations}
                            locations={locations}
                            hierarchicalLocations={hierarchicalLocations}
                            venuesData={venuesData}
                            onClickHandler={() => setCurrenTableInFocus?.(panelId)}
                            isInFocus={panelId === currentTableInFocus}
                            posData={posData}
                        />
                    </CollapsibleTable>
                );
            })}
            <CollapsibleTable name="" panelId={totalPanelId} key={totalPanelId}>
                <CollapsePOSTotal
                    classesData={classesData}
                    accountsData={accountsData}
                    posTotal={posTotal}
                    cashups={cashups}
                    onClickHandler={() => setCurrenTableInFocus?.(totalPanelId)}
                    isInFocus={totalPanelId === currentTableInFocus}
                />
            </CollapsibleTable>
        </Space>
    );
};

export const POSTab = CashupTabHOC(PosTabComponent);
