import { SettingOutlined } from "@ant-design/icons";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import "./userAvatarDropdown.scss";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "../../Router/RoutesEnum";
import { useGetCurrentUser } from "../../@hooks/auth/getCurrentUser";
import { resetCache } from "Redux/logoutHelper";
import { AccessIdToken, AuthTokens } from "utils/auth0/types";
import jwtDecode from "jwt-decode";
import type { MenuProps } from "antd";
import { useAppDispatch } from "Redux/TypedReduxFunctions";
import { resetVenue } from "Redux/StateSlices/ShiftReportState";

export enum PermissionType {
    CreateUser = "create:users",
    AuditReportDownload = "cashup:superadmin",
}

/**
 * Returns true if access_token.permissions array contains string 'create:users'
 *
 *
 * @returns
 */
export const userManagerCheck = (userPermission: PermissionType): boolean => {
    const accessToken = window.localStorage.getItem(AuthTokens.accessToken);
    if (!accessToken) return false;
    return (
        (jwtDecode(accessToken) as AccessIdToken).permissions?.find(
            (permission) => permission === userPermission
        ) !== undefined
    );
};

export default function UserAvatarDropdown(): ReactElement {
    const router = useHistory();
    const dispatch = useAppDispatch();
    const logout = () => {
        // The auth state listener will clear redux store on exit.
        resetCache();
        dispatch(resetVenue());
        router.push(RoutePaths.LOGIN);
    };
    const { user } = useGetCurrentUser();
    // const userMenu: MenuProps["items"] = (
    //     <Menu>
    //         {user?.email && (
    //             <Menu.Item key="0">
    //                 <a rel="noreferrer">{user?.email}</a>
    //             </Menu.Item>
    //         )}
    //         {user?.email && <Menu.Divider />}
    //         {/* isAdminUser && ( */}
    //         {userManagerCheck(user) && (
    //             <Menu.Item key="1">
    //                 <a href={RoutePaths.USERS} rel="noreferrer">
    //                     User Management
    //                 </a>
    //             </Menu.Item>
    //         )}
    //         {/* */}
    //         {/* TEMPORARILY DISABLED TILL RELEVANT FIGURZ ENDPOINT EXISTS */}
    //         {/*<Menu.Item key="2">
    //             <a href={RoutePaths.PROFILE} rel="noreferrer">
    //                 Change password
    //             </a>
    //         </Menu.Item> */}
    //         <Menu.Item key="3">
    //             <a rel="noreferrer" onClick={logout}>
    //                 Log out
    //             </a>
    //         </Menu.Item>
    //     </Menu>
    // );

    const userMenu: MenuProps["items"] = [
        {
            key: 1,
            label: (
                <Menu.Item key="0">
                    <a rel="noreferrer">{user?.email}</a>
                </Menu.Item>
            ),
        },
        {
            key: 2,
            label: userManagerCheck(PermissionType.CreateUser) && (
                <Menu.Item key="1">
                    <a href={RoutePaths.USERS} rel="noreferrer">
                        User Management
                    </a>
                </Menu.Item>
            ),
        },
        {
            key: 3,
            label: userManagerCheck(PermissionType.AuditReportDownload) && (
                <Menu.Item key="1">
                    <a href={RoutePaths.AUDIT_REPORT} rel="noreferrer">
                        Audit Report
                    </a>
                </Menu.Item>
            ),
        },
        {
            key: 4,
            label: (
                <Menu.Item key="3">
                    <a rel="noreferrer" onClick={logout}>
                        Log out
                    </a>
                </Menu.Item>
            ),
        },
    ];

    return (
        <Dropdown.Button
            className="dropdown-btn"
            menu={{ items: userMenu }}
            icon={
                <SettingOutlined
                    style={{
                        fontSize: "28px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "50%",
                    }}
                />
            }
        />
    );
}
