import React from "react";
import { useGetGroupDataQuery } from "Redux/StateSlices/GroupDataAPI";
import { useCubejs } from "utils/cubejs-utils";

export const useIntializeCashupReport = () => {
    const { cubejsApi, updateCubeJsToken } = useCubejs();
    const { data: groupData, isFetching: fetchingGroupData } =
        useGetGroupDataQuery(null);

    React.useEffect(() => {
        if (groupData?.cubejs_token) {
            updateCubeJsToken(groupData.cubejs_token);
        }
    }, [groupData, updateCubeJsToken]);

    return {
        ready: !fetchingGroupData && groupData && cubejsApi,
    };
};
