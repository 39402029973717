import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthTokens } from "utils/auth0/auth0API";
import { BASE_URL } from "../../../@Constants";
import { AppendContentTypeHeader } from "./UsersAPI";

// Extended from "AccountItem" in @generated
export type ExtendedAccountItem = {
    account_id: string;
    name: string;
    account_type: number;
    group_id: string;
    parent_id: string; // TODO "None" Check
    tender_type: boolean; // 0 | 1;
    active: boolean;
    variance_account: boolean;
    cash_account: boolean;
    external_id?: string;
    eftpos_account: boolean;
    sales_account: boolean;
    payout_tender_type: boolean;
    mapping_configurator_id: null | string;
    netsuite_name: null | string;
};

export const accountsAPI = createApi({
    reducerPath: "AccountsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: async (headers) => {
            const data = await getAuthTokens();
            const access_token = data?.access_token;
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAccounts: builder.query<ExtendedAccountItem[], null>({
            query: () => ({
                url: "/accounts",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const { useGetAccountsQuery } = accountsAPI;
