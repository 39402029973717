import React, { ReactElement } from "react";
import { useBegoneWidget } from "utils/widget";
import { LoginForm } from "../../Components/LoginScreen";

const LoginPage = (): ReactElement => {
    useBegoneWidget();
    return <LoginForm />;
};

export default LoginPage;
