import { Card, Col, Row, Statistic } from "antd";
import React, { useCallback, useMemo } from "react";
import { prependDollarSign } from "@Constants";
import { Column } from "@ant-design/plots";
import { GroupDataState } from "Redux/StateSlices/GroupData";
import { SectionTitle, StyledKeyNumberText, StyledTable } from "./CashupDailyReport";
import styled from "styled-components";
import { CHART_COLOUR } from "./GamingSection";

const StyledPieContainer = styled.div`
    padding-bottom: 20px;
    max-width: 400px;
`;

const StyledContainer = styled.div`
    border-radius: 16px;
    padding: 20px;
    height: 100%;
    flex: 1;
    border-radius: 12px;
    border: 1px solid #aaa;

    @media (max-width: 768px) {
        padding: 8px;
        border: none;

        .ant-card-body {
            padding: 8px !important;
        }
        .ant-card {
            padding: 8px !important;
        }
    }
`;

const RenderLocationCardComponent = ({
    locationId,
    venueId,
    date,
    name,
    groupData,
    salesTotalSalesData,
    isMobile,
}: {
    locationId: string;
    venueId: string;
    date: string;
    name: string;
    groupData: GroupDataState;
    salesTotalSalesData: { [classId: string]: number };
    isMobile: boolean;
}) => {
    const ParsedAllLocationsSalesData = useMemo(() => {
        if (!salesTotalSalesData) return;
        let salesTotal = 0;

        Object.keys(salesTotalSalesData).forEach((classId: any) => {
            salesTotal += salesTotalSalesData[classId];
        });

        const ClassesArray: {
            className: string;
            amount: number;
        }[] = [];
        Object.keys(salesTotalSalesData).forEach((classId) => {
            const match = groupData.classes.find(
                (currentClass) => classId === currentClass.class_id
            );
            if (match)
                ClassesArray.push({
                    className: match.name,
                    amount: salesTotalSalesData[classId],
                });
        });

        return { salesTotal, ClassesArray };
    }, [salesTotalSalesData]);
    const fetchRowIndexByClassname = useCallback(
        (title: string) => {
            return ParsedAllLocationsSalesData?.ClassesArray.findIndex(
                (item) => item.className === title
            );
        },
        [ParsedAllLocationsSalesData]
    );
    if (
        !ParsedAllLocationsSalesData ||
        Object.keys(salesTotalSalesData).length === 0
    )
        return (
            <StyledContainer>
                <SectionTitle title={name} />
                <div
                    style={{
                        height: 200,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <SectionTitle title="No Data Available" />
                </div>
            </StyledContainer>
        );

    if (ParsedAllLocationsSalesData.ClassesArray.length === 0) {
        return (
            <StyledContainer>
                <SectionTitle title={name} />
                <div
                    style={{
                        height: 200,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <SectionTitle title="No Data Available" />
                </div>
            </StyledContainer>
        );
    }

    return (
        <StyledContainer
            style={{
                flexDirection: "column",
                width: "100%",
            }}
        >
            <SectionTitle title={name} />
            <Col style={{ paddingBottom: 20 }}>
                <Card>
                    <Statistic
                        title="Total Sales"
                        value={ParsedAllLocationsSalesData.salesTotal}
                        precision={2}
                        prefix={"$"}
                    />
                </Card>
            </Col>
            <StyledPieContainer>
                <Column
                    data={ParsedAllLocationsSalesData.ClassesArray}
                    xField="className"
                    yField="amount"
                    style={{ paddingBottom: 20 }}
                    label={{
                        position: "middle",
                        style: {
                            fill: "#FFFFFF",
                            opacity: isMobile ? 0 : 1,
                        },
                        formatter: (text) => {
                            return prependDollarSign(text.amount);
                        },
                    }}
                    color={(currentColumn) => {
                        const index = fetchRowIndexByClassname(
                            currentColumn.className
                        );
                        return CHART_COLOUR[index ?? 0];
                    }}
                    yAxis={{
                        label: {
                            formatter: (text) => prependDollarSign(Number(text)),
                        },
                    }}
                />
            </StyledPieContainer>
            <StyledTable
                dataSource={ParsedAllLocationsSalesData.ClassesArray}
                columns={[
                    {
                        title: "Class name",
                        dataIndex: "className",
                        key: "className",
                    },
                    {
                        title: "Amount",
                        dataIndex: "amount",
                        key: "amount",
                        render: (text) => prependDollarSign(text),
                    },
                ]}
                pagination={false}
            />
        </StyledContainer>
    );
};

export const RenderLocationCard = React.memo(RenderLocationCardComponent);
