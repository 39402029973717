import { Space } from "antd";
import React, { useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    ExtendedVenueItem,
    VenuesCashupsResponse,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { CollapsibleTable } from "../POS/utils/Panel";
import { CashupTabHOC, TabHOCComponentProps } from "../POS/utils/PanelHOC";
import { WageringPanel } from "./WageringPanel";

interface Props extends TabHOCComponentProps {
    cashups: VenuesCashupsResponse;
    selectedDate: string;
    accountsData: ExtendedAccountItem[];
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    venuesData: ExtendedVenueItem[];
}

const WageringTabComponent: React.FC<Props> = ({
    cashups,
    selectedDate,
    accountsData,
    locations,
    hierarchicalLocations,
    venuesData,
    setCurrenTableInFocus,
    currentTableInFocus,
}) => {
    const cashup_ids = useMemo(
        () => cashups.locations.wagering_locations[0].cashup_ids,
        []
    );
    const name = useMemo(() => cashups.locations.wagering_locations[0].name, []);

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {cashups.locations.wagering_locations[0].sub_locations.map(
                (subLocation, mainIndex) => {
                    const panelId = `${selectedDate}-${mainIndex}`;
                    return (
                        <CollapsibleTable
                            name={subLocation.name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <WageringPanel
                                locations={locations}
                                hierarchicalLocations={hierarchicalLocations}
                                subLocation={subLocation}
                                key={name}
                                name={name}
                                accountsData={accountsData}
                                cashupIds={cashup_ids}
                                venuesData={venuesData}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                            />
                        </CollapsibleTable>
                    );
                }
            )}
        </Space>
    );
};

export const WageringTab = CashupTabHOC(WageringTabComponent);
