import React, { ReactElement } from "react";

import { DefaultLayout } from "../../Components/Layout";
import { UserTable } from "../../Components/UserTable";

const UsersPage = (): ReactElement => {
    return <DefaultLayout BodyComponent={UserTable} />;
};

export default UsersPage;
