import React, { useMemo } from "react";
import { KeyValuePair } from "@types";
import { FormInstance, Typography } from "antd";
import styled from "styled-components";
import { level2Spacing, level3Spacing } from "utils/style-utils";
import { ModalFormV2, MoneyAndDenomination } from "./ModalFormV2";
import { Field } from "./TransactionForms/SalesCountModalFormV2";

interface Props {
    row: number;
    onModalClose?: () => void;
    onSubmit: (data: KeyValuePair) => void;
    initialValues: KeyValuePair[];
    extendDenominationOptions?: boolean;
    onClear?: () => void;
}

const StyledMoreTenderForm = styled.div`
    width: 400px;
    & > div.ant-row {
        padding-left: ${level3Spacing}px !important;
        padding-right: ${level3Spacing}px !important;
    }
`;

export const denominationFormInitialState = ({
    initialValuesPairs,
    extendDenominationOptions,
}: {
    initialValuesPairs: KeyValuePair;
    extendDenominationOptions?: boolean;
}): Field[] => {
    const defaultFields: Field[] = [
        {
            name: "$100",
            type: {
                name: MoneyAndDenomination.DevisibleBy100Dollar,
                positiveValueOnly: false,
            },
            required: true,
            colSpan: 8,
            defaultValue: initialValuesPairs["$100"] ?? 0,
            addonBefore: "$",
        },
        {
            name: "$50",
            type: {
                name: MoneyAndDenomination.DevisibleBy50Dollar,
                positiveValueOnly: false,
            },
            required: true,
            colSpan: 8,
            defaultValue: initialValuesPairs["$50"] ?? 0,
            addonBefore: "$",
        },
        {
            name: "$20",
            type: {
                name: MoneyAndDenomination.DevisibleBy20Dollar,
                positiveValueOnly: false,
            },
            required: true,
            colSpan: 8,
            defaultValue: initialValuesPairs["$20"] ?? 0,
            addonBefore: "$",
        },
        {
            name: "$10",
            type: {
                name: MoneyAndDenomination.DevisibleBy10Dollar,
                positiveValueOnly: false,
            },
            required: true,
            colSpan: 8,
            defaultValue: initialValuesPairs["$10"] ?? 0,
            addonBefore: "$",
        },
        {
            name: "$5",
            type: {
                name: MoneyAndDenomination.DevisibleBy5Dollar,
                positiveValueOnly: false,
            },
            required: true,
            colSpan: 8,
            defaultValue: initialValuesPairs["$5"] ?? 0,
            addonBefore: "$",
        },
    ];
    if (extendDenominationOptions) {
        return [
            ...defaultFields,

            {
                name: "$2",
                type: {
                    name: MoneyAndDenomination.DevisibleBy2Dollar,
                    positiveValueOnly: false,
                },
                required: true,
                colSpan: 8,
                defaultValue: initialValuesPairs["$2"] ?? 0,
                addonBefore: "$",
            },
            {
                name: "$1",
                type: {
                    name: MoneyAndDenomination.IntegerOnly,
                    positiveValueOnly: false,
                },
                required: true,
                colSpan: 8,
                defaultValue: initialValuesPairs["$1"] ?? 0,
                addonBefore: "$",
            },
            {
                name: "50¢",
                type: {
                    name: MoneyAndDenomination.DevisibleBy50Cent,
                    positiveValueOnly: false,
                },
                required: true,
                colSpan: 8,
                defaultValue: initialValuesPairs["50¢"] ?? 0,
                addonBefore: "$",
            },

            {
                name: "20¢",
                type: {
                    name: MoneyAndDenomination.DevisibleBy20Cent,
                    positiveValueOnly: false,
                },
                required: true,
                colSpan: 8,
                defaultValue: initialValuesPairs["20¢"] ?? 0,
                addonBefore: "$",
            },
            {
                name: "10¢",
                type: {
                    name: MoneyAndDenomination.DevisibleBy10Cent,
                    positiveValueOnly: false,
                },
                required: true,
                colSpan: 8,
                defaultValue: initialValuesPairs["10¢"] ?? 0,
                addonBefore: "$",
            },
            {
                name: "5¢",
                type: {
                    name: MoneyAndDenomination.DevisibleBy5Cent,
                    positiveValueOnly: false,
                },
                required: true,
                colSpan: 8,
                defaultValue: initialValuesPairs["5¢"] ?? 0,
                addonBefore: "$",
            },
        ];
    }
    return defaultFields;
};

export const DenominationsForm: React.FC<Props> = ({
    onModalClose,
    onSubmit,
    initialValues,
    extendDenominationOptions,
    onClear,
}) => {
    const onClearHelper = (form: FormInstance<any>) => {
        const resetFields: any = {};

        denominationFormInitialState({
            initialValuesPairs: [],
            extendDenominationOptions,
        }).forEach((currentField) => (resetFields[currentField.name] = 0));

        form.setFieldsValue(resetFields);

        onClear?.();
    };

    const initialValuesPairs = useMemo(() => {
        const keyValues: KeyValuePair = {};
        initialValues.forEach((keyValue) => {
            for (const [key, value] of Object.entries(keyValue)) {
                keyValues[key] = value;
            }
        });
        return keyValues;
    }, [initialValues]);

    const denominationFields: Field[] = useMemo(
        () =>
            denominationFormInitialState({
                initialValuesPairs,
                extendDenominationOptions,
            }),
        [initialValuesPairs, extendDenominationOptions]
    );

    return (
        <StyledMoreTenderForm>
            <Typography
                style={{
                    color: "#626E84",
                    margin: level3Spacing,
                    marginBottom: level2Spacing,
                    fontWeight: "bold",
                }}
            >
                {
                    "For each box, enter an amount that can be evenly divided by the denomination value shown in the label."
                }
            </Typography>
            <ModalFormV2
                onModalClose={onModalClose}
                fields={denominationFields}
                onSubmission={onSubmit}
                onClear={onClearHelper}
            />
        </StyledMoreTenderForm>
    );
};
