import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Parser } from "json2csv";
import React, { useEffect, useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { ExtendedLocationItem } from "Redux/StateSlices/GroupData/LocationsAPI";
import { WithKey } from "types";
import { Cashup, LocationType } from "../CashupReportModel";
import { useCashupDetailReportTable } from "./CashupDetailReportTable.logic";

type DataType = WithKey<Omit<Cashup, "id">>;

interface Props {
    dataSource: DataType[];
    locationById: Record<string, ExtendedLocationItem>;
    locationType: LocationType;
    accountById: Record<string, ExtendedAccountItem>;
    classById: Record<string, ExtendedClassItem>;
    locationName: string;
    propagateTableDataInCsvFormat: (params: {
        locationName: string;
        csv: string;
    }) => void;
}

export const CashupDetailReportTable: React.FC<Props> = ({
    dataSource,
    locationById,
    locationType,
    accountById,
    classById,
    propagateTableDataInCsvFormat,
    locationName,
}) => {
    const { getMandatoryColumnSettings, columnSettingsByLocationType } =
        useCashupDetailReportTable({ accountById, classById });

    const columns: ColumnsType<DataType> = useMemo(() => {
        const result = getMandatoryColumnSettings({
            locationById,
            excludeTransaction: [LocationType.tab, LocationType.keno].includes(
                locationType
            ),
            forExport: false,
        });
        result.splice(2, 0, ...columnSettingsByLocationType[locationType]());
        return result;
    }, [
        columnSettingsByLocationType,
        getMandatoryColumnSettings,
        locationById,
        locationType,
    ]);
    const csvColumns: ColumnsType<DataType> = useMemo(() => {
        const result = getMandatoryColumnSettings({
            locationById,
            excludeTransaction: [LocationType.tab, LocationType.keno].includes(
                locationType
            ),
            forExport: true,
        });
        result.splice(2, 0, ...columnSettingsByLocationType[locationType](true));
        return result;
    }, [
        columnSettingsByLocationType,
        getMandatoryColumnSettings,
        locationById,
        locationType,
    ]);

    useEffect(() => {
        const tableData = dataSource.map((data: any) => {
            return csvColumns.reduce<any>((result, column) => {
                const { title, dataIndex, render } = column as any;
                const {
                    body: {
                        transferTransactions,
                        transferTransactionsTo,
                        paymentTransactions,
                        depositInTransactions,
                        depositOutTransactions,
                        eftposCountTransactions,
                        salesCountTransactions,
                    },
                } = data;
                const numberOfTransactions =
                    transferTransactions.length +
                    transferTransactionsTo.length +
                    paymentTransactions.length +
                    depositInTransactions.length +
                    depositOutTransactions.length +
                    eftposCountTransactions.length +
                    salesCountTransactions.length;

                if (title === "Transactions") {
                    result["Transactions"] = numberOfTransactions;
                    return result;
                }
                if (!render) {
                    result[title] = data[dataIndex];
                } else {
                    result[title] = render(data[dataIndex]);
                }

                return result;
            }, {});
        });

        const json2csvParser = new Parser({
            header: true,
            fields: csvColumns.map(({ title }) => title ?? "") as any,
        });
        propagateTableDataInCsvFormat({
            locationName,
            csv: json2csvParser.parse(tableData),
        });
    }, [csvColumns, dataSource, locationName, propagateTableDataInCsvFormat]);

    return (
        <Table
            style={{ overflow: "auto" }}
            columns={columns}
            dataSource={dataSource}
            rowClassName={(_, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
        />
    );
};
