import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import { Button, Divider, Modal, Table, Typography } from "antd";

import { openNotificationWithIcon } from "../../Components/Notification";
import { useHistory } from "react-router-dom";
import {
    TableActions,
    UserForm,
} from "../../Components/UserTable/TableColumnComponents";

import { FieldData } from "../../@interfaces";
import { SetEditFieldsType } from "../../@types";
import { PermissionType, userManagerCheck } from "../UserAvatarDropdown";
import { RoutePaths } from "../../Router/RoutesEnum";
import { RENDER_USER_ROLE } from "../../utils/generalFunctions";
import {
    useCreateUserMutation,
    useDeleteUserMutation,
    useGetUsersQuery,
    useUpdateUserMutation,
} from "../../Redux/StateSlices/GroupData/UsersAPI";
import { useGetCurrentUser } from "../../@hooks/auth/getCurrentUser";
import { ExtendedUserType } from "../../@types/reduxStore";
import { parseName, stringSort } from "utils/utilities";
import { isArray } from "lodash";
import styled from "styled-components";

const columns: any = (
    users: ExtendedUserType[],
    setUserToEditID: Dispatch<SetStateAction<string | null>>,
    setEditFields: (input: SetEditFieldsType) => void,
    setIsEditUserModalVisble: Dispatch<SetStateAction<boolean>>,
    setDeleteUserId: Dispatch<SetStateAction<string | null>>,
    setIsDeleteModalVisible: Dispatch<SetStateAction<boolean>>
) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        // ...getColumnSearchProps("name"),
        sorter: (a: any, b: any) => {
            // TODO MAKE THIS IMPROVEMENT TO ALL SORTING FUNCTIONS!
            // If field is undefined assigned it as an empty string
            const aName = a.name ?? "";
            const bName = b.name ?? "";
            return stringSort(aName, bName);
        },
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "age",
        // ...getColumnSearchProps("email"),
        sorter: (a: any, b: any) => stringSort(a.email, b.email),
        responsive: ["sm"],
    },
    {
        title: "Is Admin",
        dataIndex: "is_admin",
        key: "roleID",
        render: (can_invite_users: boolean) => {
            // const userRole = Number(can_invite_users);
            return RENDER_USER_ROLE(can_invite_users);
        },
        responsive: ["sm"],
    },
    {
        title: "Is Active",
        dataIndex: "is_active",
        key: "activeStatus",
        render: (is_active: boolean) => {
            return RENDER_USER_ROLE(is_active);
        },
        responsive: ["sm"],
    },
    {
        title: "",
        key: "actions",
        dataIndex: "user_id",
        render: (user_id: string) => (
            <TableActions
                user_id={user_id}
                users={users}
                setDeleteUserId={setDeleteUserId}
                setEditFields={setEditFields}
                setIsDeleteModalVisible={setIsDeleteModalVisible}
                setIsEditUserModalVisble={setIsEditUserModalVisble}
                setUserToEditID={setUserToEditID}
            />
        ),
    },
];

const initialUserFields: FieldData[] = [
    { name: ["firstName"], value: "" },
    { name: ["lastName"], value: "" },
    { name: ["email"], value: "" },
    { name: ["userRole"], value: "" },
    { name: ["isActive"], value: "" },
];

export const UserTable = (): ReactElement => {
    const router = useHistory();

    const { data: users, isLoading: loading, refetch } = useGetUsersQuery(null);

    const [editUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    const [createUser] = useCreateUserMutation();

    useEffect(() => {
        document.title = "Cashup - User management";
    }, []);

    useEffect(() => {
        // Kicks unauthorised users away in the edge event they manually navigate to this route.
        userManagerCheck(PermissionType.CreateUser)
            ? null
            : router.push(RoutePaths.HOME);
    }, [router]);

    /**
     * Delete user state variables
     *
     */
    const [deleteUserID, setDeleteUserId] = useState<string | null>(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

    /**
     * Create user state variables
     *
     */
    const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);
    const [createUserLoading, setCreateUserLoading] = useState(false);
    const [createUserFields, setCreateUserFields] =
        useState<FieldData[]>(initialUserFields);

    /**
     * Edit user state variables
     *
     */

    const [isEditUserModalVisable, setIsEditUserModalVisable] = useState(false);
    const [editUserLoading, setEditUserLoading] = useState(false);
    const [userToEditID, setUserToEditID] = useState<string | null>(null);

    const [editUserFields, setEditUserFields] =
        useState<FieldData[]>(initialUserFields);

    // This function re-initialise the form fields and sets the values extracted from the user that is being edited.
    const setEditFields = useCallback(
        ({ firstName, lastName, email, userRole, isActive }: SetEditFieldsType) => {
            setEditUserFields([
                { name: ["firstName"], value: firstName },
                { name: ["lastName"], value: lastName },
                { name: ["email"], value: email },
                { name: ["userRole"], value: userRole },
                { name: ["isActive"], value: isActive },
            ]);
        },
        []
    );

    const tableColumns = useMemo(() => {
        if (!users) return undefined;

        return columns(
            users,
            setUserToEditID,
            setEditFields,
            setIsEditUserModalVisable,
            setDeleteUserId,
            setIsDeleteModalVisible
        );
    }, [
        setUserToEditID,
        setEditFields,
        setIsEditUserModalVisable,
        setDeleteUserId,
        setIsDeleteModalVisible,
        users,
    ]);

    const usersDisplay = useMemo(() => {
        if (!users) return undefined;
        return users.map((user) => {
            if (!user.name && (user.first_name || user.last_name)) {
                return {
                    ...user,
                    name: `${parseName(user.first_name!, user.last_name!)}`,
                };
            } else {
                return user;
            }
        });
    }, [users]);

    /**
     * Resets the form state for both the Edit & Create user forms.
     */
    const resetFormState = () => {
        setCreateUserFields(initialUserFields);
        setEditUserFields(initialUserFields);
    };

    const editUserModalHandleOk = async () => {
        setEditUserLoading(true);

        const firstName =
            editUserFields[
                editUserFields.findIndex((item) => {
                    if (Array.isArray(item.name)) {
                        return item.name[0] === "firstName";
                    } else {
                        return item.name === "firstName";
                    }
                })
            ];
        const lastName =
            editUserFields[
                editUserFields.findIndex((item) => {
                    if (Array.isArray(item.name)) {
                        return item.name[0] === "lastName";
                    } else {
                        return item.name === "lastName";
                    }
                })
            ];
        const userRole =
            editUserFields[
                editUserFields.findIndex((item) => {
                    if (Array.isArray(item.name)) {
                        return item.name[0] === "userRole";
                    } else {
                        return item.name === "userRole";
                    }
                })
            ];
        const isActive =
            editUserFields[
                editUserFields.findIndex((entry) => {
                    if (isArray(entry.name)) {
                        return entry.name[0] === "isActive";
                    } else {
                        return entry.name === "isActive";
                    }
                })
            ];
        await editUser({
            first_name: firstName.value,
            last_name: lastName.value,
            // a user id will always be selected in this scenario.
            user_id: userToEditID!,
            is_admin: !!userRole.value,
            is_active: !!isActive.value,
        })
            .unwrap()
            .then(() => {
                openNotificationWithIcon({
                    type: "success",
                    title: "User edited",
                    description: `The user: ${parseName(
                        firstName.value,
                        lastName.value
                    )}. Has been updated.`,
                });
                getUsers();
            })
            .catch(() => {
                openNotificationWithIcon({
                    type: "error",
                    title: "User edit failed",
                    description: `Something went wrong when updating this user.`,
                });
            });

        setEditUserLoading(false);
        setIsEditUserModalVisable(false);
    };

    const editUserModalHandleCancel = async () => {
        setIsEditUserModalVisable(false);
    };

    const createUserModalHandleOk = async () => {
        setCreateUserLoading(true);
        const firstName =
            createUserFields[
                createUserFields.findIndex((item) => {
                    if (Array.isArray(item.name)) {
                        return item.name[0] === "firstName";
                    } else {
                        return item.name === "firstName";
                    }
                })
            ];
        const lastName =
            createUserFields[
                createUserFields.findIndex((item) => {
                    if (Array.isArray(item.name)) {
                        return item.name[0] === "lastName";
                    } else {
                        return item.name === "lastName";
                    }
                })
            ];

        const email =
            createUserFields[
                createUserFields.findIndex((item) => {
                    if (Array.isArray(item.name)) {
                        return item.name[0] === "email";
                    } else {
                        return item.name === "email";
                    }
                })
            ];

        const userRole =
            createUserFields[
                createUserFields.findIndex((item) => {
                    if (Array.isArray(item.name)) {
                        return item.name[0] === "userRole";
                    } else {
                        return item.name === "userRole";
                    }
                })
            ];

        await createUser({
            first_name: firstName.value,
            last_name: lastName.value,
            username: email.value,
            email: email.value,
            is_admin: !!userRole.value,
        })
            .unwrap()
            .then((result) => {
                if (result) {
                    openNotificationWithIcon({
                        type: "success",
                        title: "User created",
                        description: `The user: ${parseName(
                            firstName.value,
                            lastName.value
                        )}. Has successfully been created.`,
                    });
                    getUsers();
                } else {
                    openNotificationWithIcon({
                        type: "error",
                        title: "User creation failed",
                        description: `Something went wrong when creating this user.`,
                    });
                }
            })
            .catch(() => {
                openNotificationWithIcon({
                    type: "error",
                    title: "Unsuccessful user creation.",
                    description: "",
                });
            });

        setIsCreateUserModalVisible(false);
        setCreateUserLoading(false);
    };

    const createrUserModalHandleCancel = () => {
        setIsCreateUserModalVisible(false);
    };

    const getUsers = async () => {
        refetch();
    };

    const deleteModalHandleOk = async () => {
        if (deleteUserID) {
            setDeleteLoading(true);
            await deleteUser({ user_id: deleteUserID })
                .unwrap()
                .then(() => {
                    openNotificationWithIcon({
                        type: "success",
                        title: "User successfully deleted",
                        description: "",
                    });
                    getUsers();
                })
                .catch(() => {
                    openNotificationWithIcon({
                        type: "error",
                        title: "Unsuccessful user deletion",
                        description: "",
                    });
                });

            setDeleteLoading(false);
            setIsDeleteModalVisible(false);
        }
    };
    const deleteModalHandleCancel = () => {
        setDeleteUserId(null);
        setIsDeleteModalVisible(false);
        setDeleteLoading(false);
    };

    return (
        <>
            <Modal
                title="Confirm deletion"
                visible={isDeleteModalVisible}
                onOk={deleteModalHandleOk}
                onCancel={deleteModalHandleCancel}
                confirmLoading={deleteLoading}
                bodyStyle={{ width: "25vw" }}
            >
                <p>
                    This will permanetly delete the user. This action can not be
                    undone.
                </p>
            </Modal>
            <Modal
                title="Edit User"
                visible={isEditUserModalVisable}
                onOk={editUserModalHandleOk}
                onCancel={editUserModalHandleCancel}
                confirmLoading={editUserLoading}
                afterClose={() => {
                    // Resets the form state on close.
                    resetFormState();
                }}
                okText={"Confirm changes"}
                bodyStyle={{ width: "25vw" }}
            >
                <UserForm
                    fields={editUserFields}
                    onChange={(newFields) => {
                        setEditUserFields(newFields);
                    }}
                    createUserFlag={false}
                    editUserFlag={true}
                />
            </Modal>
            <Modal
                title="Add User"
                visible={isCreateUserModalVisible}
                onOk={createUserModalHandleOk}
                onCancel={createrUserModalHandleCancel}
                confirmLoading={createUserLoading}
                afterClose={() => {
                    // Resets the form state on close.
                    resetFormState();
                }}
                okText={"Create User"}
                bodyStyle={{ width: "25vw" }}
            >
                <UserForm
                    fields={createUserFields}
                    onChange={(newFields) => {
                        setCreateUserFields(newFields);
                    }}
                    createUserFlag={true}
                    editUserFlag={false}
                />
            </Modal>
            <Typography.Title>Users</Typography.Title>
            <Divider />
            <div
                // TODO PORT CSS CLASS
                className="site-layout-background"
                style={{
                    padding: 12,
                    background: "rgba(255, 255, 255, 0.3)",
                }}
            >
                <Button
                    type="primary"
                    style={{ marginBottom: 16 }}
                    onClick={() => setIsCreateUserModalVisible(true)}
                >
                    Add User
                </Button>
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={usersDisplay}
                />
            </div>
        </>
    );
};
