import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthTokens } from "utils/auth0/auth0API";
import { BASE_URL } from "../../@Constants";
import { GroupDataState } from "./GroupData";

export const groupdataAPI = createApi({
    reducerPath: "GroupdataAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/group`,
        prepareHeaders: async (headers) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const data = await getAuthTokens();
            const access_token = data?.access_token;
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                headers.set("Content-Type", "*/*");
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getGroupData: builder.query<GroupDataState, null>({
            query: () => ({
                url: "/data",
                method: "GET",
            }),
        }),
    }),
});

export const { useGetGroupDataQuery } = groupdataAPI;
