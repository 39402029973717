import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthTokens } from "utils/auth0/auth0API";
import { BASE_URL } from "../../../@Constants";
import { DepartmentItem } from "../../../@generated";
import { AppendContentTypeHeader } from "./UsersAPI";

export type ExtendedDepartmentItem = DepartmentItem & {
    external_id: string;
    active: number;
    name: string;
    parent_id: string;
    department_id: string;
};

export const departmentsAPI = createApi({
    reducerPath: "DepartmentAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: async (headers) => {
            const data = await getAuthTokens();
            const access_token = data?.access_token; // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getDepartments: builder.query<ExtendedDepartmentItem[], null>({
            query: () => ({
                url: "/departments",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const { useGetDepartmentsQuery } = departmentsAPI;
