import { precisionRound } from "utils/utilities";
import { transactionStates } from "../@types";

export const statusColorMap: { [key in transactionStates]: string } = {
    Rejected: "volcano",
    Pending: "geekblue",
    Approved: "green",
};

export const statusColorMapProd = {
    1: "geekblue",
    2: "volcano",
    3: "green",
};

export const EftposCountActionColourMap = {
    "under & overs": "geekblue",
    "take cash": "volcano",
    "post revenue": "green",
};

export const ENV: string = process.env.REACT_APP_ENV!;

export const BASE_URL =
    process.env.REACT_APP_BACKEND_API_URL ??
    "https://figurz-backend-xr73anbcsa-ts.a.run.app";

// GST Tax decimal value
export const GST_DECIMAL = 0.1;

export const formatDollarField = (value: number) => precisionRound(value, 2);

export const fixedDollarField = (value: number) => value.toFixed(2);

export const prependDollarSign = (value: number | string) =>
    typeof value === "string" ? value : `$${formatDollarField(value)}`;
