import { useQuery } from "react-query";
import { CubeName, CubeQueryBuilder, useCubejs } from "utils/cubejs-utils";
import { ShiftReportPrompt } from "../CashupReportModel";

interface GetShiftReportPrompts {
    venueId: string;
}

export const useGetShiftReportPrompts = ({ venueId }: GetShiftReportPrompts) => {
    const { cubejsApi } = useCubejs();

    const query = CubeQueryBuilder({
        dimensions: [
            `${CubeName.shiftReportPrompt}.text`,
            `${CubeName.shiftReportPrompt}.promptCategory`,
            `${CubeName.shiftReportPrompt}.fieldType`,
            `${CubeName.venueShiftReportPrompt}.locationId`,
            `${CubeName.venueShiftReportPrompt}.id`,
        ],
        filters: [
            {
                member: `${CubeName.venueShiftReportPrompt}.venueId`,
                operator: "equals",
                values: [venueId],
            },
        ],
    }).getResult();

    const { isLoading, data: shiftReportPrompts } = useQuery(
        ["SHIFT_REPORT_PROMPTS", venueId],
        () => cubejsApi!.load(query),
        {
            enabled: Boolean(cubejsApi),
            select: (result) => {
                return result.rawData().map((d) => ({
                    promptText: d[`${CubeName.shiftReportPrompt}.text`],
                    promptCategory:
                        d[`${CubeName.shiftReportPrompt}.promptCategory`],
                    promptType: d[`${CubeName.shiftReportPrompt}.fieldType`],

                    promptLocationId:
                        d[`${CubeName.venueShiftReportPrompt}.locationId`],
                    promptId: d[`${CubeName.venueShiftReportPrompt}.id`],
                    venueId: d[`${CubeName.venueShiftReportPrompt}.venueId`],
                })) as ShiftReportPrompt[];
            },
            staleTime: Infinity,
        }
    );

    return {
        shiftReportPrompts,
        isLoading,
    };
};
