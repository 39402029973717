import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { Space } from "StyledComponents/Space";
import { dayjsFormat, toQTCDate } from "utils/date-utils";
import { downloadCSV } from "utils/dom-utils";
import { LocationType } from "./CashupReportModel";
import {
    CashupDailyReport,
    CashupDailyReportCsv,
} from "./Components/CashupDailyReport/CashupDailyReport";
import { useIntializeCashupReport } from "./Hooks/useIntializeCashupReport";
import dayjs from "dayjs";

interface Props {
    date: string;
    venueId: string;
}

const Container = styled.div`
    min-height: 280px;
    border-radius: 8px;
`;

export const CashupDailyReportContainer: React.FC<Props> = ({ date, venueId }) => {
    const { ready } = useIntializeCashupReport();
    const csvReport = useRef<CashupDailyReportCsv>({});

    const exportCashupDailyReport = useCallback(() => {
        let csvString = "";

        csvString += `Daily Report for Venue ${csvReport.current.venue},,,\n`;
        csvString += ",,,\n";

        if (csvReport.current[LocationType.pos]) {
            csvString += "POS ,,,\n";
            csvString += `${csvReport.current[LocationType.pos]} ,,,\n`;
        }

        if (csvReport.current[LocationType.gaming]) {
            csvString += "Gaming ,,,\n";
            csvString += `${csvReport.current[LocationType.gaming]} ,,,\n`;
        }

        if (csvReport.current[LocationType.keno]) {
            csvString += "Keno ,,,\n";
            csvString += `${csvReport.current[LocationType.keno]} ,,,\n`;
        }

        if (csvReport.current[LocationType.tab]) {
            csvString += "TAB ,,,\n";
            csvString += `${csvReport.current[LocationType.tab]} ,,,\n`;
        }
        if (csvReport.current.comments) {
            csvString += "Comment ,,,\n";
            csvString += `${csvReport.current.comments} ,,,\n`;
        }
        downloadCSV(
            csvString,
            `Shift report-${toQTCDate(dayjs()).format(
                dayjsFormat.dayMonthYearSeparateByDash.format
            )}.csv`
        );
    }, [date, venueId, csvReport.current]);

    const buildCsvReport = useCallback(
        (
            csvReportItems: Array<{
                section: keyof CashupDailyReportCsv;
                content: string;
            }>
        ) => {
            csvReportItems.forEach(({ content, section }) => {
                csvReport.current[section] = `${content} ,,,\n`;
            });
        },
        [date, venueId, csvReport.current]
    );
    useEffect(() => {
        csvReport.current = {};
    }, [date, venueId]);

    if (!ready) {
        return <QuantacoLoader size={100} />;
    }

    return (
        <Container>
            <Space direction="vertical" $fluid>
                <CashupDailyReport
                    updateCashupReportCsv={buildCsvReport}
                    date={date}
                    venueId={venueId}
                />
            </Space>
        </Container>
    );
};
