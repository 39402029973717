import { Spin } from "antd";
import React from "react";

export const CentredSpinner = ({
    size,
}: {
    size?: "small" | "large" | "default";
}) => {
    return (
        <div style={{ textAlign: "center" }}>
            <Spin size={size ?? "large"} />
        </div>
    );
};
