import {
    getPaymentTypesArrayWithPayout,
    PaymentTypeEnumWithPayout,
} from "@Constants/enum";
import {
    AntDFormStateForGaming,
    AntDFormStateWithoutSplit,
    TreeSelectData,
} from "@types";
import { Collapse } from "antd";
import React, { useCallback, useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { ExtendedVenueItem } from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import {
    PaymentTransactionInit,
    TransferTransactionInit,
} from "../CashupTabs/POS/@types";
import { extractSubLocation } from "../../../utils/utilities";
import { Field } from "./TransactionForms/SalesCountModalFormV2";
import {
    checkForSublocations,
    TransferModalFormV2,
} from "./TransactionForms/TransferModalFormV2";
import { appendVenueName } from "./utils";
import { PaymentModalFormV3 } from "./TransactionForms/PaymentModalFormV3";
import { entityDataParser } from "./TransactionsFormPOS";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { ExtendedTreeData } from "./TransactionsFormTransfersOffice";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { ModalFooter } from "Components/Form/ModalFooter";

const { Panel } = Collapse;

interface Props {
    onModalClose?: () => void;
    locations: ExtendedLocationItem[];
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    TransferTransactions: TransferTransactionInit[];
    TransferTransactionsReadOnly: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID: string;
    PaymentTransactionData: PaymentTransactionInit[];
    accountsData: ExtendedAccountItem[];
    onPaymentTransactionSubmission: (data: AntDFormStateForGaming[]) => void;
    onPaymentTransactionRemove: (data: AntDFormStateForGaming[]) => void;
    venuesData: ExtendedVenueItem[];
}

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

export const PaymentTransactionParserForGaming = (
    PaymentTransactions: PaymentTransactionInit[]
): AntDFormStateForGaming[] => {
    const ParsedPaymentTransactions: AntDFormStateForGaming[] =
        PaymentTransactions.map((currentPaymentTransaction) => {
            return {
                formData: [
                    {
                        name: "Reason",
                        value: currentPaymentTransaction.payment_type_id.toString(),
                    },
                    {
                        name: "Description",
                        value: currentPaymentTransaction.memo,
                    },
                    {
                        name: "Amount",
                        value: currentPaymentTransaction.amount?.toString(),
                    },
                    {
                        name: "Tender",
                        value: currentPaymentTransaction.account_split[0]
                            ?.account_id,
                    },
                ],
                formID: Math.random().toString(),
                EntityData: entityDataParser(
                    currentPaymentTransaction.entity?.entity_data
                ),
            };
        });
    ParsedPaymentTransactions.push({
        formData: [],
        formID: Math.random().toString(),
        EntityData: [],
    });
    return ParsedPaymentTransactions;
};

export const TransfersTransactionParser = (
    PaymentTransactions: TransferTransactionInit[],
    currentLocationID?: string,
    readOnly = false
): AntDFormStateWithoutSplit[] => {
    const ParsedPaymentTransactions: AntDFormStateWithoutSplit[] =
        PaymentTransactions.map((currentPaymentTransaction) => {
            return {
                formData: [
                    {
                        name: "From",
                        value: currentPaymentTransaction.from_location_id,
                    },
                    {
                        name: "To",
                        value: currentPaymentTransaction.to_location_id,
                    },
                    {
                        name: "Amount",
                        value: currentPaymentTransaction?.amount?.toString(),
                    },
                ],
                formID: Math.random().toString(),
            };
        });
    if (readOnly === false && currentLocationID !== undefined)
        ParsedPaymentTransactions.push({
            formData: [{ name: "From", value: currentLocationID }],
            formID: Math.random().toString(),
        });
    return ParsedPaymentTransactions;
};

const initialPaymentFormData = () => {
    return {
        name: "Reason",
        value: PaymentTypeEnumWithPayout["Payout"].toString(),
    };
};

export function paymentFieldsHelper(tenderAccounts: ExtendedAccountItem[]): Field[] {
    return [
        {
            name: "Reason",
            required: true,
            type: {
                name: "Dropdown",
                options: getPaymentTypesArrayWithPayout()
                    .map((paymentType) => {
                        return {
                            value: paymentType.value,
                            label: paymentType.name,
                        };
                    })
                    .filter((currentPaymentType) => {
                        return currentPaymentType.value === "3";
                    }),
            },
            colSpan: 3,
        },
        {
            name: "Description",
            required: true,
            type: {
                name: "Text",
            },
            colSpan: 8,
        },
        {
            name: "Amount",
            required: true,
            type: { name: "Money" },
            colSpan: 3,
            changeGST: true,
        },
        {
            name: "Tender",
            required: true,
            type: {
                name: "Dropdown",
                options: tenderAccounts.map((tenderAccount) => {
                    return {
                        value: tenderAccount.account_id,
                        label: tenderAccount.name,
                    };
                }),
            },
            colSpan: 5,
        },
        {
            name: "Customer Details",
            type: {
                name: "Modal",
            },
            colSpan: 3,
        },
        { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
    ];
}

const TransactionsFormGamingComponent: React.FC<Props> = ({
    onModalClose,
    locations,
    onTransferTransactionSubmission,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
    TransferTransactions,
    TransferTransactionsReadOnly,
    hierarchicalLocations,
    currentLocationID,
    PaymentTransactionData,
    accountsData,
    venuesData,
}) => {
    const tenderAccounts = useMemo(
        () =>
            accountsData.filter(
                (currentAccountItem) => currentAccountItem.payout_tender_type
            ),
        [accountsData]
    );
    const venueNameById = useMemo(() => {
        if (venuesData)
            return venuesData.reduce<{ [venueID: string]: string }>(
                (accumulator, current) => {
                    accumulator[current.venue_id] = current.name;

                    return accumulator;
                },
                {}
            );
    }, [venuesData]);

    // Appends a randomly generated ID to each form to ensure uniqueIDs.
    const initialPaymentTransactionsDataWithAppendedFormID: AntDFormStateForGaming[] =
        useMemo(
            () =>
                PaymentTransactionData.length > 0
                    ? PaymentTransactionParserForGaming(PaymentTransactionData)
                    : [
                          {
                              formData: [initialPaymentFormData()],
                              formID: Math.random().toString(),
                              EntityData: [],
                          },
                      ],
            []
        );
    // Appends a randomly generated ID to each form to ensure uniqueIDs.
    const initialTransfersTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactions.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactions,
                          currentLocationID
                      )
                    : [
                          {
                              formData: [{ name: "From", value: currentLocationID }],
                              formID: Math.random().toString(),
                          },
                      ],
            []
        );

    const initialTransfersReadOnlyTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactionsReadOnly.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactionsReadOnly,
                          currentLocationID,
                          true
                      )
                    : [],
            []
        );
    const [selectedVenue] = useLocalStorage<string | null>("selectedVenue", null);

    const processTransformLocations = useCallback(
        (isReadonly: boolean) => {
            const result: ExtendedTreeData[] = [],
                floatLocations: ExtendedLocationItemWithChildren[] = [];
            hierarchicalLocations.forEach((location, index) => {
                if (location.location_type !== 14) {
                    if (location.venue === selectedVenue)
                        result.push({
                            key: index,
                            title: appendVenueName(location, venueNameById),
                            value: location.location_id,
                            children: location.sub_locations
                                ? isReadonly
                                    ? extractSubLocation(
                                          location.sub_locations,
                                          location.name
                                      ).sort((subLocationA, subLocationB) =>
                                          subLocationA.title.localeCompare(
                                              subLocationB.title
                                          )
                                      )
                                    : extractSubLocation(
                                          location.sub_locations
                                      ).sort((subLocationA, subLocationB) =>
                                          subLocationA.title.localeCompare(
                                              subLocationB.title
                                          )
                                      )
                                : [],
                            disabled: checkForSublocations(location),
                            venue: location.venue,
                        });
                } else {
                    floatLocations.push(location);
                }
            });

            if (floatLocations.length !== 0) {
                const floatLocationsChildren = extractSubLocation(
                    floatLocations
                ).sort((subLocationA, subLocationB) =>
                    subLocationA.title.localeCompare(subLocationB.title)
                );

                result.forEach((location) => {
                    if (location.title.includes("Safe")) {
                        const itemsWithMatchingVenueId =
                            floatLocationsChildren.filter(
                                (item) => item.venue === location.venue
                            );
                        location.children = location.children?.concat(
                            itemsWithMatchingVenueId
                        );
                        location.disabled = true;
                    }
                });
            }
            return result;
        },
        [hierarchicalLocations]
    );
    const transformLocations = processTransformLocations(true);
    const transformLocationsReadonly = processTransformLocations(true);
    const transferFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                type: {
                    name: "TreeSelectLocked",
                    locations: transformLocations.sort((locationA, locationB) =>
                        locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },
                colSpan: 8,
            },
            {
                name: "To",
                required: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocations.sort((locationA, locationB) =>
                        locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
            { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
        ],
        [locations]
    );

    const transferReadOnlyFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                disabled: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },

                colSpan: 8,
            },
            {
                name: "To",
                disabled: true,
                required: true,

                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                disabled: true,
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
        ],
        [locations]
    );

    const paymentFields: Field[] = useMemo(
        () => paymentFieldsHelper(tenderAccounts),
        [tenderAccounts]
    );

    return (
        <StyledFormContainer>
            <Collapse accordion expandIconPosition="end">
                <Panel
                    header={<StyledPanelHeader>Transfers From</StyledPanelHeader>}
                    key="1"
                >
                    <TransferModalFormV2
                        currentLocationID={currentLocationID}
                        fields={transferFields}
                        initialDataForForms={
                            initialTransfersTransactionsDataWithAppendedFormID
                        }
                        onChange={onTransferTransactionSubmission}
                    />
                </Panel>
                {initialTransfersReadOnlyTransactionsDataWithAppendedFormID.length >
                    0 && (
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="2"
                    >
                        <TransferModalFormV2
                            fields={transferReadOnlyFields}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                        />
                    </Panel>
                )}

                <Panel
                    header={<StyledPanelHeader>Payout</StyledPanelHeader>}
                    key="4"
                >
                    <PaymentModalFormV3
                        fields={paymentFields}
                        initialDataForForms={
                            initialPaymentTransactionsDataWithAppendedFormID
                        }
                        onSubmit={onPaymentTransactionSubmission}
                        onRemove={onPaymentTransactionRemove}
                        tenderAccounts={tenderAccounts}
                    />
                </Panel>
            </Collapse>
            <ModalFooter
                onClose={onModalClose}
                onSave={onModalClose}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
        </StyledFormContainer>
    );
};

export const TransactionsFormGaming = React.memo(TransactionsFormGamingComponent);
