import { InitDataResponseType } from "Pages/CashupHome/CashupTabs/POS/@types";
import React, { useEffect } from "react";
import { usePusherSubscribeMutation } from "Redux/StateSlices/Pusher";
import { usePusher } from "./usePusher";

export const useCashupRealtime = ({
    cashupIds,
    onCashupInitialized,
    onCashupUpdated,
}: {
    cashupIds: string[];
    onCashupInitialized: (cashup: InitDataResponseType) => void;
    onCashupUpdated: (cashup: InitDataResponseType) => void;
}): { isLoading: boolean } => {
    const [isLoading, setIsLoading] = React.useState(false);
    const { pusher } = usePusher();
    const [sendSubscriptionNotice] = usePusherSubscribeMutation();

    const subscribeToCashupIds = React.useCallback(() => {
        setIsLoading(true);
        setTimeout(() => {
            cashupIds.forEach((cashupId) => pusher?.subscribe(cashupId));
            sendSubscriptionNotice({ cashup_ids: cashupIds });
            setIsLoading(false);
        }, 100);
    }, [cashupIds, pusher, sendSubscriptionNotice]);

    const unsubscribeAllCashups = React.useCallback(() => {
        cashupIds.forEach((cashupId) => {
            pusher?.unsubscribe(cashupId);
        });
    }, [cashupIds, pusher]);

    useEffect(() => {
        if (pusher) subscribeToCashupIds();
        return () => unsubscribeAllCashups();
    }, [subscribeToCashupIds, unsubscribeAllCashups, pusher, cashupIds]);

    const cashupIDCheck = React.useCallback(
        (cashup: InitDataResponseType) => {
            // ! What this solves.
            // If the user rapidly change the selected location before all the previous "init_data" event load it results in unrelated cashup data
            // being pushed to the newly opened location, to prevent this we check to see if the CashupID that is part of the "init_data" payload
            // matches the "cashupIds" this instance of "useCashupRealtime" was passed.
            if (cashupIds.includes(cashup.cashup_id)) {
                onCashupInitialized(cashup);
            }
        },
        [cashupIds]
    );

    useEffect(() => {
        pusher?.allChannels().forEach((channel) => {
            // There are cases where the pusher object shares channels across Spreadsheets/sections
            // Therefore we only bind events for channel names that are within the CashupIDs array
            if (cashupIds.includes(channel.name)) {
                channel.bind("init_data", cashupIDCheck);
                channel.bind("update_cashup", onCashupUpdated);
            }
        });
    }, [onCashupInitialized, onCashupUpdated, pusher]);

    return { isLoading };
};
