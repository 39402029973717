import DatePicker from "antd/lib/date-picker";
import React, { useEffect, useState } from "react";
import { dayjsFormat, QTCRange, toQTCRange } from "utils/date-utils";
import dayjs from "dayjs";
const { RangePicker: AntdRangePicker } = DatePicker;

interface Props {
    onChange: (newRange?: QTCRange) => void;
    defaultRange?: QTCRange;
}

let RangePicker: React.FC<Props> = ({ onChange, defaultRange }) => {
    const [selectedRange, setSelectedRange] = useState<QTCRange>(
        defaultRange ?? toQTCRange(dayjs(), dayjs())
    );
    const [selectorOpen, setSelectorOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!selectorOpen) {
            onChange(selectedRange);
        }
    }, [onChange, selectedRange, selectorOpen]);

    return (
        <AntdRangePicker
            onChange={(newRange: any) => {
                if (newRange)
                    setSelectedRange({
                        start: newRange[0],
                        end: newRange[1],
                    });
            }}
            open={selectorOpen}
            value={
                selectedRange ? [selectedRange.start, selectedRange.end] : undefined
            }
            onOpenChange={setSelectorOpen}
            format={dayjsFormat.dayMonthYearSeparateByDash.format}
        />
    );
};

RangePicker = React.memo(RangePicker);

export { RangePicker };
