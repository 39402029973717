import { PaymentTypeEnumWithPayout } from "@Constants/enum";
import { AntDFormStateForGaming, AntDFormStateWithoutSplit } from "@types";
import { Collapse } from "antd";
import React, { useCallback, useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { ExtendedVenueItem } from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import {
    PaymentTransactionInit,
    TransferTransactionInit,
} from "../CashupTabs/POS/@types";
import { extractSubLocation } from "../../../utils/utilities";
import { Field } from "./TransactionForms/SalesCountModalFormV2";
import {
    checkForSublocations,
    TransferModalFormV2,
} from "./TransactionForms/TransferModalFormV2";
import {
    paymentFieldsHelper,
    PaymentTransactionParserForGaming,
    TransfersTransactionParser,
} from "./TransactionsFormGaming";
import { appendVenueName } from "./utils";
import { PaymentModalFormV3 } from "./TransactionForms/PaymentModalFormV3";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { ExtendedTreeData } from "./TransactionsFormTransfersOffice";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { ModalFooter } from "Components/Form/ModalFooter";

const { Panel } = Collapse;

interface Props {
    onModalClose: () => void;
    locations: ExtendedLocationItem[];
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    TransferTransactions: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID: string;
    accountsData: ExtendedAccountItem[];
    PaymentTransactionData: PaymentTransactionInit[];
    onPaymentTransactionSubmission: (data: AntDFormStateForGaming[]) => void;
    onPaymentTransactionRemove: (data: AntDFormStateForGaming[]) => void;
    TransferTransactionsReadOnly: TransferTransactionInit[];
    venuesData: ExtendedVenueItem[];
}

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

const initialPaymentFormData = () => {
    return {
        name: "Reason",
        value: PaymentTypeEnumWithPayout["Payout"].toString(),
    };
};

const TransactionsFormTransferAndPaymentComponent: React.FC<Props> = ({
    onModalClose,
    locations,
    onTransferTransactionSubmission,
    TransferTransactions,
    hierarchicalLocations,
    currentLocationID,
    accountsData,
    PaymentTransactionData,
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
    TransferTransactionsReadOnly,
    venuesData,
}) => {
    const tenderAccounts = useMemo(
        () =>
            accountsData.filter(
                (currentAccountItem) => currentAccountItem.payout_tender_type
            ),
        [accountsData]
    );

    const venueNameById = useMemo(() => {
        if (venuesData)
            return venuesData.reduce<{ [venueID: string]: string }>(
                (accumulator, current) => {
                    accumulator[current.venue_id] = current.name;

                    return accumulator;
                },
                {}
            );
    }, [venuesData]);

    // Appends a randomly generated ID to each form to ensure uniqueIDs.
    const initialTransfersTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactions.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactions,
                          currentLocationID
                      )
                    : [
                          {
                              formData: [{ name: "From", value: currentLocationID }],
                              formID: Math.random().toString(),
                          },
                      ],
            []
        );

    const [selectedVenue, _] = useLocalStorage<string | null>("selectedVenue", null);

    const processTransformLocations = useCallback(
        (isReadonly: boolean) => {
            const result: ExtendedTreeData[] = [],
                floatLocations: ExtendedLocationItemWithChildren[] = [];
            hierarchicalLocations.forEach((location, index) => {
                if (location.location_type !== 14) {
                    if (location.venue === selectedVenue)
                        result.push({
                            key: index,
                            title: appendVenueName(location, venueNameById),
                            value: location.location_id,
                            children: location.sub_locations
                                ? isReadonly
                                    ? extractSubLocation(
                                          location.sub_locations,
                                          location.name
                                      ).sort((subLocationA, subLocationB) =>
                                          subLocationA.title.localeCompare(
                                              subLocationB.title
                                          )
                                      )
                                    : extractSubLocation(
                                          location.sub_locations
                                      ).sort((subLocationA, subLocationB) =>
                                          subLocationA.title.localeCompare(
                                              subLocationB.title
                                          )
                                      )
                                : [],
                            disabled: checkForSublocations(location),
                            venue: location.venue,
                        });
                } else {
                    floatLocations.push(location);
                }
            });
            if (floatLocations.length !== 0) {
                const floatLocationsChildren = extractSubLocation(
                    floatLocations
                ).sort((subLocationA, subLocationB) =>
                    subLocationA.title.localeCompare(subLocationB.title)
                );

                result.forEach((location) => {
                    if (location.title.includes("Safe")) {
                        const itemsWithMatchingVenueId =
                            floatLocationsChildren.filter(
                                (item) => item.venue === location.venue
                            );
                        location.children = location.children?.concat(
                            itemsWithMatchingVenueId
                        );
                        location.disabled = true;
                    }
                });
            }
            return result;
        },
        [hierarchicalLocations]
    );

    const transformLocations = processTransformLocations(true);
    const transformLocationsReadonly = processTransformLocations(true);
    const transferFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                type: {
                    name: "TreeSelectLocked",
                    locations: transformLocations.sort((locationA, locationB) =>
                        locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },
                colSpan: 8,
            },
            {
                name: "To",
                required: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocations.sort((locationA, locationB) =>
                        locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
            { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
        ],
        [locations]
    );
    const initialPaymentTransactionsDataWithAppendedFormID: AntDFormStateForGaming[] =
        useMemo(
            () =>
                PaymentTransactionData.length > 0
                    ? PaymentTransactionParserForGaming(PaymentTransactionData)
                    : [
                          {
                              formData: [initialPaymentFormData()],
                              formID: Math.random().toString(),
                              EntityData: [],
                          },
                      ],
            []
        );

    const transferReadOnlyFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                disabled: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },

                colSpan: 8,
            },
            {
                name: "To",
                disabled: true,
                required: true,

                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                disabled: true,
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
        ],
        [locations]
    );
    const initialTransfersReadOnlyTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactionsReadOnly.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactionsReadOnly,
                          currentLocationID,
                          true
                      )
                    : [],
            []
        );

    const paymentFields: Field[] = useMemo(
        () => paymentFieldsHelper(tenderAccounts),
        [tenderAccounts]
    );

    return (
        <StyledFormContainer>
            <Collapse accordion expandIconPosition="end">
                <Panel
                    header={<StyledPanelHeader>Transfers From</StyledPanelHeader>}
                    key="1"
                >
                    <TransferModalFormV2
                        currentLocationID={currentLocationID}
                        fields={transferFields}
                        initialDataForForms={
                            initialTransfersTransactionsDataWithAppendedFormID
                        }
                        onChange={onTransferTransactionSubmission}
                    />
                </Panel>
                {initialTransfersReadOnlyTransactionsDataWithAppendedFormID.length >
                    0 && (
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="2"
                    >
                        <TransferModalFormV2
                            fields={transferReadOnlyFields}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                        />
                    </Panel>
                )}
                <Panel
                    header={<StyledPanelHeader>Payout</StyledPanelHeader>}
                    key="3"
                >
                    <PaymentModalFormV3
                        fields={paymentFields}
                        initialDataForForms={
                            initialPaymentTransactionsDataWithAppendedFormID
                        }
                        onSubmit={onPaymentTransactionSubmission}
                        onRemove={onPaymentTransactionRemove}
                        tenderAccounts={tenderAccounts}
                    />
                </Panel>
            </Collapse>
            <ModalFooter
                onClose={onModalClose}
                onSave={onModalClose}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
        </StyledFormContainer>
    );
};

export const TransactionsFormTransferAndPayment = React.memo(
    TransactionsFormTransferAndPaymentComponent
);
