import { FieldData } from "@interfaces";
import { Button, Col, Divider, Form, Input, Result, Row } from "antd";
import React from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { renderDollarColumn } from "utils/generalFunctions/AntdTable";
import {
    StyledActionContainer,
    StyledCancelButton,
    StyledSaveButton,
} from "../SalesTotalForm";

interface Props {
    valueToBalance: number | undefined;
    accounts: ExtendedAccountItem[] | ExtendedClassItem[];
    closeModal: () => void;
    formFields: FieldData[];
    setFormFields: React.Dispatch<React.SetStateAction<FieldData[]>>;
    CurrentSplitAmount: number;
    setCurrentSplitAmount: React.Dispatch<React.SetStateAction<number>>;
    overrideFieldName?: string;
    isPayoutForGaming?: boolean;
}

const TotalRemainingSection = ({
    currentAmount,
    valueToBalance,
}: {
    currentAmount: number;
    valueToBalance: number;
}) => (
    <Col>
        <Row>
            <Col>{`Amount $${currentAmount}`}</Col>
        </Row>
        <Row>
            <Col>{`Remaining ${renderDollarColumn(
                valueToBalance - currentAmount
            )}`}</Col>
        </Row>
        <Row>
            <Col>{`Total $${valueToBalance}`}</Col>
        </Row>
    </Col>
);

export const SplitModal = ({
    valueToBalance,
    accounts,
    closeModal,
    formFields,
    setFormFields,
    setCurrentSplitAmount,
    CurrentSplitAmount,
    overrideFieldName,
    isPayoutForGaming,
}: Props) => {
    const includeChequeNo = (accounts as ExtendedAccountItem[]).find(
        (account) => account.name.trim().toLowerCase() === "cheque"
    );
    const accountsWithoutCheque = (accounts as ExtendedAccountItem[]).filter(
        (account) => account.name.trim().toLowerCase() !== "cheque"
    );
    if (valueToBalance === undefined) {
        return (
            <Result
                title={`Please fill out the '${
                    overrideFieldName ?? "Amount"
                }' field to proceed`}
                extra={
                    <Button type="primary" key="console" onClick={closeModal}>
                        Close modal
                    </Button>
                }
            />
        );
    }

    return (
        <div>
            <Form
                layout="vertical"
                fields={formFields}
                onFieldsChange={(_, allFields) => {
                    let CumulativeSum = 0;
                    allFields.forEach((currentField) => {
                        if (currentField.value) {
                            if (Array.isArray(currentField.name)) {
                                if (currentField.name[0] !== "cheque_no") {
                                    CumulativeSum =
                                        CumulativeSum +
                                        parseFloat(currentField.value);
                                }
                            } else {
                                if (currentField.name !== "cheque_no") {
                                    CumulativeSum =
                                        CumulativeSum +
                                        parseFloat(currentField.value);
                                }
                            }
                        }
                    });
                    setCurrentSplitAmount(CumulativeSum);
                    setFormFields(allFields);
                }}
            >
                <Row gutter={4}>
                    {accountsWithoutCheque.map((currentAccount, index) => {
                        return (
                            <Col key={index} span={8}>
                                <Form.Item
                                    name={currentAccount.name}
                                    label={currentAccount.name}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                        );
                    })}

                    {includeChequeNo !== undefined && (
                        <Col span={8}>
                            <Form.Item name={"Cheque"} label={"Cheque"}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    )}
                    {!!isPayoutForGaming && includeChequeNo !== undefined && (
                        <Col span={8}>
                            <Form.Item name={"cheque_no"} label="Cheque Number">
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col span={16}> </Col>
                    <Col span={8} style={{ padding: "12px 0px" }}>
                        <TotalRemainingSection
                            currentAmount={CurrentSplitAmount}
                            valueToBalance={valueToBalance}
                        />
                    </Col>
                </Row>
                <Divider style={{ marginTop: 0 }} />
                <StyledActionContainer>
                    <StyledSaveButton htmlType="submit" onClick={closeModal}>
                        Close
                    </StyledSaveButton>
                </StyledActionContainer>
            </Form>
        </div>
    );
};
