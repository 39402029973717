import React, { useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { useIntializeCashupReport } from "./Hooks/useIntializeCashupReport";
import { DefaultLayout } from "Components/Layout";
import { Space } from "StyledComponents/Space";
import { CashupReportSelector } from "./Components/CashupReportSelector";
import {
    CashupDetailReport,
    CsvCashupDataByLocation,
} from "./Components/CashupDetailReport";
import { downloadCSV } from "utils/dom-utils";
import { useDispatch } from "react-redux";
import { updateDetailedShiftReportStore } from "Redux/StateSlices/ShiftReportState";

interface PageParams {
    venueId: string;
    date: string;
}

export const CashupReportDetailPage = () => {
    const csvDataByLocation = useRef<CsvCashupDataByLocation[]>([]);
    const { ready } = useIntializeCashupReport();
    const { venueId, date } = useParams<PageParams>();

    const dispatch = useDispatch();

    useEffect(() => {
        if (ready) {
            dispatch(
                updateDetailedShiftReportStore({
                    newVenue: venueId,
                    newDate: date,
                })
            );
        }
    }, [date, ready, dispatch, venueId, updateDetailedShiftReportStore]);

    const updateCsvData = useCallback(
        ({ locationName, csv }: CsvCashupDataByLocation) => {
            if (
                !csvDataByLocation.current.find(
                    ({ locationName: locName }) => locName === locationName
                )
            )
                csvDataByLocation.current.push({
                    locationName,
                    csv,
                });
        },
        []
    );

    const exportCashupReport = useCallback(() => {
        const reportTitle = "Cashup report";

        downloadCSV(
            csvDataByLocation.current.reduce((result, { locationName, csv }) => {
                if (!result) {
                    result += `${reportTitle},,,\n`;
                }
                result += `${locationName},,,\n`;
                result += csv;
                result += ",,,\n";
                result += ",,,\n";

                return result;
            }, ""),
            `${reportTitle}.csv`
        );
    }, []);

    useEffect(() => {
        if (csvDataByLocation.current.length) {
            csvDataByLocation.current = [];
        }
    }, [venueId, date]);

    if (!ready) {
        return <QuantacoLoader size={100} />;
    }

    return (
        <DefaultLayout>
            <Space $fluid size="large" direction="vertical">
                <CashupReportSelector
                    onExport={exportCashupReport}
                    exportBtnDisabled={!venueId || !date}
                    singleDateSelector
                    isDetailedReport
                />
                <CashupDetailReport updateCsvDataForReport={updateCsvData} />
            </Space>
        </DefaultLayout>
    );
};
