import { Row, Select, Col, DatePicker, Button } from "antd";
import { SelectValue } from "antd/lib/select";
import { RangePicker } from "Components/RangePicker/RangePicker";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useGetGroupDataQuery } from "Redux/StateSlices/GroupDataAPI";
import {
    changeDate,
    changeRange,
    changeSelectedReport,
    changeVenue,
} from "Redux/StateSlices/ShiftReportState";
import { useAppDispatch, useAppSelector } from "Redux/TypedReduxFunctions";
import styled from "styled-components";
import { dayjsFormat, QTCDate, QTCRange, toQTCRange } from "utils/date-utils";
import dayjs from "dayjs";

const { Option } = Select;

interface Props {
    singleDateSelector?: boolean;
    onExport?: () => void;
    exportBtnDisabled?: boolean;
    isDetailedReport?: boolean;
    shouldShowReportSelector?: boolean;
}

let CashupReportSelector: React.FC<Props> = ({
    singleDateSelector = false,
    exportBtnDisabled = false,
    onExport,
    isDetailedReport = false,
    shouldShowReportSelector = false,
}) => {
    const { selectedDate, selectedVenueId, selectedRange, selectedReport } =
        useAppSelector((state) => state.shiftReportSlice);

    const { parsedDate, parsedRange } = useMemo(() => {
        return {
            parsedDate: dayjs(selectedDate),
            parsedRange: selectedRange
                ? toQTCRange(selectedRange?.start, selectedRange?.end)
                : undefined,
        };
    }, [selectedDate, selectedRange]);

    const dispatch = useAppDispatch();
    const { data: groupData } = useGetGroupDataQuery(null);
    const history = useHistory();

    const onSelectedRangeChanged = useCallback(
        (newRange?: QTCRange) =>
            newRange &&
            dispatch(
                changeRange({
                    newRange: {
                        start: newRange.start.format(),
                        end: newRange.end.format(),
                    },
                })
            ),
        [dispatch]
    );

    const onSelectedDateChanged = useCallback(
        (newDate: QTCDate | null) =>
            newDate &&
            dispatch(
                changeDate({
                    newDate: newDate.format(),
                })
            ),
        [dispatch]
    );

    if (!groupData) {
        throw new Error(
            "No group data in CahsupReportSelector, this should be impossible !!"
        );
    }

    const venueOptions =
        groupData?.venues?.map(({ venue_id: id, name }) => ({ id, name })) ?? [];

    return (
        <Col>
            <Row>
                <InputSelector>
                    <Col style={{ flexWrap: "wrap" }}>
                        <Row style={{ gap: 12 }}>
                            <Select
                                size="middle"
                                style={{
                                    width: 200,
                                    marginBottom: 6,
                                }}
                                placeholder="Venue"
                                onChange={(value: SelectValue) => {
                                    value &&
                                        dispatch(
                                            changeVenue({
                                                newVenue: value.toString(),
                                            })
                                        );
                                }}
                                value={selectedVenueId}
                            >
                                {venueOptions.map(({ id, name }) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                            <div style={{ display: "inline" }}>
                                {singleDateSelector ? (
                                    <DatePicker
                                        value={parsedDate}
                                        format={
                                            dayjsFormat.dayMonthYearSeparateByDash
                                                .format
                                        }
                                        onChange={onSelectedDateChanged}
                                    />
                                ) : (
                                    <RangePicker
                                        onChange={onSelectedRangeChanged}
                                        defaultRange={parsedRange}
                                    />
                                )}
                            </div>
                            {onExport ? (
                                <Button
                                    onClick={onExport}
                                    disabled={exportBtnDisabled}
                                    style={{
                                        width: 200,
                                        marginRight: 12,
                                        marginTop: 6,
                                        marginBottom: 6,
                                    }}
                                >
                                    Export
                                </Button>
                            ) : null}

                            {isDetailedReport && (
                                <Button
                                    onClick={() => history.goBack()}
                                    disabled={exportBtnDisabled}
                                    style={{ width: 200 }}
                                >
                                    Return to summary
                                </Button>
                            )}
                        </Row>
                    </Col>
                </InputSelector>
            </Row>
            {shouldShowReportSelector && (
                <Row
                    style={{
                        alignContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        gap: 12,
                        padding: 12,
                        height: "100%",
                    }}
                >
                    <div>{"Current Report"}</div>
                    <Button
                        type={selectedReport === "Summary" ? "primary" : "default"}
                        onClick={() => {
                            dispatch(
                                changeSelectedReport({
                                    newReport: "Summary",
                                })
                            );
                        }}
                    >
                        Summary
                    </Button>
                    <Button
                        type={selectedReport === "Detailed" ? "primary" : "default"}
                        onClick={() => {
                            dispatch(
                                changeSelectedReport({
                                    newReport: "Detailed",
                                })
                            );
                        }}
                    >
                        Detailed
                    </Button>
                </Row>
            )}
        </Col>
    );
};

CashupReportSelector = React.memo(CashupReportSelector);

export { CashupReportSelector };

const InputSelector = styled(Col)`
    margin-right: auto;
`;
