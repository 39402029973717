import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Modal, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { chain, DebouncedFunc } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetGroupDataQuery } from "Redux/StateSlices/GroupDataAPI";
import { useAppSelector } from "Redux/TypedReduxFunctions";
import {
    formatDateStringForDisplay,
    dayjsFormat,
    toQTCDate,
    toQTCRange,
    formatForBackend,
} from "utils/date-utils";
import { downloadCSV } from "utils/dom-utils";
import { useQuery, gql } from "@apollo/client";
import { roundToTwoDecimal } from "utils/utilities";
import { calculateCashupReport, ClassTypeEnum } from "../CashupReportUtils";
import { useGetCashups } from "../Hooks/useGetCashups";
import dayjs from "dayjs";
import "./CashupReportTable.css";
import JsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import { getWindowDimensions } from "../Hooks/useWindowDimensions";
import { CashupReportTableHOC } from "../utils/CashupReportTableHOC";
import styled from "styled-components";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";

interface TableRow {
    key: string;
    date: string;
    food: string;
    beverage: string;
    accommodation: string;
    turnover: string;
    net_profit: string;
    total: string;
    other: string;
}

export type CashupReportColumnName = keyof TableRow;
export interface CashupReportTableComponentProps {
    openModal: boolean;
    setOpenModal: (open: boolean) => void;
}

interface DataForCsv {
    date: string;
    food: string;
    beverage: string;
    other: string;
    accommodation?: string;
    turnover?: string;
    net_profit?: string;
    total: string;
    variance: string;
}

type CsvHeader = keyof DataForCsv;
interface dataForTable {
    beverage: string;
    food: string;
    accommodation: string;
    turnover: string;
    net_profit: string;
    other: string;
    total: string;
    key: string;
    date: string;
    isEmpty?: boolean;
    variance: string;
}

const StyledModal = styled(Modal)`
    .ant-modal-content {
        width: fit-content;
    }
`;
const VARIANCE_DATE_RANGE = gql`
    query CubeQuery($startDate: String!, $endDate: String!) {
        cube(
            where: {
                account: { varianceAccount: { equals: "true" } }
                splits: { splitDate: { inDateRange: [$startDate, $endDate] } }
            }
        ) {
            splits(orderBy: { sumTotalIncTax: desc }) {
                sumTotalIncTax
                splitDate {
                    value
                    day
                }
            }
        }
    }
`;

export function useWindowDimensions({
    fetchDimensions,
}: {
    fetchDimensions: DebouncedFunc<(...args: any) => void | undefined>;
}) {
    const [windowDimensions, setWindowDimensions] = useState<{
        width: number;
        height: number;
    }>(getWindowDimensions);

    useEffect(() => {
        function handleResize() {
            const result = fetchDimensions();
            if (result) setWindowDimensions(result);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [fetchDimensions]);

    return useMemo(() => windowDimensions, [windowDimensions]);
}

const CashupReportTableComponent: React.FC<CashupReportTableComponentProps> = ({
    openModal,
    setOpenModal,
}) => {
    const { selectedRange, selectedVenueId } = useAppSelector(
        (state) => state.shiftReportSlice
    );
    const parsedRange = useMemo(
        () =>
            selectedRange
                ? toQTCRange(selectedRange.start, selectedRange.end)
                : undefined,
        [selectedRange]
    );

    const { isLoading, cashups } = useGetCashups({
        range: parsedRange,
        venueId: selectedVenueId,
    });

    const { data: varianceData, error: varianceError } = useQuery(
        VARIANCE_DATE_RANGE,
        {
            variables: {
                startDate: dayjs(selectedRange?.start).format("YYYY-MM-DD"),
                endDate: dayjs(selectedRange?.end).format("YYYY-MM-DD"),
            },
            fetchPolicy: "network-only",
        }
    );
    const variance = useMemo(() => {
        const result = varianceData
            ? varianceData.cube.map((v: any) => ({
                  date: formatForBackend(v.splits.splitDate.day),
                  variance: v.splits.sumTotalIncTax,
              }))
            : 0;
        return result;
    }, [varianceData]);
    const { data: groupData } = useGetGroupDataQuery(null);
    const { push } = useHistory();

    if (!groupData) {
        throw new Error(
            "No group data in CashupReportTable, this should be impossible !!"
        );
    }

    const isAccommodationExist = useMemo(
        () =>
            groupData.classes.find(
                (data) => Number(data.class_type) === ClassTypeEnum.ACCOMMODATION
            ) !== undefined,
        [groupData]
    );

    const isGamingExist = useMemo(
        () =>
            groupData.classes.find(
                (data) => Number(data.class_type) === ClassTypeEnum.GAMING
            ) !== undefined,
        [groupData]
    );
    const initialColumns: CsvHeader[] = useMemo(() => {
        const result = ["food", "beverage", "other", "total", "variance"];
        if (isAccommodationExist) {
            result.splice(3, 0, "accommodation");
        }
        if (isGamingExist) {
            result.splice(-2, 0, "turnover", "net_profit");
        }
        return result as CsvHeader[];
    }, [isAccommodationExist, isGamingExist]);

    const initialColumnsTable = useMemo(() => {
        const result = ["Date", "Food", "Beverage", "Other", "Total", "Variance"];
        if (isAccommodationExist) {
            result.splice(4, 0, "Accommodation");
        }
        if (isGamingExist) {
            result.splice(-2, 0, "Turnover", "Net Profit");
        }
        return result;
    }, [isAccommodationExist, isGamingExist]);

    const debouncedWindowDimensions = useDebouncedCallback(getWindowDimensions, 100);

    const { width } = useWindowDimensions({
        fetchDimensions: debouncedWindowDimensions,
    });
    const isSmall = useMemo(() => (width < 792 ? true : false), [width]);

    const columns = useMemo(() => {
        const result: ColumnsType<TableRow> = initialColumns.map((field) => ({
            title: field
                .split("_")
                .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
                .join(" "),
            dataIndex: field,
            key: field,
            sorter: isSmall
                ? undefined
                : (firstObject, secondObject) =>
                      parseFloat(
                          firstObject[field as CashupReportColumnName].slice(2)
                      ) -
                      parseFloat(
                          secondObject[field as CashupReportColumnName].slice(2)
                      ),
        }));

        result.splice(0, 0, {
            title: "Date",
            dataIndex: "date",
            key: "date",
            width: isSmall ? 120 : 300,
            fixed: isSmall ? "left" : undefined,
            sorter: isSmall
                ? undefined
                : (date1, date2) =>
                      new Date(dayjs(date1.date).format("L")).valueOf() -
                      new Date(dayjs(date2.date).format("L")).valueOf(),
            render: (date: string) =>
                toQTCDate(date).format(
                    isSmall
                        ? dayjsFormat.dayMonthYearSlash.format
                        : dayjsFormat.dateWithWeekDay.format
                ) !== "Invalid Date"
                    ? toQTCDate(date).format(
                          isSmall
                              ? dayjsFormat.dayMonthYearSlash.format
                              : dayjsFormat.dateWithWeekDay.format
                      )
                    : date,
        });

        return result;
    }, [isSmall, initialColumns]);

    const aggregatedCashups = calculateCashupReport({
        classTypeById: chain(groupData?.classes)
            .keyBy("class_id")
            .mapValues("class_type")
            .value(),
        cashups,
    }).map(
        ({
            beverage,
            food,
            accommodation,
            turnover,
            net_profit,
            other,
            total,
            ...rest
        }) => ({
            ...rest,
            beverage: `$ ${roundToTwoDecimal(beverage)}`,
            food: `$ ${roundToTwoDecimal(food)}`,
            accommodation: `$ ${roundToTwoDecimal(accommodation!)}`,
            turnover: `$ ${roundToTwoDecimal(turnover!)}`,
            net_profit: `$ ${roundToTwoDecimal(net_profit!)}`,
            other: `$ ${roundToTwoDecimal(other)}`,
            total: `$ ${roundToTwoDecimal(total)}`,
        })
    );

    const calculateVariance = (variance: any, date: Date) => {
        if (variance === 0) {
            return 0;
        } else {
            const varianceOnSpecificDate = variance.find(
                (v: any) => v.date === dayjs(date).format(dayjsFormat.default.format)
            );
            if (!varianceOnSpecificDate) {
                return 0;
            } else {
                return varianceOnSpecificDate.variance as string;
            }
        }
    };
    // Results are sorted (ASC) then missing dates are filled with an added flag to signal there is no data available for the date.
    const dataSource: dataForTable[] = useMemo(() => {
        if (parsedRange) {
            const filledOutItems: dataForTable[] = [];

            const end = parsedRange.end.toDate();
            for (
                let d = parsedRange.start.toDate();
                d <= end;
                d.setDate(d.getDate() + 1)
            ) {
                const currentDayCashup = aggregatedCashups.find((currentItem) => {
                    return (
                        currentItem.date ===
                        dayjs(d).format(dayjsFormat.default.format)
                    );
                });
                const varianceToday = calculateVariance(variance, d);
                if (currentDayCashup) {
                    filledOutItems.push({
                        ...currentDayCashup,
                        variance: `$ ${varianceToday}`,
                    });
                } else {
                    filledOutItems.push({
                        beverage: "$ 0",
                        food: "$ 0",
                        accommodation: "$ 0",
                        turnover: "$ 0",
                        net_profit: "$ 0",
                        other: "$ 0",
                        total: "$ 0",
                        key: dayjs(d).format(dayjsFormat.default.format),
                        date: dayjs(d).format(dayjsFormat.default.format),
                        isEmpty: true,
                        variance: `$ ${varianceToday}`,
                    });
                }
            }
            return filledOutItems;
        }
        return aggregatedCashups
            .map((cashup) => ({ ...cashup, variance: `$ 0` }))
            .sort((a, b) => {
                return new Date(a.date).getTime() > new Date(b.date).getTime()
                    ? 1
                    : -1;
            });
    }, [aggregatedCashups, parsedRange]);

    const totalRow = useMemo(() => {
        const total = dataSource.reduce<dataForTable>(
            (acc, data) => {
                let {
                    beverage,
                    food,
                    accommodation,
                    turnover,
                    net_profit,
                    other,
                    total,
                    variance,
                } = acc;
                beverage = `$ ${roundToTwoDecimal(
                    Number(beverage.slice(2)) + Number(data.beverage.slice(2))
                )}`;
                food = `$ ${roundToTwoDecimal(
                    Number(food.slice(2)) + Number(data.food.slice(2))
                )}`;
                accommodation = `$ ${roundToTwoDecimal(
                    Number(accommodation.slice(2)) +
                        Number(data.accommodation.slice(2))
                )}`;
                turnover = `$ ${roundToTwoDecimal(
                    Number(turnover.slice(2)) + Number(data.turnover.slice(2))
                )}`;
                net_profit = `$ ${roundToTwoDecimal(
                    Number(net_profit.slice(2)) + Number(data.net_profit.slice(2))
                )}`;
                other = `$ ${roundToTwoDecimal(
                    Number(other.slice(2)) + Number(data.other.slice(2))
                )}`;
                total = `$ ${roundToTwoDecimal(
                    Number(total.slice(2)) + Number(data.total.slice(2))
                )}`;
                variance = `$ ${roundToTwoDecimal(
                    Number(variance.slice(2)) + Number(data.variance.slice(2))
                )}`;

                return {
                    ...acc,
                    beverage,
                    food,
                    accommodation,
                    turnover,
                    net_profit,
                    other,
                    total,
                    variance,
                };
            },
            {
                beverage: "$ 0",
                food: "$ 0",
                accommodation: "$ 0",
                turnover: "$ 0",
                net_profit: "$ 0",
                other: "$ 0",
                total: "$ 0",
                key: dayjs().format(dayjsFormat.default.format),
                date: dayjs().format(dayjsFormat.default.format),
                isEmpty: true,
                variance: `$ 0`,
            }
        );
        Object.assign(total, { date: "Total" });
        return total;
    }, [dataSource]);

    const dataForCsv: { [name: string]: string }[] = useMemo(() => {
        const result: { [name: string]: string }[] = [];
        const columnsForDownload = [...initialColumns];
        columnsForDownload.unshift("date");
        dataSource.map((data) => {
            const item: { [name: string]: string } = {};
            for (const key of columnsForDownload) {
                item[key] = data[key];
            }
            result.push(item);
        });
        return result;
    }, [dataSource, initialColumns]);

    const venueIndex = useMemo(
        () =>
            groupData?.venues?.find(({ venue_id }) => venue_id === selectedVenueId),
        [groupData]
    );
    const convertToCsv = useCallback(() => {
        let result = initialColumnsTable.join(",") + "\r\n";
        dataForCsv.forEach((item) => {
            const values = Object.values(item);
            const date = values.shift();
            result += date + ",";
            const valuesWihtoutDollar = values.map((value) => value.slice(2));
            result += valuesWihtoutDollar.join(",") + "\r\n";
        });
        return result;
    }, [initialColumnsTable, dataForCsv]);

    const convertToPdf = () => {
        const report = new JsPDF({
            orientation: "landscape",
            unit: "pt",
            format: "a4",
        });
        const dataInPdf = dataForCsv.reduce<string[][]>((result, current, index) => {
            result[index] = [];
            Object.values(current).forEach((value) => result[index].push(value));
            return result;
        }, []);
        autoTable(report, {
            head: [initialColumnsTable],
            body: dataInPdf,
            startY: false,
            headStyles: {
                fillColor: "fafafa",
                textColor: [0, 0, 0],
                cellPadding: 8,
                halign: "left",
            },
            bodyStyles: {
                halign: "left",
            },
            styles: {
                overflow: "linebreak",
                fontSize: 10,
                cellPadding: 8,
            },
            didParseCell: function (data) {
                if (data.row.index % 2 === 1) {
                    data.cell.styles.fillColor = [229, 235, 242];
                }
            },
        });
        report.save(
            `Report-${formatDateStringForDisplay(
                selectedRange!.start,
                dayjsFormat.dayMonthYearSeparateByDash
            )}-${formatDateStringForDisplay(
                selectedRange!.end,
                dayjsFormat.dayMonthYearSeparateByDash
            )}-${venueIndex ? venueIndex.name : undefined}.pdf`
        );
    };

    const generatePdfWithCanvas = async () => {
        const exportElement = document.querySelector("#report") as HTMLElement;
        const canvas = await html2canvas(exportElement!);
        const image = canvas.toDataURL("image/png", 1.0);
        const report = new JsPDF({
            orientation: "landscape",
            unit: "pt",
            format: "a4",
        });
        const imgProps = report.getImageProperties(image);
        const pdfWidth = report.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * (pdfWidth - 40)) / imgProps.width;
        report.addImage(image, "PNG", 20, 20, pdfWidth - 40, pdfHeight - 40);
        const onePageCoorindate = report.internal.pageSize.getHeight();
        for (
            let i = 1;
            i <= Math.ceil(pdfHeight / (onePageCoorindate - 20)) - 1;
            i++
        ) {
            report.addPage();
            report.addImage(
                image,
                "PNG",
                20,
                -((onePageCoorindate - 20) * i),
                pdfWidth - 40,
                pdfHeight - 40
            );
        }
        report.save(
            `Report-${formatDateStringForDisplay(
                selectedRange!.start,
                dayjsFormat.dayMonthYearSeparateByDash
            )}-${formatDateStringForDisplay(
                selectedRange!.end,
                dayjsFormat.dayMonthYearSeparateByDash
            )}-${venueIndex ? venueIndex.name : undefined}.pdf`
        );
    };

    return (
        <Card>
            <div id="report">
                <Col>
                    <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            style={{ marginBottom: 4 }}
                            onClick={() => {
                                setOpenModal(true);
                            }}
                        >
                            Download
                        </Button>
                    </Row>
                    <Table
                        className="ant-table-column-sort"
                        loading={isLoading}
                        style={{ width: "100%" }}
                        columns={columns as ColumnsType<TableRow>}
                        dataSource={[...dataSource, totalRow]}
                        rowClassName={(_, index) =>
                            index % 2 === 0
                                ? "pointer table-row-light"
                                : "pointer table-row-dark"
                        }
                        bordered={isSmall ? true : false}
                        size={isSmall ? "small" : "large"}
                        scroll={{ x: 792 }}
                    />
                </Col>
            </div>
            <StyledModal
                open={openModal}
                footer={null}
                destroyOnClose
                keyboard={false}
                title={
                    <Typography.Title
                        style={{ marginBottom: 0, padding: 24 }}
                        level={4}
                    >
                        Choose document format
                    </Typography.Title>
                }
                bodyStyle={{ width: "fit-content" }}
                onCancel={() => setOpenModal(false)}
            >
                <Row style={{ paddingLeft: "24px", marginBottom: "24px" }}>
                    <Button
                        type="default"
                        onClick={() => {
                            setOpenModal(false);
                            downloadCSV(
                                convertToCsv(),
                                `Report-${formatDateStringForDisplay(
                                    selectedRange!.start,
                                    dayjsFormat.dayMonthYearSeparateByDash
                                )}-${formatDateStringForDisplay(
                                    selectedRange!.end,
                                    dayjsFormat.dayMonthYearSeparateByDash
                                )}-${venueIndex ? venueIndex.name : undefined}.csv`
                            );
                        }}
                        style={{ marginRight: 8 }}
                    >
                        Csv version
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            setOpenModal(false);
                            convertToPdf();
                            // generatePdfWithCanvas();
                        }}
                    >
                        Pdf version
                    </Button>
                </Row>
            </StyledModal>
        </Card>
    );
};

export const CashupReportTable = CashupReportTableHOC(CashupReportTableComponent);
