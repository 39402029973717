import React, { ReactElement } from "react";
import { Result, Space, Typography } from "antd";

import { RoutePaths } from "../../Router/RoutesEnum";

export default function PageNotFound(): ReactElement {
    const Title = () => (
        <>
            <Space direction={"vertical"}>
                <Typography.Text type={"secondary"}>
                    {"This page doesn't exist."}
                </Typography.Text>
                <Typography.Link href={RoutePaths.HOME}>
                    {"Click here to back to the home screen."}
                </Typography.Link>
            </Space>
        </>
    );
    return <Result status="404" title="404" subTitle={<Title />} />;
}
