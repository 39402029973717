import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Result } from "antd";
import { DraggableModal } from "Components/DraggableModal";
import { resetCache } from "Redux/logoutHelper";
import { RoutePaths } from "Router/RoutesEnum";

// TokenErrorModal component to handle token expiration and display an appropriate modal
export const TokenErrorModal: React.FC = () => {
    // State to control the visibility of the modal
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();

    // Function to handle going back to the login page
    const handleGoBack = () => {
        history.push(RoutePaths.LOGIN);
        setIsVisible(false);
        resetCache();
    };

    // Function to handle token expiration events
    const handleTokenExpiration = () => {
        resetCache();
        if (location.pathname !== RoutePaths.LOGIN) {
            setIsVisible(true);
        }
    };

    // Add an event listener for token expiration
    useEffect(() => {
        window.addEventListener("token-expire", handleTokenExpiration);
        // Clean up the event listener
        return () =>
            window.removeEventListener("token-expire", handleTokenExpiration);
    }, []);

    // Update modal visibility based on location changes
    useEffect(() => {
        if (location.pathname === RoutePaths.LOGIN) setIsVisible(false);
    }, [location]);

    return (
        <DraggableModal
            title=""
            open={isVisible}
            onCancel={() => setIsVisible(false)}
            onOk={() => setIsVisible(false)}
            destroyOnClose
            style={{ top: 30, borderRadius: "12px" }}
            bodyStyle={{ padding: "0px 24px 20px 24px" }}
            zIndex={100000}
            cancelButtonProps={{ style: { display: "none" } }}
            footer={null}
            noBottomBorder
            width={"80%"}
        >
            <Result
                title="Session expired, please log in again."
                extra={
                    <Button type="primary" key="console" onClick={handleGoBack}>
                        Go back to Login
                    </Button>
                }
            />
        </DraggableModal>
    );
};
