import React, { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { DefaultLayout } from "../../../Components/Layout";
import { Button, Empty, Layout, message, Result, Tabs } from "antd";
import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import {
    ExtendedVenueItem,
    VenuesCashupsResponse,
    useLazyGetCashupsForVenueQuery,
    useGetVenuesQuery,
} from "../../../Redux/StateSlices/GroupData/VenuesAPI";
import { POSTab } from "../CashupTabs/POS/POSTab";
import { CentredSpinner } from "../../../Components/Misc/Loading/CentredSpinner";
import { ExtendedClassItem } from "../../../Redux/StateSlices/GroupData/ClassesAPI";
import { QuantacoLoader } from "../../../Components/QuantacoLoader/QuantacoLoader";
import { ExtendedAccountItem } from "../../../Redux/StateSlices/GroupData/AccountsAPI";
import { GamingTab } from "../CashupTabs/Gaming/GamingTab";
import { ATMTab } from "../CashupTabs/ATM/ATMTab";
import { Channel } from "pusher-js";
import { CollaborationTableTopBanner } from "./Components/TopBanner";
import { handleBalancesEvent } from "./utils";
import { usePusher } from "hooks/usePusher";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { OfficeTab } from "../CashupTabs/Office/OfficeTab";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useGetGroupDataQuery } from "Redux/StateSlices/GroupDataAPI";
import { GroupSettings } from "Redux/StateSlices/GroupData";
import { useMutation } from "react-query";
import { fetchWrapper } from "API";
import { CashupDailyReportContainer } from "Pages/CashupReport/CashupDailyReportContainer";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "Router/RoutesEnum";
import { CRTTab } from "../CashupTabs/CRT/CRRTTab";
import { useReturnWidget } from "utils/widget";
import { useGetCurrentUser } from "@hooks/auth/getCurrentUser";
import { ExtendedUserType } from "../../../@types/reduxStore/index";
import { dayjsFormat } from "utils/date-utils";
import { downloadCSV } from "utils/dom-utils";
import { fetchUserToken } from "utils/utilities";
import { BASE_URL } from "@Constants";
import { getAuthTokens } from "utils/auth0/auth0API";
import { SafeTab } from "../CashupTabs/SAFE/SafeTab";
import styled from "styled-components";
import { WageringTab } from "../CashupTabs/Wagering/WageringTab";
import dayjs from "dayjs";
import { useIsMobile } from "./useIsMobile";
import { PosTotal } from "../CashupTabs/POS/@types";
import { CCUTab } from "../CashupTabs/CCU/CCUTab";
import { useCubejs } from "utils/cubejs-utils";

//hotfix/deployment-failure
const { Content } = Layout;
const { TabPane } = Tabs;

const StyledButton = styled(Button)`
    @media (max-width: 768px) {
        display: none;
    }
`;

type TabOptions =
    | "POS"
    | "Gaming"
    | "TAB"
    | "Keno"
    | "ATM"
    | "Office"
    | "Shift report"
    | "Safe"
    | "CRT"
    | "Wagering"
    | "CCU";

const TabRoutes: { [x: string]: string } = {
    POS: RoutePaths.POS,
    Gaming: RoutePaths.Gaming,
    TAB: RoutePaths.TAB,
    Keno: RoutePaths.Keno,
    Wagering: RoutePaths.Wagering,
    ATM: RoutePaths.ATM,
    Office: RoutePaths.Office,
    "Shift report": RoutePaths.DailyShiftReport,
    CRT: RoutePaths.CRT,
    CCU: RoutePaths.CCU,
    Safe: RoutePaths.Safe,
};

export const useGtag = ({
    groupId,
    groupName,
}: {
    groupId?: string;
    groupName?: string;
}) => {
    const [auth0UserId, setAuth0UserId] = useState<undefined | string>(undefined);
    const [auth0User, setAuth0User] = useState<undefined | string>(undefined);
    const getTokens = async () => {
        const data = await getAuthTokens();
        setAuth0UserId(data?.user_id);
        setAuth0User(data?.email);
    };
    useEffect(() => {
        getTokens();
        if (auth0UserId && groupId && groupName) {
            //@ts-ginore
            if (window?.gtag) {
                //@ts-ignore
                window?.gtag?.("set", "user_properties", {
                    firebase_user_id: auth0UserId,
                    group_id: groupId,
                    group_name: groupName,
                    userEmail: auth0User,
                });
            }
        }
    }, [auth0UserId, auth0User, groupId, groupName]);
};

const refreshPosScreen = async ({
    venueId,
    selectedDate,
}: {
    venueId: string;
    selectedDate: string;
}) => {
    return await fetchWrapper(
        `/v1/pos/orders?cashup_date=${selectedDate}&venue_id=${venueId}`,
        "POST"
    );
};

const CashupTablesComponent = ({
    venuesData,
    locationsData,
    hierarchicalLocations,
    groupSettings,
    currentTab,
    currentUser,
    isMobile,
}: {
    venuesData: ExtendedVenueItem[];
    locationsData: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    groupSettings: GroupSettings;
    currentTab: TabOptions;
    currentUser: ExtendedUserType;
    isMobile: boolean;
}) => {
    useReturnWidget();

    const history = useHistory();
    const [selectedVenue, setSelectedVenue] = useLocalStorage<string | null>(
        "selectedVenue",
        null
    );

    const [selectedDate, setSelectedDate] = useLocalStorage<string | null>(
        "selectedDate",
        null
    );

    const [topBannerValues, setTopBannerValues] = useState<
        | {
              total_sales: number;
              cash_increase: number;
              bankable: number;
          }
        | undefined
    >();
    const [posTotalRow, setPosTotalRow] = useState<PosTotal | undefined>(undefined);
    const updatePosTotal = (data: PosTotal) => {
        setPosTotalRow(data);
    };

    const currentVenueData = useMemo(() => {
        return selectedVenue
            ? venuesData.find(({ venue_id }) => venue_id === selectedVenue)
            : undefined;
    }, [venuesData, selectedVenue]);

    const venueName = useMemo(() => currentVenueData?.name, [currentVenueData]);

    const [fetchVenueCashups, venuesCashupData] = useLazyGetCashupsForVenueQuery();
    const { pusher } = usePusher();
    const topBannerSubscriptionRef = useRef<Channel>();

    useEffect(() => {
        document.title = `Cashup - ${currentTab}`;
    }, [currentTab]);

    useEffect(() => {
        // Fired on component dismount
        return () => {
            topBannerSubscriptionRef.current?.unbind_all();
            topBannerSubscriptionRef.current?.unsubscribe();
        };
    }, [pusher]);

    useEffect(() => {
        if (selectedDate && selectedVenue) {
            fetchVenueCashups({
                venue_id: selectedVenue,
                cashup_date: selectedDate,
            });
        }
    }, [fetchVenueCashups, selectedDate, selectedVenue]);

    useEffect(() => {
        if (!pusher) return;
        if (venuesCashupData.isSuccess) {
            if (pusher.channels.all().length === 0) {
                topBannerSubscriptionRef.current = pusher.subscribe(
                    venuesCashupData.data.channel_id
                );
                topBannerSubscriptionRef.current.bind("balances", (data: any) =>
                    handleBalancesEvent(data, setTopBannerValues)
                );
                topBannerSubscriptionRef.current.bind(
                    "pos_totals",
                    (data: PosTotal) => updatePosTotal(data)
                );
            } else {
                // If we arrive here that means there is a pre-existing subscription and data that needs to be reset.
                setTopBannerValues(undefined);
                setPosTotalRow(undefined);
                topBannerSubscriptionRef.current?.unbind_all();
                // Note: There should only be one subscription at a time for this screen/component.
                pusher.allChannels().forEach((channel) => channel.unsubscribe());

                topBannerSubscriptionRef.current = pusher.subscribe(
                    venuesCashupData.data.channel_id
                );
                topBannerSubscriptionRef.current.bind("balances", (data: any) =>
                    handleBalancesEvent(data, setTopBannerValues)
                );
                topBannerSubscriptionRef.current.bind(
                    "pos_totals",
                    (data: PosTotal) => updatePosTotal(data)
                );
            }
        }
    }, [pusher, venuesCashupData.data, venuesCashupData.isSuccess]);

    function onDatePickerChange(date: any, dateString: any) {
        setSelectedDate(dateString);
    }

    const { mutate, isLoading: isMutationLoading } = useMutation(refreshPosScreen);
    const { mutate: fetchCSV, isLoading: isFetchJournalLoading } = useMutation(
        async () => {
            const ID_TOKEN = await fetchUserToken();
            if (ID_TOKEN === undefined) {
                throw Error("Auth token not available");
            }

            const currentDay = dayjs(selectedDate, "DD-MM-YYYY").format(
                dayjsFormat.default.format
            );
            const nextDay = dayjs(selectedDate, "DD-MM-YYYY")
                .add(1, "day")
                .format(dayjsFormat.default.format);

            return fetch(
                `${BASE_URL}/netsuite/journal?from_date=${currentDay}&to_date=${nextDay}&venue_id=${selectedVenue}&content_type=csv`,
                { headers: { Authorization: `Bearer ${ID_TOKEN}` } }
            );
        },
        {
            onSuccess: async (response) => {
                const parsedText = await response.text();
                if (parsedText.length > 1) {
                    downloadCSV(
                        parsedText,
                        `Journal-${selectedDate}-${venueName}.csv`
                    );
                } else {
                    message.error("No Journal for this selected Date & Venue");
                }
            },
        }
    );

    const commonTabProps = useMemo(() => {
        if (
            venuesCashupData.data == undefined ||
            venuesCashupData.isFetching ||
            currentVenueData == undefined
        ) {
            return null;
        }

        return {
            cashups: venuesCashupData.data!,
            selectedDate: selectedDate!,
            classesData: currentVenueData.classes,
            accountsData: currentVenueData.accounts,
            locations: locationsData,
            hierarchicalLocations,
            venuesData,
            posTotal: posTotalRow,
        };
    }, [
        hierarchicalLocations,
        locationsData,
        selectedDate,
        venuesCashupData.data,
        venuesCashupData.isFetching,
        venuesData,
        posTotalRow,
        currentVenueData,
    ]);

    const onboardingDate = useMemo(() => {
        if (!selectedVenue) {
            return undefined;
        } else {
            const currentVenue = venuesData.find(
                (venueItem) => venueItem.venue_id === selectedVenue
            );
            return currentVenue ? currentVenue.onboarding_date : undefined;
        }
    }, [venuesData, selectedVenue]);
    const onTabChange = (newTab: TabOptions | string) => {
        history.push(TabRoutes[newTab]);
    };

    return (
        <>
            <CollaborationTableTopBanner
                setSelectedVenue={setSelectedVenue}
                venuesData={venuesData}
                onDatePickerChange={onDatePickerChange}
                VenuesCashupResponse={venuesCashupData.data}
                SubscriptionResponse={topBannerValues}
                selectedVenue={selectedVenue}
                initialDateString={selectedDate!}
                onboardingDate={onboardingDate}
            />

            <CashupMainView
                isMobile={isMobile}
                selectedDate={selectedDate!}
                selectedVenue={selectedVenue}
                currentTab={currentTab}
                groupSettings={groupSettings}
                mutate={mutate}
                isMutationLoading={isMutationLoading}
                currentUser={currentUser}
                fetchCSV={fetchCSV}
                isFetchJournalLoading={isFetchJournalLoading}
                onTabChange={onTabChange}
                commonTabProps={commonTabProps}
            />

            {venuesCashupData.isLoading ||
                (venuesCashupData.isFetching && <CentredSpinner />)}
            {venuesCashupData.data === undefined &&
                venuesCashupData.isLoading === false &&
                venuesCashupData.isFetching === false &&
                venuesCashupData.isError !== true && (
                    <Empty description="Please select a venue." />
                )}
            {venuesCashupData.isError && (
                <Result status="error" title="Error fetching locations" />
            )}
        </>
    );
};

const CashupTables = React.memo(CashupTablesComponent);

export const DataLayerWrapper = ({ currentTab }: { currentTab: TabOptions }) => {
    const { data, isLoading, status } = useGetGroupDataQuery(null);
    const {
        user: currentUser,
        isLoading: isUserLoading,
        isError: isGetUserError,
    } = useGetCurrentUser();

    useGtag({
        groupId: data?.group.group_id,
        groupName: data?.group.client_group_name,
    });
    const { updateCubeJsToken } = useCubejs();

    React.useEffect(() => {
        if (data?.cubejs_token) {
            updateCubeJsToken(data.cubejs_token);
        }
    }, [data, updateCubeJsToken]);
    const isMobile = useIsMobile();

    const parsedCurrentTab = useMemo(() => {
        if (isMobile) return "Shift report";
        return currentTab;
    }, [currentTab, isMobile]);

    if (isGetUserError) {
        // TODO: Added this error to Bugsnag / Sentry
        return (
            <Result status="error" title="Error occur fetching current user data" />
        );
    }

    if (status === "rejected") {
        // TODO: Added this error to Bugsnag / Sentry
        return <Result status="error" title="An error occured setting up Cashup" />;
    }

    if (isLoading || isUserLoading) {
        return <QuantacoLoader size={100} />;
    }

    if (data === undefined)
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                }}
            >
                <h1>Invalid group data</h1>
            </div>
        );

    return (
        <CashupTables
            venuesData={data.venues}
            locationsData={data.locations}
            hierarchicalLocations={data.locations_hierarchy}
            groupSettings={data.group.settings}
            currentTab={parsedCurrentTab}
            currentUser={currentUser!}
            isMobile={isMobile}
        />
    );
};

const PosTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="POS" />}
            skipLayoutWrapper
        />
    );
};

const GamingTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="Gaming" />}
            skipLayoutWrapper
        />
    );
};

const WageringTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="Wagering" />}
            skipLayoutWrapper
        />
    );
};

const ATMTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="ATM" />}
            skipLayoutWrapper
        />
    );
};

const OfficeTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="Office" />}
            skipLayoutWrapper
        />
    );
};

const DailyShiftReport = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="Shift report" />}
            skipLayoutWrapper
        />
    );
};

const CRTTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="CRT" />}
            skipLayoutWrapper
        />
    );
};

const SafeTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="Safe" />}
            skipLayoutWrapper
        />
    );
};
const CCUTable = () => {
    return (
        <DefaultLayout
            BodyComponent={() => <DataLayerWrapper currentTab="CCU" />}
            skipLayoutWrapper
        />
    );
};
export {
    PosTable,
    GamingTable,
    WageringTable,
    OfficeTable,
    ATMTable,
    DailyShiftReport,
    CRTTable,
    SafeTable,
    CCUTable,
};
const CashupDesktopView = ({
    currentTab,
    groupSettings,
    selectedDate,
    selectedVenue,
    mutate,
    isMutationLoading,
    currentUser,
    fetchCSV,
    isFetchJournalLoading,
    onTabChange,
    commonTabProps,
}: {
    currentTab: string;
    groupSettings: GroupSettings;
    selectedDate: string | undefined;
    selectedVenue: any;
    mutate: ({
        venueId,
        selectedDate,
    }: {
        venueId: string;
        selectedDate: string;
    }) => void;
    isMutationLoading: boolean;
    currentUser: ExtendedUserType;
    fetchCSV: () => void;
    isFetchJournalLoading: boolean;
    onTabChange: (newTab: TabOptions | string) => void;
    commonTabProps: {
        cashups: VenuesCashupsResponse;
        selectedDate: string;
        classesData: ExtendedClassItem[];
        accountsData: ExtendedAccountItem[];
        locations: ExtendedLocationItem[];
        hierarchicalLocations: ExtendedLocationItemWithChildren[];
        venuesData: ExtendedVenueItem[];
        posTotal?: PosTotal;
    } | null;
}) => {
    if (commonTabProps === null) return <div></div>;

    return (
        <Tabs
            activeKey={currentTab}
            destroyInactiveTabPane
            tabBarExtraContent={TabBarContent(
                groupSettings,
                selectedDate,
                selectedVenue,
                mutate,
                isMutationLoading,
                currentUser,
                fetchCSV,
                isFetchJournalLoading
            )}
            onChange={onTabChange}
        >
            <TabPane tab="POS" key="POS">
                <POSTab {...commonTabProps} />
            </TabPane>
            {commonTabProps.cashups.locations.gaming_locations.some(
                (loction) => loction.sub_locations.length > 0
            ) ? (
                <TabPane tab="Gaming" key="Gaming">
                    <GamingTab {...commonTabProps} />
                </TabPane>
            ) : null}

            {commonTabProps.cashups.locations.wagering_locations.some(
                (location) => location.sub_locations.length > 0
            ) ? (
                <TabPane tab="Wagering" key="Wagering">
                    <WageringTab {...commonTabProps} />
                </TabPane>
            ) : null}
            {commonTabProps.cashups.locations.atm_locations.some(
                (location) => location.sub_locations.length > 0
            ) ? (
                <TabPane tab="ATM" key="ATM">
                    <ATMTab {...commonTabProps} />
                </TabPane>
            ) : null}
            {commonTabProps.cashups.locations.ccu_locations.some(
                (loction) => loction.sub_locations.length > 0
            ) ? (
                <TabPane tab="CCU" key="CCU">
                    <CCUTab {...commonTabProps} />
                </TabPane>
            ) : null}
            {commonTabProps.cashups.locations.crt_locations.some(
                (loction) => loction.sub_locations.length > 0
            ) ? (
                <TabPane tab="CRT" key="CRT">
                    <CRTTab {...commonTabProps} />
                </TabPane>
            ) : null}
            <TabPane tab="Office" key="Office">
                <OfficeTab {...commonTabProps} />
            </TabPane>
            <TabPane tab="Safe" key="Safe">
                <SafeTab {...commonTabProps} />
            </TabPane>
            <TabPane tab="Shift report" key="Shift report">
                <CashupDailyReportContainer
                    venueId={selectedVenue!}
                    date={selectedDate!}
                />
            </TabPane>
        </Tabs>
    );
};

const CashupMobileView = ({
    selectedDate,
    selectedVenue,
}: {
    selectedDate: string | undefined;
    selectedVenue: string;
}) => <CashupDailyReportContainer venueId={selectedVenue!} date={selectedDate!} />;

const CashupMainViewComponent = ({
    isMobile,
    selectedDate,
    selectedVenue,
    currentTab,
    groupSettings,
    mutate,
    isMutationLoading,
    currentUser,
    fetchCSV,
    isFetchJournalLoading,
    onTabChange,
    commonTabProps,
}: {
    isMobile: boolean;
    selectedDate: string | undefined;
    selectedVenue: any;
    currentTab: string;
    groupSettings: GroupSettings;
    mutate: ({
        venueId,
        selectedDate,
    }: {
        venueId: string;
        selectedDate: string;
    }) => void;
    isMutationLoading: boolean;
    currentUser: ExtendedUserType;
    fetchCSV: () => void;
    isFetchJournalLoading: boolean;
    onTabChange: (newTab: TabOptions | string) => void;
    commonTabProps: {
        cashups: VenuesCashupsResponse;
        selectedDate: string;
        classesData: ExtendedClassItem[];
        accountsData: ExtendedAccountItem[];
        locations: ExtendedLocationItem[];
        hierarchicalLocations: ExtendedLocationItemWithChildren[];
        venuesData: ExtendedVenueItem[];
        posTotal?: PosTotal;
    } | null;
}) => (
    <Content style={{ padding: "24px" }}>
        {isMobile ? (
            <CashupMobileView
                selectedDate={selectedDate}
                selectedVenue={selectedVenue}
            />
        ) : (
            <CashupDesktopView
                currentTab={currentTab}
                groupSettings={groupSettings}
                selectedDate={selectedDate}
                selectedVenue={selectedVenue}
                mutate={mutate}
                isMutationLoading={isMutationLoading}
                currentUser={currentUser}
                fetchCSV={fetchCSV}
                isFetchJournalLoading={isFetchJournalLoading}
                onTabChange={onTabChange}
                commonTabProps={commonTabProps}
            />
        )}
    </Content>
);

const CashupMainView = React.memo(CashupMainViewComponent);

function TabBarContent(
    groupSettings: GroupSettings,
    selectedDate: string | undefined,
    selectedVenue: any,
    mutate: any,
    isMutationLoading: boolean,
    currentUser: ExtendedUserType,
    fetchJournal: () => void,
    isJournalLoading: boolean
) {
    const { data: venuesData, isLoading } = useGetVenuesQuery(null);

    const child = useMemo(() => {
        if (!venuesData || isLoading) return undefined;
        const venue = venuesData.find((venue) => venue.venue_id === selectedVenue);
        if (!venue) return undefined;
        if (venue.pos_integration_enabled)
            return (
                <StyledButton
                    type="primary"
                    icon={<SyncOutlined />}
                    disabled={selectedDate === null || selectedVenue === null}
                    onClick={() =>
                        mutate({
                            selectedDate: selectedDate!,
                            venueId: selectedVenue,
                        })
                    }
                    loading={isMutationLoading}
                >
                    Update
                </StyledButton>
            );

        return undefined;
    }, [venuesData, selectedVenue, isLoading]);

    const syncJournal = currentUser.settings?.support_user ? (
        <Button
            type="primary"
            icon={<DownloadOutlined />}
            disabled={selectedDate === null || selectedVenue === null}
            onClick={fetchJournal}
            loading={isJournalLoading}
            style={{ marginRight: 8 }}
        >
            Download Journal
        </Button>
    ) : undefined;

    return (
        <div>
            {syncJournal}
            {child}
        </div>
    );
}
