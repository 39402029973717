import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import {
    level1Spacing,
    level2Spacing,
    level3Spacing,
    smallSpacing,
} from "utils/style-utils";

interface Props {
    onSave?: () => void;
    onClose?: () => void;
    primaryButtonText?: string;

    onClear?: () => void;
    hideCancelButton?: boolean;
}

const StyledModalFooter = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: ${level2Spacing}px ${level3Spacing}px;
`;

const StyledSaveButton = styled(Button)`
    border-radius: ${smallSpacing}px;
    font-size: 14px;
    line-height: 18px;

    &,
    &:hover,
    &:active,
    &:focus {
        background: #1a81c7;
        border-color: #1a81c7;
        color: white;
    }
`;
const StyledCancelButton = styled(Button)`
    border-radius: ${smallSpacing}px;
    font-size: 14px;
    line-height: 18px;
    margin-right: ${level1Spacing}px;

    &,
    &:hover,
    &:active,
    &:focus {
        background: #f0f2f5;
        border-color: #f0f2f5;
        color: #626e84;
    }
`;

export const ModalFooter: React.FC<Props> = ({
    onSave,
    onClose,
    onClear,
    hideCancelButton,
    primaryButtonText,
}) => {
    return (
        <StyledModalFooter>
            {hideCancelButton !== true && (
                <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
            )}
            {onClear && (
                <StyledCancelButton onClick={onClear}>Clear</StyledCancelButton>
            )}
            {onSave ? (
                <StyledSaveButton htmlType="submit" onClick={onSave}>
                    {primaryButtonText ?? "Save"}
                </StyledSaveButton>
            ) : (
                <StyledSaveButton htmlType="submit">
                    {primaryButtonText ?? "Save"}
                </StyledSaveButton>
            )}
        </StyledModalFooter>
    );
};
