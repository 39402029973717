import { Row, Card } from "antd";
import React, { useCallback } from "react";
import { StyledSection, SectionTitle, StyledTable } from "./CashupDailyReport";
import { prependDollarSign } from "@Constants";
import { Column } from "@ant-design/charts";
import styled from "styled-components";
import { CHART_COLOUR } from "./GamingSection";
import { useIsMobile } from "Pages/CashupHome/CollaborationTable/useIsMobile";

const Container = styled(Card)`
    min-height: 280px;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    flex: 1;

    @media screen and (max-width: 792px) {
        .ant-card-body {
            padding: 8px !important;
        }
        .ant-card {
            padding: 8px !important;
        }
        margin-bottom: 0px;
    }
`;

const SalesBreakdownComponent = ({
    ClassesArray,
}: {
    ClassesArray: { className: string; amount: number }[];
}) => {
    const isMobile = useIsMobile();

    const fetchRowIndexByClassname = useCallback(
        (title: string) => {
            return ClassesArray.findIndex((item) => item.className === title);
        },
        [ClassesArray]
    );
    if (ClassesArray.length === 0)
        return (
            <StyledSection
                shouldHideOnMobile
                style={{ display: "flex", gap: 12, flexDirection: "column" }}
            >
                <SectionTitle title="Sales Breakdown" />
                <Container>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <SectionTitle title="No Data Available" />
                    </div>
                </Container>
            </StyledSection>
        );

    return (
        <StyledSection
            shouldHideOnMobile
            style={{ display: "flex", gap: 12, flexDirection: "column" }}
        >
            <Row style={{ justifyContent: "center" }}>
                <SectionTitle title="Sales Breakdown" />
            </Row>
            <Container>
                <Column
                    data={ClassesArray}
                    xField="className"
                    yField="amount"
                    style={{ paddingBottom: 20 }}
                    label={{
                        position: "middle",
                        style: {
                            fill: "#FFFFFF",
                            opacity: isMobile ? 0 : 1,
                        },
                        formatter: (text) => {
                            return prependDollarSign(text.amount);
                        },
                    }}
                    yAxis={{
                        label: {
                            formatter: (text) => prependDollarSign(Number(text)),
                        },
                    }}
                    color={(currentColumn) => {
                        const index = fetchRowIndexByClassname(
                            currentColumn.className
                        );
                        return CHART_COLOUR[index];
                    }}
                />
                <StyledTable
                    dataSource={ClassesArray}
                    columns={[
                        {
                            title: "Class name",
                            dataIndex: "className",
                            key: "className",
                        },
                        {
                            title: "Amount",
                            dataIndex: "amount",
                            key: "amount",
                            render: (text) => prependDollarSign(text),
                        },
                    ]}
                    pagination={false}
                />
            </Container>
        </StyledSection>
    );
};

export const SalesBreakdown = React.memo(SalesBreakdownComponent);
