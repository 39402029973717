import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import React, { ReactElement } from "react";
import { statusColorMap, statusColorMapProd } from "../../@Constants";
import { CashupStatus } from "../../@Constants/enum/Cashup";
import { transactionStates } from "../../@types";
import { NavBarOptions } from "../../Components/Layout/NavBarOptions";

/**
 * Conditionally returns text with/without an edit icon.
 *
 * @param text the string to display
 * @param hideIconForStrings array of string to hide the icon from
 * @returns
 */
export const TextWithDeleteIcon = (
    text: string,
    valueToPass: any = null,
    onClick: (input?: any) => void = () => null,
    hideIcon = false
): string | ReactElement => {
    if (hideIcon) {
        return text;
    }
    //if (hideIconForStrings) {
    //    if (hideIconForStrings.indexOf(text.toLocaleLowerCase()) > -1) {
    //        return `${text}`;
    //    }
    //}
    return (
        <div>
            <DeleteOutlined
                style={{ paddingRight: "8px" }}
                onClick={() => onClick(valueToPass)}
            />
            {`${text}`}
        </div>
    );
};

export const TextWithEditAndDeleteIcon = (
    text: string,
    valueToPass: any = null,
    onDeleteClick: (input: any) => void = () => null,
    onEditClick: (input: any, memo: string) => void = () => null,
    hideIcon = false,
    hideEditIcon = false
): string | ReactElement => {
    if (hideIcon) {
        return text;
    }
    //if (hideIconForStrings) {
    //    if (hideIconForStrings.indexOf(text.toLocaleLowerCase()) > -1) {
    //        return `${text}`;
    //    }
    //}
    return (
        <div>
            <DeleteOutlined
                style={{ paddingRight: "4px" }}
                onClick={() => onDeleteClick(valueToPass)}
            />
            {hideEditIcon === false && (
                <EditOutlined
                    style={{ paddingRight: "8px" }}
                    onClick={() => onEditClick(valueToPass, text)}
                />
            )}
            {`${text}`}
        </div>
    );
};

/**
 * Trim strings to a set length (indicate continuation with "...")
 *
 * @param param0
 * @returns
 */
export const EllipsisText = ({
    text,
    length,
    ...other
}: {
    text: string;
    length: number;
}): ReactElement => {
    const textLength = text.length;
    if (textLength <= length || length < 0) {
        return <span {...other}>{text}</span>;
    }

    const displayText = text.slice(0, length);

    return <span {...other}>{`${displayText}...`}</span>;
};

/**
 * Helper function that checks the current location/route against a list of NavOptions routes.
 * If there is a match between the current root route & a nav option the corresponding route is set as the active nav option.
 *
 * Note: NavBarOptions needs to encapsulate every potential route.
 *
 * @param {string} pathname
 * @returns
 */
export const getActiveNavOption = ({
    pathname,
}: {
    pathname: string;
}): string | undefined => {
    const INDEX = NavBarOptions.findIndex(
        (item: { route: string; title: string }) => {
            // For routes such as /transactions/512123z (e.g. Having URL Params.)
            if (pathname.split("/").length > 2) {
                // Gets the main route (e.g. transactions, cashup)
                const PATH_NAME = `/${pathname.split("/")[1]}`;
                // Checks if the nav option is the current pathname.
                return item.route === PATH_NAME ? true : false;
            }
            // For cases where there to strip URL params from the pathname.
            return item.route === pathname ? true : false;
        }
    );

    // INDEX == -1 Means nothing found
    if (INDEX >= 0) {
        return NavBarOptions[INDEX].route;
    }
    return;
};

export const renderAntdTag = (status: transactionStates): ReactElement => {
    const color = statusColorMap[status];

    return <Tag color={color}>{status.toUpperCase()}</Tag>;
};

export const renderCashupAntdTagProd = (status: CashupStatus): ReactElement => {
    const color = statusColorMapProd[status];

    return <Tag color={color}>{CashupStatus[status].toUpperCase()}</Tag>;
};

export function capitaliseFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const RENDER_USER_ROLE = (role_id: boolean | null): ReactElement | null => {
    if (role_id !== null) {
        // return <Tag>{UserRoles[role_id]}</Tag>;
        return role_id ? <CheckOutlined /> : <CloseOutlined />;
    }
    return null;
};
