import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface ShiftReportFields {
    selectedDate?: string;
}

const initialState = {
    selectedDate: dayjs().format("DD-MM-YYYY"),
} as ShiftReportFields;

const collaborationTableSlice = createSlice({
    name: "collaborationTableSlice",
    initialState,
    reducers: {
        changeCollaborationTableDate(
            state,
            payload: PayloadAction<{ newDate: string }>
        ) {
            state.selectedDate = payload.payload.newDate;
        },
    },
});

export const { changeCollaborationTableDate } = collaborationTableSlice.actions;
export default collaborationTableSlice.reducer;
