import React, { useEffect } from "react";
import { WHITE } from "@Constants/theme/constants";
import { DatePicker, Divider, Empty, Select, Statistic } from "antd";
import {
    ExtendedVenueItem,
    VenuesCashupsResponse,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import locale from "antd/es/date-picker/locale/en_GB";
import { dayjsFormat } from "utils/date-utils";
import dayjs from "dayjs";

// moment.locale("en", {
//     week: {
//         dow: 1,
//     },
// });

const TopBannerContent = styled.div`
    display: flex;
    background-color: ${WHITE};
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    align-items: center;
    padding: 8px 28px;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

const StatisticSection = styled.div`
    display: flex;
    justify-content: space-around;
    flex: 1;
    width: 100%;
    @media (max-width: 768px) {
        padding-bottom: 12px;
    }
`;

export const CollaborationTableTopBanner = ({
    setSelectedVenue,
    venuesData,
    onDatePickerChange,
    VenuesCashupResponse,
    SubscriptionResponse,
    selectedVenue,
    initialDateString,
    onboardingDate,
}: {
    setSelectedVenue: React.Dispatch<React.SetStateAction<string | null>>;
    venuesData: ExtendedVenueItem[];
    onDatePickerChange: (date: any, dateString: any) => void;
    VenuesCashupResponse: VenuesCashupsResponse | undefined;
    SubscriptionResponse:
        | {
              total_sales: number;
              cash_increase: number;
              bankable: number;
          }
        | undefined;
    selectedVenue: string | null;
    initialDateString?: string;
    onboardingDate?: string;
}) => {
    useEffect(() => {
        if (selectedVenue === null) {
            setSelectedVenue(venuesData[0].venue_id);
        }
        if (initialDateString === null) {
            onDatePickerChange(
                dayjs().format("DD-MM-YYYY"),
                dayjs().format("DD-MM-YYYY").toString()
            );
        }
    }, [selectedVenue, initialDateString]);

    return (
        <TopBannerContent>
            <div>
                <Select
                    showSearch
                    style={{ width: "250px" }}
                    placeholder="Select a venue"
                    filterOption={(input, option) =>
                        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(result) =>
                        result && setSelectedVenue(result.toString())
                    }
                    notFoundContent={<Empty />}
                    value={selectedVenue ?? undefined}
                >
                    {venuesData?.map((curVenue) => (
                        <Select.Option
                            key={curVenue.venue_id}
                            value={curVenue.venue_id}
                            title={curVenue.name}
                        >
                            {curVenue.name}
                        </Select.Option>
                    ))}
                </Select>
                <Divider
                    type="vertical"
                    style={{ backgroundColor: "rgba(0,0,0,.5)" }}
                />
                <DatePicker
                    onChange={onDatePickerChange}
                    value={
                        initialDateString
                            ? dayjs(initialDateString, "DD-MM-YYYY")
                            : dayjs()
                    }
                    allowClear={false}
                    locale={locale}
                    format={dayjsFormat.dayMonthYearSeparateByDash.format}
                    disabledDate={(currentDate: dayjs.Dayjs) =>
                        onboardingDate
                            ? currentDate.isBefore(dayjs(onboardingDate))
                            : false
                    }
                />
            </div>
            <StatisticSection>
                <Statistic
                    title="Total sales"
                    value={
                        SubscriptionResponse
                            ? SubscriptionResponse.total_sales
                            : VenuesCashupResponse?.balances.total_sales
                    }
                    valueStyle={{ fontSize: "14px" }}
                    style={{ padding: "0px 8px" }}
                    prefix={"$"}
                />
                <Statistic
                    title="Cash increase"
                    value={
                        SubscriptionResponse
                            ? SubscriptionResponse.cash_increase
                            : VenuesCashupResponse?.balances.cash_increase
                    }
                    valueStyle={{ fontSize: "14px" }}
                    style={{ padding: "0px 8px" }}
                    prefix={"$"}
                />
                <Statistic
                    title="Bankable"
                    value={
                        SubscriptionResponse
                            ? SubscriptionResponse.bankable
                            : VenuesCashupResponse?.balances.bankable
                    }
                    valueStyle={{ fontSize: "14px" }}
                    style={{ padding: "0px 8px" }}
                    prefix={"$"}
                />
            </StatisticSection>
        </TopBannerContent>
    );
};
