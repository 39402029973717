import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultLayout } from "Components/Layout";
import { Space } from "StyledComponents/Space";
import { useAppDispatch, useAppSelector } from "Redux/TypedReduxFunctions";
import { dayjsFormat, QTCRange, toQTCRange } from "utils/date-utils";
import { useGetGroupDataQuery } from "Redux/StateSlices/GroupDataAPI";
import { changeVenue } from "Redux/StateSlices/ShiftReportState";
import { Button, Col, Form, message, Row, Select } from "antd";
import styled from "styled-components";
import { SelectValue } from "antd/lib/select";
import { DownloadOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { fetchUserToken } from "utils/utilities";
import { BASE_URL } from "@Constants";
import { downloadCSV } from "utils/dom-utils";
import { useIntializeCashupReport } from "./Hooks/useIntializeCashupReport";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { PermissionType, userManagerCheck } from "Components/UserAvatarDropdown";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "Router/RoutesEnum";
import dayjs, { Dayjs } from "dayjs";
import { LimitedRangePicker } from "Components/RangePicker/LimitedRangePicker";

const { Option } = Select;

export const AuditReportPage = () => {
    const { ready } = useIntializeCashupReport();
    const router = useHistory();

    useEffect(() => {
        document.title = "Cashup - Summary Report";
    }, []);
    useEffect(() => {
        if (!userManagerCheck(PermissionType.AuditReportDownload)) {
            router.push(RoutePaths.HOME);
        }
    });
    if (!ready) {
        return <QuantacoLoader size={100} />;
    }
    return (
        <DefaultLayout>
            <Space $fluid size="large" direction="vertical">
                <AuditReportComponent />
            </Space>
        </DefaultLayout>
    );
};

const AuditReportComponent = () => {
    const { selectedVenueId } = useAppSelector((state) => state.shiftReportSlice);
    const [selectedRange, setSelectedRange] = useState<
        { start: Dayjs; end: Dayjs } | undefined
    >({ start: dayjs().startOf("week"), end: dayjs().endOf("week") });

    const dispatch = useAppDispatch();

    const { data: groupData } = useGetGroupDataQuery(null);

    const onSelectedRangeChanged = useCallback(
        (newRange?: QTCRange) =>
            newRange && newRange.start && newRange.end
                ? setSelectedRange({ start: newRange.start, end: newRange.end })
                : setSelectedRange(undefined),
        []
    );

    if (!groupData) {
        throw new Error(
            "No group data in CahsupReportSelector, this should be impossible !!"
        );
    }

    const venueIndex = useMemo(
        () =>
            groupData?.venues?.find(({ venue_id }) => venue_id === selectedVenueId),
        [groupData]
    );
    const { mutate: fetchAuditReport, isLoading: isFetchAuditReportLoading } =
        useMutation(
            async () => {
                const ID_TOKEN = await fetchUserToken();
                if (ID_TOKEN === undefined) {
                    throw Error("Auth token not available");
                }
                return fetch(
                    `${BASE_URL}/api/v1/cashup/history?venue_id=${selectedVenueId}&from_date=${selectedRange?.start.toISOString()}&to_date=${selectedRange?.end.toISOString()}`,
                    { headers: { Authorization: `Bearer ${ID_TOKEN}` } }
                );
            },
            {
                onSuccess: async (response) => {
                    const parsedText = await response.text();
                    if (parsedText.length > 1) {
                        downloadCSV(
                            parsedText,
                            `Audit-report-from-${selectedRange?.start.format(
                                dayjsFormat.dayMonthYearSlashHourMinute.format
                            )}-to-${selectedRange?.end.format(
                                dayjsFormat.dayMonthYearSlashHourMinute.format
                            )}-${venueIndex?.name}.csv`
                        );
                    } else {
                        message.error(
                            "No Audit report for this selected Date & Venue"
                        );
                    }
                },
            }
        );

    const venueOptions =
        groupData?.venues?.map(({ venue_id: id, name }) => ({ id, name })) ?? [];

    return (
        <InputSelector>
            <Form.Item label="Report venue">
                <Select
                    size="middle"
                    style={{ width: 200, marginRight: 12, marginBottom: 6 }}
                    placeholder="Venue"
                    onChange={(value: SelectValue) => {
                        value &&
                            dispatch(
                                changeVenue({
                                    newVenue: value.toString(),
                                })
                            );
                    }}
                    value={selectedVenueId}
                >
                    {venueOptions.map(({ id, name }) => (
                        <Option key={id} value={id}>
                            {name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="Report date range">
                <LimitedRangePicker
                    onChange={onSelectedRangeChanged}
                    defaultRange={selectedRange}
                />
            </Form.Item>

            <Button
                type="primary"
                icon={<DownloadOutlined />}
                disabled={!selectedRange || !selectedVenueId}
                onClick={() => fetchAuditReport()}
                loading={isFetchAuditReportLoading}
                style={{ marginRight: 8 }}
            >
                Download
            </Button>
        </InputSelector>
    );
};
const InputSelector = styled(Col)`
    margin-right: auto;
`;
