import React, { ComponentType, useState } from "react";

export const CashupReportTableHOC = (Component: ComponentType<any>) => {
    const WrappedComponent = (props: any) => {
        const [openModal, setOpenModal] = useState<boolean>(false);
        return (
            <Component
                {...props}
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
        );
    };
    return WrappedComponent;
};
