import { NavbarOptions } from "../../@types";
import { RoutePaths } from "../../Router/RoutesEnum";

export const NavBarOptions: NavbarOptions[] = [
    {
        route: RoutePaths.HOME,
        title: "Cashups",
        type: "item",
    },
    {
        route: RoutePaths.CASHUP_REPORT_SUMMARY,
        title: "Cashup Reports",
        type: "item",
    },
];
