import React from "react";
import { StyledAntDInput } from "Components/MoneyInput/MoneyInputV2";
import { FieldWithOutType } from "../../TransactionForms/SalesCountModalFormV2";

const formatValue = (value: string): number => {
    const containsDecimal = value.indexOf(".");
    const parsedValue = containsDecimal
        ? parseFloat(`${value.replaceAll(",", "")}`)
        : Number(value.replaceAll(",", ""));
    return parsedValue;
};

const isNegativeSign = (value: string): boolean => value === "-";

const isZeroDashInput = (value: string): boolean => value === "0-";

const handleOnChange = (
    value: string,
    onChange?: (value: number | string) => void
): void => {
    if (!value) {
        onChange?.(0);
        return;
    }

    if (isNegativeSign(value)) {
        onChange?.(value);
        return;
    }

    if (isZeroDashInput(value)) {
        onChange?.("");
        return;
    }

    const parsedValue = formatValue(value);
    if (!Number.isNaN(parsedValue)) {
        onChange?.(value.replaceAll(",", "") ?? 0);
    } else {
        onChange?.(0);
    }
};

interface MoneyFormFieldProps extends Omit<FieldWithOutType, "type"> {
    disabled?: boolean;
    onChange?: (value: number | string) => void;
}

export const MoneyFormField: React.FC<MoneyFormFieldProps> = ({
    disabled,
    onChange,
    ...rest
}) => {
    return (
        <StyledAntDInput
            className="numberInputField"
            {...rest}
            disabled={disabled ?? false}
            onChange={({ currentTarget: { value } }) => {
                if (!value) {
                    onChange?.(0);
                }
                handleOnChange(value, onChange);
            }}
            onFocus={(e) => {
                e.target.select();
            }}
            // This is to prevent a weird bug where if the initial value is a decimal (e.g. 50.25) you cannot change the decimal value only whole numbers with the same decimal value appended are accepted.
            step="any"
            type="text"
            pattern="^(-?[0-9]+,?[0-9]*([.][0-9]+)?)$"
            prefix="$"
        />
    );
};
