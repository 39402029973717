import { Button, Modal, Space } from "antd";
import React, { useCallback, useMemo } from "react";
import { CollapsibleTable } from "../POS/utils/Panel";
import { CashupTabHOC, TabHOCComponentProps } from "../POS/utils/PanelHOC";
import { FloatsPanel } from "./Floats";
import { DenominationsPanel } from "./SafePanel";
import { TotalBudgetsVarianceTable } from "./Total_Budget_Variance";
import styled from "styled-components";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    ExtendedVenueItem,
    VenuesCashupsResponse,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { InitDataResponseType } from "../POS/@types";
import produce from "immer";
import {
    sortCashupIDS,
    sortTableDataByShiftType,
    transformSafeLocationsToSafeLocationDetailByCashup,
} from "utils/utilities";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import { cloneDeep } from "lodash";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { AntDFormState, AntDFormStateWithoutSplit } from "@types";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import TextArea from "antd/lib/input/TextArea";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { TableDataType } from "../POS/POSPanel";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { useGetGroupDataQuery } from "Redux/StateSlices/GroupDataAPI";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";
import { CommentSection } from "./CommentSection";
import { ApprovedBySection, ApproveButtonSection } from "./ApprovedBySection";
import { TransferModalComponent } from "./TransferModalComponent";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import {
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
} from "../POS/utils";

interface ColumnWidthDistribution {
    numberOfItems: number;
    borderRight?: boolean;
    centerText?: boolean;
    evenDistribution?: boolean;
}

export const StyledInput = styled(TextArea)<ColumnWidthDistribution>`
    padding: 4px 12px;
    flex-basis: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );
    width: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );

    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}

    background: #F9FAFB;
    height: 100%;
    color: #353940;
    display: flex;
    align-items: center;
    border-radius: 4px;

    textarea {
        background: #f9fafb;
    }

    &:focus {
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
    }
`;

export const StyledButton = styled(Button)<ColumnWidthDistribution>`
    padding: 4px 12px;
    flex-basis: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );
    width: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );

    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}
`;

export const EmptyDiv = styled.div<ColumnWidthDistribution>`
    font-weight: bold;
    padding: 4px 12px;
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center;
    flex-basis: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );
    width: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );

    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}
`;

export const StyledModal = styled(Modal)`
    .ant-modal-content {
        width: fit-content;
    }
`;

export const StyledButtonFullWidth = styled.button`
    background: #f9fafb;
    border: 1px solid #dadfe7;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 8px;
    width: 100%;

    &:focus {
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
    }
`;

export const StyledModalDiv = styled.div<{ numberOfItems: number }>`
    flex-basis: calc(${({ numberOfItems }) => 100 / numberOfItems}%);
    width: calc(${({ numberOfItems }) => 100 / numberOfItems}%);

    background: #f9fafb;
    border: 1px solid #dadfe7;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;

    width: 100%;

    &:focus {
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
    }
`;

interface Props extends TabHOCComponentProps {
    selectedDate: string;
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    venuesData: ExtendedVenueItem[];
    floatLocationIds: string[];
    safeLocationIds: string[];
    cashups: VenuesCashupsResponse;
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
}

const ExampleSections = ["Denominations", "Floats", "Total, Budget & Variance"];

const SafeTabComponent: React.FC<Props> = ({
    selectedDate,
    setCurrenTableInFocus,
    currentTableInFocus,
    hierarchicalLocations,
    locations,
    venuesData,
    cashups,
    accountsData,
    classesData,
}) => {
    const { data: groupData, isLoading: isGroupDataLoading } =
        useGetGroupDataQuery(null);

    const [selectedVenue, setSelectedVenue] = useLocalStorage<string | null>(
        "selectedVenue",
        null
    );

    const [updateCashup] = usePushCashupMutation();

    const safeLocationDetailByCashupId = useMemo(
        () =>
            cashups.locations.safe_locations[0].sub_locations
                ? transformSafeLocationsToSafeLocationDetailByCashup(
                      cashups.locations.safe_locations[0].sub_locations
                  )
                : {},
        [cashups.locations.safe_locations]
    );

    const floatLocations = useMemo(
        () => cashups.locations.float_locations,
        [cashups.locations.float_locations]
    );

    const safeLocationIds = useMemo(() => {
        return cashups.locations.safe_locations.map(
            (location) => location.cashup_ids
        )[0];
    }, [cashups.locations.safe_locations]);

    const [safeTableData, setSafeTableData] = React.useState<InitDataResponseType[]>(
        []
    );

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            updateCashup(updatedCashup).unwrap(),
        [updateCashup]
    );

    const onRowDataChange = (rowIndex: number, tableData: TableDataType[]) => {
        handleCashupChanged(tableData[rowIndex]);
    };

    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                safeTableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const onPaymentSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormState[], rowIndex: number) =>
            onPaymentTransactionSubmission(
                data,
                rowIndex,
                accountsData,
                safeTableData,
                onRowDataChange
            ),
        300
    );

    const onSignOffCashupCount = (index: number) => {
        const cashup = safeTableData[index];
        const updatedCashup = produce(cashup, (draft) => {
            draft.safe_data.sign_off = true;
        });
        handleCashupChanged(updatedCashup);
    };

    const updateSafeTableData = (cashup: InitDataResponseType) =>
        setSafeTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);

                    draft.sort((cashupA, cashupB) =>
                        sortTableDataByShiftType(
                            cashupA,
                            cashupB,
                            safeLocationDetailByCashupId
                        )
                    );
                }
            });

            return updatedTableData;
        });

    const sortedSafeCashupIDS = useMemo(
        () =>
            cloneDeep(safeLocationIds).sort((cashupA_ID, cashupB_ID) =>
                sortCashupIDS(cashupA_ID, cashupB_ID, safeLocationDetailByCashupId)
            ),
        [safeLocationIds, safeLocationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds: sortedSafeCashupIDS,
        onCashupInitialized: updateSafeTableData,
        onCashupUpdated: updateSafeTableData,
    });

    const syncTableDataStateDebounced = useDebouncedCallback(
        onRowDataChange,
        DEFAULT_DEBOUNCE_TIME
    );

    const onCommentChange = (
        rowIndex: number,
        comment: string,
        fieldName: "denominations_comments" | "floats_comments" | "variance_comments"
    ) => {
        const tableDataDeepCopy = cloneDeep(safeTableData);
        tableDataDeepCopy[rowIndex].safe_data[fieldName] = {
            content: comment,
            mentions: [],
        };

        setSafeTableData(tableDataDeepCopy);

        syncTableDataStateDebounced(rowIndex, tableDataDeepCopy);
    };

    const TotalSum = useMemo(() => {
        return safeTableData.map((tableData) => {
            const { total } = tableData.safe_data;

            return {
                total: total ?? 0,
                shiftType:
                    safeLocationDetailByCashupId[tableData.cashup_id].shiftType,
            };
        });
    }, [safeTableData]);

    const TableLength = useMemo(() => {
        return safeTableData.length;
    }, [safeTableData]);

    const shouldShowLooseChange = useMemo(() => {
        if (selectedVenue === null) return false;
        if (venuesData.length === 0) return false;
        const venue = venuesData.find((venue) => venue.venue_id === selectedVenue);
        if (venue === undefined) return false;
        return venue.use_loose_change;
    }, [venuesData, selectedVenue]);

    const PanelHelper = useCallback(
        (
            name: string,
            panelId: string,
            currentTableInFocus: string | undefined,
            currentSection: string,
            setCurrenTableInFocus?: (tableID?: string) => void
        ): JSX.Element => {
            switch (currentSection) {
                case "Denominations":
                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <DenominationsPanel
                                key={name}
                                name={name}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                                tableData={safeTableData}
                                onTableDataChange={(tableData, index) =>
                                    onRowDataChange(index, tableData)
                                }
                                tableLength={TableLength}
                                safeLocationDetailByCashupId={
                                    safeLocationDetailByCashupId
                                }
                                shouldShowLooseChange={shouldShowLooseChange}
                            />
                            <CommentSection
                                onFocus={() => setCurrenTableInFocus?.()}
                                comments={safeTableData.reduce(
                                    (accumulator, currentCashup) => {
                                        return [
                                            ...accumulator,
                                            currentCashup.safe_data
                                                .denominations_comments
                                                ? {
                                                      ...currentCashup.safe_data
                                                          .denominations_comments,
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                          undefined,
                                                  }
                                                : {
                                                      content: "",
                                                      mentions: [],
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                          undefined,
                                                  },
                                        ];
                                    },
                                    [] as {
                                        content: string;
                                        mentions: string[];
                                    }[]
                                )}
                                onChange={(value, index) =>
                                    onCommentChange(
                                        index,
                                        value,
                                        "denominations_comments"
                                    )
                                }
                                tableLength={TableLength}
                            />
                        </CollapsibleTable>
                    );

                case "Floats":
                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <FloatsPanel
                                key={name}
                                name={name}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                                floatLocations={floatLocations}
                                tableData={safeTableData}
                                onTableDataChange={(tableData, index) =>
                                    onRowDataChange(index, tableData)
                                }
                                tableLength={TableLength}
                                safeLocationDetailByCashupId={
                                    safeLocationDetailByCashupId
                                }
                            />
                            <TransferModalComponent
                                locations={locations}
                                hierarchicalLocations={hierarchicalLocations}
                                venuesData={venuesData}
                                tableData={safeTableData}
                                onSubmit={onTransferTransactionSubmissionDebounce}
                                onOpen={() => setCurrenTableInFocus?.("TRANSFERS")}
                                accountsData={accountsData}
                                classesData={classesData}
                                onPaymentTransactionSubmission={(data, rowIndex) =>
                                    onPaymentSubmissionDebounce(data, rowIndex)
                                }
                                onPaymentTransactionRemove={(data, rowIndex) =>
                                    onPaymentTransactionRemove(
                                        data,
                                        rowIndex,
                                        accountsData,
                                        safeTableData,
                                        onRowDataChange
                                    )
                                }
                            />
                            <CommentSection
                                onFocus={() => setCurrenTableInFocus?.()}
                                comments={safeTableData.reduce(
                                    (accumulator, currentCashup) => {
                                        return [
                                            ...accumulator,
                                            currentCashup.safe_data.floats_comments
                                                ? {
                                                      ...currentCashup.safe_data
                                                          .floats_comments,
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                          undefined,
                                                  }
                                                : {
                                                      content: "",
                                                      mentions: [],
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                          undefined,
                                                  },
                                        ];
                                    },
                                    [] as {
                                        content: string;
                                        mentions: string[];
                                    }[]
                                )}
                                onChange={(value, index) =>
                                    onCommentChange(index, value, "floats_comments")
                                }
                                tableLength={TableLength}
                            />
                        </CollapsibleTable>
                    );

                case "Total, Budget & Variance":
                    return (
                        <CollapsibleTable
                            name={name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <TotalBudgetsVarianceTable
                                key={name}
                                onClickHandler={() =>
                                    setCurrenTableInFocus?.(panelId)
                                }
                                isInFocus={panelId === currentTableInFocus}
                                tableData={safeTableData}
                                totals={TotalSum}
                                tableLength={TableLength}
                                safeLocationDetailByCashupId={
                                    safeLocationDetailByCashupId
                                }
                            />
                            <CommentSection
                                onFocus={() => setCurrenTableInFocus?.()}
                                comments={safeTableData.reduce(
                                    (accumulator, currentCashup) => {
                                        return [
                                            ...accumulator,
                                            currentCashup.safe_data.variance_comments
                                                ? {
                                                      ...currentCashup.safe_data
                                                          .variance_comments,
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                          undefined,
                                                  }
                                                : {
                                                      content: "",
                                                      mentions: [],
                                                      disabled:
                                                          currentCashup.safe_data
                                                              .signed_off_by_user_id !==
                                                          undefined,
                                                  },
                                        ];
                                    },
                                    [] as {
                                        content: string;
                                        mentions: string[];
                                    }[]
                                )}
                                onChange={(value, index) =>
                                    onCommentChange(
                                        index,
                                        value,
                                        "variance_comments"
                                    )
                                }
                                tableLength={TableLength}
                            />
                            <ApprovedBySection
                                tableData={safeTableData}
                                users={groupData?.users ?? []}
                                tableLength={TableLength}
                            />
                            <ApproveButtonSection
                                onApprove={onSignOffCashupCount}
                                tableData={safeTableData}
                                tableLength={TableLength}
                            />
                        </CollapsibleTable>
                    );

                default:
                    return <div></div>;
                    throw new Error(`This isn't supported: [${currentSection}]`);
            }
        },
        [
            currentTableInFocus,
            safeTableData,
            onRowDataChange,
            onCommentChange,
            onSignOffCashupCount,
            onTransferTransactionSubmissionDebounce,
            TotalSum,
            floatLocations,
            locations,
            hierarchicalLocations,
            venuesData,
            shouldShowLooseChange,
        ]
    );

    if (
        isLoading ||
        isGroupDataLoading ||
        safeTableData.length !== sortedSafeCashupIDS.length
    )
        return <QuantacoLoader size={100} />;

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {ExampleSections.map((name, index) => {
                const panelId = `${selectedDate}-${index}`;

                return PanelHelper(
                    name,
                    panelId,
                    currentTableInFocus,
                    name,
                    setCurrenTableInFocus
                );
            })}
        </Space>
    );
};

export const SafeTab = CashupTabHOC(SafeTabComponent);
