import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import styled from "styled-components";

interface Props {
    toolTipContent?: React.ReactElement;
}

export const ReportColumnWithTooltip: React.FC<Props> = ({
    children,
    toolTipContent,
}) => {
    if (!toolTipContent) {
        return <span>{children}</span>;
    }
    return (
        <Tooltip title={toolTipContent} placement="topRight">
            <Container>
                <span>{children}</span>
                <ExclamationCircleOutlined />
            </Container>
        </Tooltip>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 5px;
`;
