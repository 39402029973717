/**
 * Note this component makes the assumption that there will only be a single Gaming CashupID that we subscribe to
 * as the Gaming machines section is hard coded to use only the first / 0 index within the tableData array.
 */

import React, { useCallback, useMemo } from "react";
import {
    ExtendedVenueItem,
    GamingLocation,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { GameData, GamingMachineCount, InitDataResponseType } from "../POS/@types";
import { TableDataType } from "../POS/POSPanel";
import { TransactionsFormGaming } from "../../ExcelTable/TransactionsFormGaming";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { onTransferTransactionSubmission } from "./utils";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import produce from "immer";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import { TableRows } from "Components/Table/TableBody";
import { TableConfigs } from "Components/Table/TableHeader";
import { Table } from "Components/Table/Table";
import set from "lodash/set";
import {
    countTransactions,
    LocationDetailByCashup,
    parseTimePeriod,
    sortCashupIDS,
    sortTableData,
} from "../../../../utils/utilities";
import { formatDollarField } from "@Constants";
import {
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
} from "../POS/utils";
import { PanelHOCComponentProps } from "../POS/utils/PanelHOC";
import { AntDFormStateForGaming, AntDFormStateWithoutSplit } from "@types";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";
import { cloneDeep } from "lodash";
import { StyledCollapsedPanel } from "Components/StyledCollapsedPanel";
import Title from "antd/lib/typography/Title";
import { useLocalStorage } from "@hooks/useLocalStorage";
interface Props extends PanelHOCComponentProps {
    name: string;
    subLocations: GamingLocation[];
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    venuesData: ExtendedVenueItem[];
    currentTableInFocus?: string;
    panelId?: string;
}

const initializeGamingFields = (
    cashup: InitDataResponseType
): InitDataResponseType => {
    const gamingData = cashup.gaming_data;
    return {
        ...cashup,
        gaming_data: {
            jackpot: gamingData?.jackpot ?? 0,
            unclaimed_tickets: gamingData?.unclaimed_tickets ?? 0,
            total_wins: gamingData?.total_wins ?? 0,
            turnover: gamingData?.turnover ?? 0,
            gaming_machine_counts: gamingData.gaming_machine_counts ?? [],
            net_profit: gamingData.net_profit ?? 0,
            cash_box: gamingData.cash_box ?? 0,
            tickets_in: gamingData.tickets_in ?? 0,
        },
    };
};

const setGamingMachineCountValue = (
    gaming_data: GameData,
    gamingCountsNumber: number,
    property: string,
    updatedValue: number
) => {
    const gamingData = { ...gaming_data };
    if (
        !gamingData.gaming_machine_counts ||
        gamingData.gaming_machine_counts?.length === 0
    ) {
        if (!gamingData.gaming_machine_counts) gamingData.gaming_machine_counts = [];
        for (let i = 0; i <= gamingCountsNumber; i++) {
            gamingData.gaming_machine_counts.push({
                cash_box_read: 0,
                cash_count: 0,
                variance: 0,
            });
        }
        const currentgamingCount = Object.keys(
            gamingData.gaming_machine_counts[0]
        ).reduce<{ [name: string]: number }>((acc, curr) => {
            if (curr === property) {
                acc[curr] = updatedValue;
            } else {
                acc[curr] = 0;
            }
            return acc;
        }, {});
        gamingData.gaming_machine_counts.splice(
            gamingCountsNumber,
            1,
            currentgamingCount as GamingMachineCount
        );
    } else {
        const gamingCountsData = [...gamingData.gaming_machine_counts];
        if (!gamingData.gaming_machine_counts[gamingCountsNumber]) {
            for (
                let i = 0;
                i <= gamingCountsNumber - gamingData.gaming_machine_counts.length;
                i++
            ) {
                gamingCountsData.push({
                    cash_box_read: 0,
                    cash_count: 0,
                    variance: 0,
                });
            }
        }
        const gamingCountData = {
            ...gamingCountsData[gamingCountsNumber],
        };
        gamingCountData[property as keyof GamingMachineCount] = updatedValue;
        gamingCountsData.splice(gamingCountsNumber, 1, gamingCountData);
        gamingData.gaming_machine_counts = gamingCountsData;
    }
    return gamingData;
};

const processData = (data: any) => (isNaN(Number(data)) ? 0 : Number(data));

export const GamingPanel: React.FC<Props> = ({
    subLocations,
    accountsData,
    locations,
    hierarchicalLocations,
    venuesData,
    onClickHandler,
    currentTableInFocus,
    panelId,
}) => {
    const [pushTableState] = usePushCashupMutation();
    const [selectedVenue] = useLocalStorage<string | null>("selectedVenue", null);
    const currentVenue = useMemo(
        () => venuesData.find((venue) => venue.venue_id === selectedVenue),
        [selectedVenue, venuesData]
    );
    const locationDetailByCashupId = React.useMemo(() => {
        return subLocations.reduce<LocationDetailByCashup>(
            (result, { cashups, name, location_id }) => {
                cashups.forEach(
                    ({ cashup_id, time_period: timePeriod }) =>
                        (result[cashup_id] = { name, timePeriod, location_id })
                );
                return result;
            },
            {}
        );
    }, [subLocations]);

    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);
    const updateTableData = (cashup: InitDataResponseType) =>
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);
                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });

    const cashupIds = React.useMemo(
        () =>
            subLocations
                .flatMap(({ cashups }) => cashups.map(({ cashup_id }) => cashup_id))
                .sort((cashupA_ID, cashupB_ID) =>
                    sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
                ),
        [subLocations, locationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            pushTableState(updatedCashup).unwrap(),
        [pushTableState]
    );

    /**
     * Method invoked on tableData change to sync with backend
     * @param rowIndex
     * @param currentTableState
     */
    const onRowDataChange = React.useCallback(
        (rowIndex: number, tableData: TableDataType[]) => {
            handleCashupChanged(tableData[rowIndex]);
        },
        [handleCashupChanged]
    );

    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const onPaymentTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateForGaming[], rowIndex: number) =>
            onPaymentTransactionSubmission(
                data,
                rowIndex,
                accountsData,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const dataSources: TableRows[] = useMemo(
        () =>
            tableData.map((cashup, rowIndex) => {
                const {
                    cashup_id,
                    cash_count,
                    gaming_data,
                    status,
                    payment_transactions,
                    transfer_transactions_to,
                    sales_count_transactions,
                    transfer_transactions,
                } = cashup;

                return [
                    {
                        value: locationDetailByCashupId[cashup_id].name,
                        readOnly: true,
                        subValue: parseTimePeriod(
                            locationDetailByCashupId[cashup_id].timePeriod
                        ),
                    },
                    {
                        value: gaming_data?.turnover ?? 0,
                        propertyPath: "gaming_data.turnover",
                    },
                    {
                        value: gaming_data?.total_wins ?? 0,
                        propertyPath: "gaming_data.total_wins",
                    },
                    {
                        value: gaming_data?.jackpot ?? 0,
                        propertyPath: "gaming_data.jackpot",
                    },
                    {
                        value: gaming_data?.net_profit ?? 0,
                        readOnly: true,
                        propertyPath: "gaming_data.net_profit",
                    },
                    {
                        value: gaming_data?.unclaimed_tickets ?? 0,
                        propertyPath: "gaming_data.unclaimed_tickets",
                    },
                    {
                        value: gaming_data?.cash_box ?? 0,
                        propertyPath: "gaming_data.cash_box",
                    },
                    {
                        value: gaming_data?.tickets_in ?? 0,
                        propertyPath: "gaming_data.tickets_in",
                    },
                    {
                        value: tableData[rowIndex]
                            ? countTransactions([
                                  transfer_transactions ?? [],
                                  sales_count_transactions ?? [],
                                  payment_transactions ?? [],
                                  transfer_transactions_to ?? [],
                              ])
                            : "",
                        editModal: (closeModal) => (
                            <TransactionsFormGaming
                                currentLocationID={
                                    locationDetailByCashupId[cashup_id].location_id
                                }
                                onTransferTransactionSubmission={(data) =>
                                    onTransferTransactionSubmissionDebounce(
                                        data,
                                        rowIndex
                                    )
                                }
                                TransferTransactions={
                                    tableData[rowIndex]
                                        ? tableData[rowIndex].transfer_transactions
                                        : []
                                }
                                TransferTransactionsReadOnly={
                                    transfer_transactions_to ?? []
                                }
                                locations={locations}
                                hierarchicalLocations={hierarchicalLocations}
                                onPaymentTransactionSubmission={(data) =>
                                    onPaymentTransactionSubmissionDebounce(
                                        data,
                                        rowIndex
                                    )
                                }
                                onPaymentTransactionRemove={(data) =>
                                    onPaymentTransactionRemove(
                                        data,
                                        rowIndex,
                                        accountsData,
                                        tableData,
                                        onRowDataChange
                                    )
                                }
                                PaymentTransactionData={payment_transactions ?? []}
                                accountsData={accountsData}
                                venuesData={venuesData}
                                onModalClose={closeModal}
                            />
                        ),
                        modalTitle: "Transactions",
                        hideEditModalFooter: true,
                    },
                    {
                        value: cash_count.actual ?? "$0",
                        readOnly: true,
                        isExplicitlyNotTitleCell: true,
                        applyDollarFormatting: true,

                        propertyPath: "cash_count.actual",
                        nanInsteadOfZeroOut: true,
                    },
                    {
                        value: formatDollarField(cash_count.expected_cash ?? 0),
                        readOnly: true,
                        statusField: () =>
                            cash_count.expected_cash === null ? status : null,
                    },
                    {
                        value:
                            typeof cash_count.cash_variance !== "string"
                                ? formatDollarField(cash_count.cash_variance ?? 0)
                                : cash_count.cash_variance,
                        readOnly: true,
                        statusField: () => (status ? status : null),
                    },
                ];
            }),
        [tableData]
    );

    const tableConfigs: TableConfigs = [
        { columnTitle: "" },
        { columnTitle: "Turnover" },
        { columnTitle: "Total Wins" },
        { columnTitle: "Jackpots" },
        { columnTitle: "Net Profit" },
        { columnTitle: "Unclaimed Movements" },
        { columnTitle: "Cash Box" },
        { columnTitle: "Tickets In" },
        { columnTitle: "Transactions", hideTotal: true },
        { columnTitle: "Cash count", separateColumn: true },
        { columnTitle: "Expected" },
        { columnTitle: "Variance" },
    ];

    const gamingMachineCountsColumn = useMemo(() => {
        const result: { columnTitle: string }[] = [];
        if (!currentVenue) {
            throw new Error("Venue not found");
        } else if (currentVenue.gaming_machine_counts === 0) {
            result.push({
                columnTitle: "Total Machine Count",
            });
            return result;
        } else {
            for (let i = 0; i < currentVenue.gaming_machine_counts; i++) {
                result.push({ columnTitle: `#${i + 1}` });
            }
        }
        return result;
    }, [currentVenue]);

    const gamingMachineCountVariance = useCallback(
        (rowIndex: number) => {
            if (tableData[0]) {
                const cashBoxRead = tableData[0].gaming_data?.gaming_machine_counts
                    ? tableData[0].gaming_data?.gaming_machine_counts[rowIndex]
                          ?.cash_box_read
                    : 0;
                const cashCount = tableData[0].gaming_data?.gaming_machine_counts
                    ? tableData[0].gaming_data?.gaming_machine_counts[rowIndex]
                          ?.cash_count
                    : 0;
                return processData(cashCount) - processData(cashBoxRead);
            }
            return 0;
        },
        [tableData]
    );
    const onGamingMachineCountChange = useCallback(
        (
            tableData: InitDataResponseType[],
            tableRow: number,
            GamingMachineNumber: number,
            property: string,
            data: number
        ) => {
            const tableDataDeepClone = cloneDeep(tableData);
            tableDataDeepClone[tableRow].gaming_data = setGamingMachineCountValue(
                tableDataDeepClone[tableRow].gaming_data,
                GamingMachineNumber,
                property,
                data
            );
            onRowDataChange(tableRow, tableDataDeepClone);
        },
        []
    );
    const dataSourceForGamingMachineCounts: TableRows[] = useMemo(() => {
        const result: TableRows[] = [];
        if (!currentVenue) {
            throw new Error("Venue not found");
        } else if (currentVenue.gaming_machine_counts === 0) {
            result.push([
                {
                    value: "Total Machine Count",
                    readOnly: true,
                },
                {
                    value: processData(
                        tableData[0]?.gaming_data?.gaming_machine_counts
                            ? tableData[0]?.gaming_data?.gaming_machine_counts[0]
                                  ?.cash_box_read
                            : 0
                    ),
                    onSubmit: (data: number) =>
                        onGamingMachineCountChange(
                            tableData,
                            0,
                            0,
                            "cash_box_read",
                            data
                        ),
                },
                {
                    value: processData(
                        tableData[0]?.gaming_data?.gaming_machine_counts
                            ? tableData[0]?.gaming_data?.gaming_machine_counts[0]
                                  ?.cash_count
                            : 0
                    ),
                    onSubmit: (data: number) =>
                        onGamingMachineCountChange(
                            tableData,
                            0,
                            0,
                            "cash_count",
                            data
                        ),
                },
                {
                    value: gamingMachineCountVariance(0),
                    readOnly: true,
                    onSubmit: (data: number) =>
                        onGamingMachineCountChange(
                            tableData,
                            0,
                            0,
                            "variance",
                            data
                        ),
                },
            ]);
        } else {
            gamingMachineCountsColumn?.forEach((currGamingCount, index) => {
                result.push([
                    {
                        value: currGamingCount.columnTitle,
                        readOnly: true,
                    },
                    {
                        value: processData(
                            tableData[0]?.gaming_data?.gaming_machine_counts
                                ? tableData[0].gaming_data?.gaming_machine_counts[
                                      index
                                  ]?.cash_box_read
                                : 0
                        ),
                        onSubmit: (data: number) =>
                            onGamingMachineCountChange(
                                tableData,
                                0,
                                index,
                                "cash_box_read",
                                data
                            ),
                    },
                    {
                        value: processData(
                            tableData[0]?.gaming_data?.gaming_machine_counts //logic problem here
                                ? tableData[0].gaming_data?.gaming_machine_counts[
                                      index
                                  ]?.cash_count
                                : 0
                        ),
                        onSubmit: (data: number) =>
                            onGamingMachineCountChange(
                                tableData,
                                0,
                                index,
                                "cash_count",
                                data
                            ),
                    },
                    {
                        value: gamingMachineCountVariance(index),
                        readOnly: true,
                        onSubmit: (data: number) =>
                            onGamingMachineCountChange(
                                tableData,
                                0,
                                index,
                                "variance",
                                data
                            ),
                    },
                ]);
            });
        }

        return result;
    }, [gamingMachineCountsColumn, currentVenue, tableData]);

    const tableConfigsForGamingMachineCounts: TableConfigs = [
        { columnTitle: "" },
        { columnTitle: "Cash Box Read" },
        { columnTitle: "Cash Count" },
        { columnTitle: "Variance" },
    ];
    if (isLoading || tableData.length === 0) {
        return <CentredSpinner />;
    }

    return (
        <>
            <Table
                onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                    const updatedCashup = produce(
                        initializeGamingFields(tableData[row]),
                        (draft) => {
                            set(draft, propertyPath, updatedValue);
                        }
                    );
                    handleCashupChanged(updatedCashup);
                }}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={() => onClickHandler(1)}
                isInFocus={currentTableInFocus === `${panelId}-1`}
            />
            <StyledCollapsedPanel
                header={
                    <Title level={3} style={{ margin: "0px" }}>
                        Gaming Machine Count
                    </Title>
                }
                style={{ paddingTop: "12px", paddingBottom: "24px" }}
                key={"1"}
            />
            <Table
                dataSources={dataSourceForGamingMachineCounts}
                tableConfigs={tableConfigsForGamingMachineCounts}
                onClickHandler={() => onClickHandler(2)}
                isInFocus={currentTableInFocus === `${panelId}-2`}
                evenDistribution
            />
        </>
    );
};
