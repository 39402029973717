import React, { ReactElement, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

// Pages/components
import { RoutePaths } from "./RoutesEnum";
import LoginPage from "../Pages/UserManagement/Login";
import PageNotFound from "../Pages/ErrorPages/PageNotFound";
import { AuthProtectedRoute } from "./AuthProtectedRoute";
import UnAuthorised from "../Pages/ErrorPages/UnAuthorised";
import UsersPage from "../Pages/UserManagement/Users";
import {
    ATMTable,
    CCUTable,
    CRTTable,
    DailyShiftReport,
    GamingTable,
    OfficeTable,
    PosTable,
    SafeTable,
    WageringTable,
} from "../Pages/CashupHome/CollaborationTable/CollaborationTable";
import { QuantacoLoader } from "../Components/QuantacoLoader/QuantacoLoader";
import { CashupReportSummaryPage } from "Pages/CashupReport/CashupReportSummaryPage";
import { CashupReportDetailPage } from "Pages/CashupReport/CashupReportDetailPage";
import { AuditReportPage } from "../Pages/CashupReport/AuditReportPage";

export default function AppRouter(): ReactElement {
    return (
        <Suspense fallback={QuantacoLoader}>
            <Switch>
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.HOME}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.POS}
                    component={PosTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Gaming}
                    component={GamingTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Safe}
                    component={SafeTable}
                />

                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Wagering}
                    component={WageringTable}
                />

                <AuthProtectedRoute
                    exact
                    path={RoutePaths.ATM}
                    component={ATMTable}
                />

                <AuthProtectedRoute
                    exact
                    path={RoutePaths.Office}
                    component={OfficeTable}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.DailyShiftReport}
                    component={DailyShiftReport}
                />
                <AuthProtectedRoute
                    exact
                    path={RoutePaths.CRT}
                    component={CRTTable}
                />

                <AuthProtectedRoute
                    exact
                    path={RoutePaths.CCU}
                    component={CCUTable}
                />

                <AuthProtectedRoute path={RoutePaths.USERS} component={UsersPage} />

                <AuthProtectedRoute
                    path={RoutePaths.CASHUP_REPORT_SUMMARY}
                    component={CashupReportSummaryPage}
                    exact
                />

                <AuthProtectedRoute
                    path={RoutePaths.CASHUP_REPORT_DETAIL}
                    component={CashupReportDetailPage}
                />

                <AuthProtectedRoute
                    path={RoutePaths.AUDIT_REPORT}
                    component={AuditReportPage}
                />

                <Route exact path={RoutePaths.LOGIN} component={LoginPage} />

                <Route
                    exact
                    path={RoutePaths.UNAUTHORISED}
                    component={UnAuthorised}
                />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Suspense>
    );
}
