import React, { memo, useMemo } from "react";
import { EmptyDiv, StyledInput } from "./SafeTab";

const CommentSectionComponent = ({
    onFocus,
    onChange,
    comments,
    tableLength,
}: {
    onFocus: () => void;

    onChange: (value: string, index: number) => void;
    comments: {
        content: string;
        mentions: string[];
        disabled?: boolean;
    }[];
    tableLength: number;
}) => {
    const emptyArray = useMemo(() => {
        const tempArray = new Array(tableLength + 1);
        tempArray.fill(0);
        return tempArray;
    }, [tableLength]);

    return (
        <div
            style={{
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                gap: 24,
            }}
        >
            {emptyArray.map((item, key) => {
                if (key === 0)
                    return (
                        <EmptyDiv numberOfItems={emptyArray.length} key={key}>
                            Comments
                        </EmptyDiv>
                    );
                return (
                    <StyledInput
                        numberOfItems={emptyArray.length}
                        key={key}
                        autoSize
                        onFocus={onFocus}
                        disabled={comments[key - 1]?.disabled}
                        value={comments[key - 1] ? comments[key - 1].content : ""}
                        onChange={(e) => onChange(e.target.value, key - 1)}
                    />
                );
            })}
        </div>
    );
};
export const CommentSection = memo(CommentSectionComponent);
