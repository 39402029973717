import { List, Typography } from "antd";
import React from "react";
import { ExtendedLocationItem } from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    CashupDetail,
    DepositInTransaction,
    DepositOutTransaction,
    EftposCountTransaction,
    PaymentTransaction,
    PaymentType,
    SalesCountTransaction,
    TransferTransaction,
} from "../CashupReportModel";

interface Props {
    cashupDetail: CashupDetail;
    locationById: Record<string, ExtendedLocationItem>;
}

const paymentTypeTextMapper: Record<PaymentType, string> = {
    [PaymentType.Expense]: "Expense",
    [PaymentType.Payout]: "Payout",
    [PaymentType.PettyCash]: "Petty Cash",
};

export const TransactionBreakdown: React.FC<Props> = ({
    cashupDetail,
    locationById,
}) => {
    const {
        transferTransactions,
        transferTransactionsTo,
        paymentTransactions,
        salesCountTransactions,
        depositInTransactions,
        depositOutTransactions,
        eftposCountTransactions,
    } = cashupDetail;

    return (
        <div style={{ maxHeight: 300, overflowY: "auto" }}>
            {transferTransactionsTo.length > 0 ? (
                <TransferFromBreakdown
                    locationById={locationById}
                    transactions={transferTransactionsTo}
                />
            ) : null}
            {transferTransactions.length > 0 ? (
                <TransferToBreakdown
                    locationById={locationById}
                    transactions={transferTransactions}
                />
            ) : null}
            {paymentTransactions.length > 0 ? (
                <PaymentBreakdown paymentTransactions={paymentTransactions} />
            ) : null}
            {salesCountTransactions.length > 0 ? (
                <SalesCountBreakdown
                    salesCountTransactions={salesCountTransactions}
                />
            ) : null}
            {depositInTransactions.length > 0 ? (
                <DepositBreakdown
                    title="Deposit In"
                    depositTransactions={depositInTransactions}
                />
            ) : null}
            {depositOutTransactions.length > 0 ? (
                <DepositBreakdown
                    title="Deposit Out"
                    depositTransactions={depositOutTransactions}
                />
            ) : null}
            {eftposCountTransactions.length > 0 ? (
                <EftposCountBreakdown
                    eftposCountTransactions={eftposCountTransactions}
                />
            ) : null}
        </div>
    );
};

interface EftposCountBreakdownProps {
    eftposCountTransactions: EftposCountTransaction[];
}

const EftposCountBreakdown: React.FC<EftposCountBreakdownProps> = ({
    eftposCountTransactions,
}) => {
    return (
        <List
            header={
                <>
                    <Typography.Title style={{ marginBottom: 0 }} level={5}>
                        Eftpos Count
                    </Typography.Title>
                </>
            }
            style={{ background: "white", minWidth: 300 }}
            dataSource={eftposCountTransactions}
            bordered
            renderItem={({ actual }) => (
                <List.Item>
                    <Typography.Text>{`$${actual}`}</Typography.Text>
                </List.Item>
            )}
        />
    );
};

interface DepositBreakdownProps {
    title: string;
    depositTransactions: DepositInTransaction[] | DepositOutTransaction[];
}

const DepositBreakdown: React.FC<DepositBreakdownProps> = ({
    depositTransactions,
    title,
}) => {
    return (
        <List
            header={
                <>
                    <Typography.Title style={{ marginBottom: 0 }} level={5}>
                        {title}
                    </Typography.Title>
                    <Typography.Text>Amount - Notes</Typography.Text>
                </>
            }
            style={{ background: "white", minWidth: 300 }}
            dataSource={depositTransactions}
            bordered
            renderItem={({ amount, memo }) => (
                <List.Item>
                    <Typography.Text>{`$${amount} - ${memo}`}</Typography.Text>
                </List.Item>
            )}
        />
    );
};

interface SalesCountBreakdownProps {
    salesCountTransactions: SalesCountTransaction[];
}

const SalesCountBreakdown: React.FC<SalesCountBreakdownProps> = ({
    salesCountTransactions,
}) => {
    return (
        <List
            header={
                <>
                    <Typography.Title style={{ marginBottom: 0 }} level={5}>
                        Sales Count
                    </Typography.Title>
                    <Typography.Text>Wins - Turnover - Unclaimed</Typography.Text>
                </>
            }
            style={{ background: "white", minWidth: 300 }}
            dataSource={salesCountTransactions}
            bordered
            renderItem={({ wins, turnover, unclaimed }) => (
                <List.Item>
                    <Typography.Text>
                        {`$${wins} - $${turnover} - $${unclaimed}`}
                    </Typography.Text>
                </List.Item>
            )}
        />
    );
};

interface PaymentBreakdownProps {
    paymentTransactions: PaymentTransaction[];
}

const PaymentBreakdown: React.FC<PaymentBreakdownProps> = ({
    paymentTransactions,
}) => {
    return (
        <List
            header={
                <Typography.Title style={{ marginBottom: 0 }} level={5}>
                    Payment
                </Typography.Title>
            }
            style={{ background: "white", minWidth: 300 }}
            dataSource={paymentTransactions}
            bordered
            renderItem={({ amount, paymentType }) => (
                <List.Item>
                    {paymentTypeTextMapper[paymentType]} : ${amount}
                </List.Item>
            )}
        />
    );
};

interface TransactionBreakdownItemProps {
    transactions: TransferTransaction[];
    locationById: Record<string, ExtendedLocationItem>;
}

const TransferToBreakdown: React.FC<TransactionBreakdownItemProps> = ({
    transactions,
    locationById,
}) => (
    <List
        header={
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
                Transfers
            </Typography.Title>
        }
        style={{ background: "white", minWidth: 300 }}
        dataSource={transactions}
        bordered
        renderItem={({ toLocationId, amount }) => (
            <List.Item>
                <Typography.Text>
                    {`${locationById[toLocationId].name} : $${amount}`}
                </Typography.Text>
            </List.Item>
        )}
    />
);

const TransferFromBreakdown: React.FC<TransactionBreakdownItemProps> = ({
    transactions,
    locationById,
}) => (
    <List
        header={
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
                Receive From
            </Typography.Title>
        }
        style={{ background: "white", minWidth: 300 }}
        dataSource={transactions}
        bordered
        renderItem={({ fromLocationId, amount }) => (
            <List.Item>
                <Typography.Text>
                    {`${locationById[fromLocationId].name} : $${amount}`}
                </Typography.Text>
            </List.Item>
        )}
    />
);
