import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { RoutePaths } from "Router/RoutesEnum";

export const mobileBreakpoint = 792;

export const useIsMobile = () => {
    const history = useHistory();
    const location = useLocation();

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileBreakpoint);

    useEffect(() => {
        if (isMobile && location.pathname !== RoutePaths.DailyShiftReport) {
            history.push(RoutePaths.DailyShiftReport);
        }
    }, [isMobile, location.pathname]);

    const resize = () => {
        const isMobileCheck = window.innerWidth < mobileBreakpoint;
        isMobileCheck !== isMobile && setIsMobile(isMobileCheck);
    };

    useEffect(() => {
        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    return isMobile;
};
