import { Collapse } from "antd";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import { chain, uniqBy } from "lodash";
import React, { useMemo } from "react";
import { useGetGroupDataQuery } from "Redux/StateSlices/GroupDataAPI";
import { useAppSelector } from "Redux/TypedReduxFunctions";
import { dayjsFormat, toQTCDate } from "utils/date-utils";
import { LocationType } from "../CashupReportModel";
import { useGetCashups } from "../Hooks/useGetCashups";
import { CashupDetailReportTable } from "./CashupDetailReportTable";

const { Panel } = Collapse;

export interface CsvCashupDataByLocation {
    locationName: string;
    csv: string;
}

interface Props {
    updateCsvDataForReport: (csvData: CsvCashupDataByLocation) => void;
}

export const CashupDetailReport = ({ updateCsvDataForReport }: Props) => {
    const { selectedDate, selectedVenueId } = useAppSelector(
        (state) => state.shiftReportSlice
    );

    const { data: groupdata } = useGetGroupDataQuery(null);
    const { cashups, isLoading } = useGetCashups({
        range: {
            start: toQTCDate(selectedDate!, dayjsFormat.dayMonthYearSeparateByDash),
            end: toQTCDate(selectedDate!, dayjsFormat.dayMonthYearSeparateByDash),
        },
        venueId: selectedVenueId,
        disabled: !selectedDate,
    });

    const cashupsByLocationId = useMemo(
        () => chain(cashups).groupBy("locationId").value(),
        [cashups]
    );
    const locationById = useMemo(
        () =>
            groupdata
                ? chain(groupdata.locations).keyBy("location_id").mapValues().value()
                : undefined,
        [groupdata]
    );

    const accountById = useMemo(
        () =>
            groupdata
                ? chain(groupdata.accounts)
                      .filter(({ tender_type }) => Boolean(tender_type))
                      .keyBy("account_id")
                      .mapValues()
                      .value()
                : undefined,
        [groupdata]
    );

    const classById = useMemo(
        () =>
            groupdata
                ? chain(groupdata.classes).keyBy("class_id").mapValues().value()
                : undefined,
        [groupdata]
    );

    if (!groupdata) {
        throw new Error(
            "Group data not available in CashupDetailReport, this shuold be impossible"
        );
    }

    if (isLoading || !cashups || !locationById || !accountById || !classById) {
        return <CentredSpinner />;
    }

    return (
        <Collapse defaultActiveKey={Object.keys(locationById)} ghost>
            {uniqBy(groupdata.locations_hierarchy, "name")
                .filter(
                    ({ location_type, venue_id }: any) =>
                        venue_id === selectedVenueId &&
                        (location_type === LocationType.atm ||
                            location_type === LocationType.keno ||
                            location_type === LocationType.tab ||
                            location_type === LocationType.gaming ||
                            location_type === LocationType.pos)
                )
                .map(({ location_type, name, location_id, sub_locations }: any) => {
                    const cashupsForSubLocations = sub_locations
                        .filter(
                            (subLocation: any) =>
                                cashupsByLocationId[subLocation.location_id]
                        )
                        .flatMap(
                            (subLocation: any) =>
                                cashupsByLocationId[subLocation.location_id]
                        );

                    return (
                        <Panel header={name} key={location_id}>
                            <CashupDetailReportTable
                                dataSource={
                                    cashupsForSubLocations.map(
                                        ({ id, ...cashup }: any) => ({
                                            key: id,
                                            ...cashup,
                                        })
                                    ) ?? []
                                }
                                locationById={locationById}
                                locationType={location_type}
                                accountById={accountById}
                                classById={classById}
                                locationName={name}
                                propagateTableDataInCsvFormat={
                                    updateCsvDataForReport
                                }
                            />
                        </Panel>
                    );
                })}
        </Collapse>
    );
};
