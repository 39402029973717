import { ColumnsType } from "antd/lib/table";
import { sum, sumBy } from "lodash";
import React, { useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { ExtendedLocationItem } from "Redux/StateSlices/GroupData/LocationsAPI";
import { CashupDetail, LocationType } from "../CashupReportModel";
import { ReportColumnWithTooltip } from "./ReportColumnWithTooltip";
import { TransactionBreakdown } from "./TransactionBreakdown";
import { formatCellForDisplay } from "../CashupReportUtils";

const getMandatoryColumnSettings = ({
    locationById,
    excludeTransaction,
    forExport,
}: {
    locationById: Record<string, ExtendedLocationItem>;
    excludeTransaction: boolean;
    forExport: boolean;
}): ColumnsType<any> => {
    const columns: ColumnsType<any> = [
        {
            title: "Location",
            dataIndex: "locationName",
        },
        {
            title: "Opening Balance",
            dataIndex: "body",
            render: ({ cashCount: { openingBalance } }: CashupDetail) => {
                return formatCellForDisplay(openingBalance);
            },
        },
    ];

    if (!excludeTransaction) {
        columns.push({
            title: "Transactions",
            dataIndex: "body",
            render: (cashupDetail: CashupDetail) => {
                const {
                    transferTransactions,
                    transferTransactionsTo,
                    paymentTransactions,
                    depositInTransactions,
                    depositOutTransactions,
                    eftposCountTransactions,
                    salesCountTransactions,
                } = cashupDetail;
                const totalTransactions =
                    transferTransactions.length +
                    transferTransactionsTo.length +
                    paymentTransactions.length +
                    depositInTransactions.length +
                    depositOutTransactions.length +
                    eftposCountTransactions.length +
                    salesCountTransactions.length;

                if (forExport) {
                    return totalTransactions;
                }
                return (
                    <ReportColumnWithTooltip
                        toolTipContent={
                            totalTransactions > 0 ? (
                                <TransactionBreakdown
                                    cashupDetail={cashupDetail}
                                    locationById={locationById}
                                />
                            ) : undefined
                        }
                    >
                        {totalTransactions}
                    </ReportColumnWithTooltip>
                );
            },
        });
    }

    columns.push({
        title: "Variance",
        dataIndex: "body",
        render: ({ cashCount: { variance } }: CashupDetail) => {
            return formatCellForDisplay(variance);
        },
    });
    return columns;
};

const getKenoColumnSettings = () => [
    {
        title: "Keno Bet",
        dataIndex: "body",
        render: ({ kenoData: { kenoBet } }: CashupDetail) => {
            return formatCellForDisplay(kenoBet);
        },
    },
    {
        title: "Heads/Tails ",
        dataIndex: "body",
        render: ({ kenoData: { headsTails } }: CashupDetail) => {
            return formatCellForDisplay(headsTails);
        },
    },
    {
        title: "Magic Millions",
        dataIndex: "body",
        render: ({ kenoData: { magicMillions } }: CashupDetail) => {
            return formatCellForDisplay(magicMillions);
        },
    },
    {
        title: "Cash Pays",
        dataIndex: "body",
        render: ({ kenoData: { cashPays } }: CashupDetail) => {
            return formatCellForDisplay(cashPays);
        },
    },
    {
        title: "Maintenance",
        dataIndex: "body",
        render: ({ kenoData: { maintainence } }: CashupDetail) => {
            return formatCellForDisplay(maintainence);
        },
    },
    {
        title: "Stationary",
        dataIndex: "body",
        render: ({ kenoData: { stationary } }: CashupDetail) => {
            return formatCellForDisplay(stationary);
        },
    },
    {
        title: "Commission After",
        dataIndex: "body",
        render: ({ kenoData: { commissionAfter } }: CashupDetail) => {
            return formatCellForDisplay(commissionAfter);
        },
    },
    {
        title: "Paid to Keno",
        dataIndex: "body",
        render: ({ kenoData: { paidToKeno } }: CashupDetail) => {
            return formatCellForDisplay(paidToKeno);
        },
    },
];

const getAtmColumnSettings = () => [
    {
        title: "External withdrawals",
        dataIndex: "body",
        render: ({ atmData: { withdrawls } }: CashupDetail) => {
            return formatCellForDisplay(withdrawls);
        },
    },
    {
        title: "External refills",
        dataIndex: "body",
        render: ({ atmData: { refills } }: CashupDetail) => {
            return formatCellForDisplay(refills);
        },
    },
];

const getTabColumnSettings = (forExport: boolean) => [
    {
        title: "Bet Types",
        dataIndex: "body",
        render: ({ tabData: { betTypes } }: CashupDetail) => {
            const totalBets = sum(Object.values(betTypes));
            if (forExport) {
                return formatCellForDisplay(totalBets);
            }
            return (
                <ReportColumnWithTooltip
                    toolTipContent={
                        totalBets ? (
                            <div>
                                <div>{`S/Bet: $${betTypes.sBet}`}</div>
                                <div>{`Trackside Sales: $${betTypes.tracksideSales}`}</div>
                            </div>
                        ) : undefined
                    }
                >
                    {formatCellForDisplay(totalBets)}
                </ReportColumnWithTooltip>
            );
        },
    },
    {
        title: "Money in",
        dataIndex: "body",
        render: ({ tabData: { moneyIn } }: CashupDetail) => {
            return formatCellForDisplay(moneyIn);
        },
    },
    {
        title: "Money out",
        dataIndex: "body",
        render: ({ tabData: { moneyOut } }: CashupDetail) => {
            return formatCellForDisplay(moneyOut);
        },
    },
    {
        title: "Costs",
        dataIndex: "body",
        render: ({ tabData: { costs } }: CashupDetail) => {
            return formatCellForDisplay(costs);
        },
    },
    {
        title: "Commission After",
        dataIndex: "body",
        render: ({ tabData: { commissionAfter } }: CashupDetail) => {
            return formatCellForDisplay(commissionAfter);
        },
    },
    {
        title: "Paid to TAB",
        dataIndex: "body",
        render: ({ tabData: { paidToTab } }: CashupDetail) => {
            return formatCellForDisplay(paidToTab);
        },
    },
];

const getGamingColumnSettings = () => [
    {
        title: "Money in",
        dataIndex: "body",
        render: ({ gamingData: { turnover } }: CashupDetail) => {
            return formatCellForDisplay(turnover);
        },
    },
    {
        title: "Money out",
        dataIndex: "body",
        render: ({ gamingData: { jackpot, totalWins } }: CashupDetail) => {
            return formatCellForDisplay(jackpot + totalWins);
        },
    },
    {
        title: "TITO",
        dataIndex: "body",
        render: ({ gamingData: { turnover, jackpot, totalWins } }: CashupDetail) => {
            return formatCellForDisplay(turnover - jackpot - totalWins);
        },
    },
];

// Unscalable....
const showedClassName = ["Beverage", "Food"];
const showedTenderNames = ["Visa", "Eftpos", "Cash"];

const getPosColumnSettings =
    ({ accountById, classById }: Props) =>
    (forExport: boolean) =>
        [
            {
                title: "Sales total",
                dataIndex: "body",
                render: ({ posData: { amount } }: CashupDetail) => {
                    return formatCellForDisplay(amount);
                },
            },
            {
                title: "Beverage",
                dataIndex: "body",
                render: ({ posData: { classSplit } }: CashupDetail) => {
                    const beverageClassSales =
                        classSplit?.find(
                            ({ classId }) => classById[classId]?.name === "Beverage"
                        )?.amount ?? 0;
                    return formatCellForDisplay(beverageClassSales);
                },
            },
            {
                title: "Food",
                dataIndex: "body",
                render: ({ posData: { classSplit } }: CashupDetail) => {
                    const foodClassSales =
                        classSplit?.find(
                            ({ classId }) => classById[classId]?.name === "Food"
                        )?.amount ?? 0;
                    return formatCellForDisplay(foodClassSales);
                },
            },
            {
                title: "Other classes",
                dataIndex: "body",
                render: ({ posData: { classSplit } }: CashupDetail) => {
                    const otherClasses = classSplit
                        ?.filter(
                            ({ classId }) =>
                                !showedClassName.includes(classById[classId]?.name)
                        )
                        ?.map(({ classId, amount }) => ({
                            className: classById[classId]?.name,
                            sales: amount,
                        }));
                    if (!otherClasses || otherClasses.length === 0) {
                        return formatCellForDisplay(0);
                    }

                    if (forExport) {
                        return formatCellForDisplay(sumBy(otherClasses, "sales"));
                    }
                    return (
                        <ReportColumnWithTooltip
                            toolTipContent={
                                <div>
                                    {otherClasses.map(({ className, sales }) => (
                                        <div key={className}>
                                            {`${className}: $${sales}`}
                                        </div>
                                    ))}
                                </div>
                            }
                        >
                            {formatCellForDisplay(sumBy(otherClasses, "sales"))}
                        </ReportColumnWithTooltip>
                    );
                },
            },
            {
                title: "Visa",
                dataIndex: "body",
                render: ({ posData: { accountSplit } }: CashupDetail) => {
                    const visaMount =
                        accountSplit?.find(
                            ({ accountId }) =>
                                accountById[accountId]?.name === "Visa"
                        )?.amount ?? 0;
                    return formatCellForDisplay(visaMount);
                },
            },
            {
                title: "Eftpos",
                dataIndex: "body",
                render: ({ posData: { accountSplit } }: CashupDetail) => {
                    const EftposAmount =
                        accountSplit?.find(
                            ({ accountId }) =>
                                accountById[accountId]?.name === "Eftpos"
                        )?.amount ?? 0;
                    return formatCellForDisplay(EftposAmount);
                },
            },
            {
                title: "Cash",
                dataIndex: "body",
                render: ({ posData: { accountSplit } }: CashupDetail) => {
                    const CashAmount =
                        accountSplit?.find(
                            ({ accountId }) =>
                                accountById[accountId]?.name === "Cash"
                        )?.amount ?? 0;
                    return formatCellForDisplay(CashAmount);
                },
            },
            {
                title: "Other tenders",
                dataIndex: "body",
                render: ({ posData: { accountSplit } }: CashupDetail) => {
                    const otherTenders = accountSplit
                        ?.filter(
                            ({ accountId }) =>
                                !showedTenderNames.includes(
                                    accountById[accountId]?.name
                                )
                        )
                        ?.map(({ accountId, amount }) => ({
                            tenderName: accountById[accountId]?.name,
                            sales: amount,
                        }));

                    if (!otherTenders || otherTenders.length === 0) {
                        return formatCellForDisplay(0);
                    }

                    if (forExport) {
                        return formatCellForDisplay(sumBy(otherTenders, "sales"));
                    }
                    return (
                        <ReportColumnWithTooltip
                            toolTipContent={
                                <div>
                                    {otherTenders.map(({ tenderName, sales }) => (
                                        <div key={tenderName}>
                                            {`${tenderName}: $${sales}`}
                                        </div>
                                    ))}
                                </div>
                            }
                        >
                            {formatCellForDisplay(sumBy(otherTenders, "sales"))}
                        </ReportColumnWithTooltip>
                    );
                },
            },
        ];
interface Props {
    accountById: Record<string, ExtendedAccountItem>;
    classById: Record<string, ExtendedClassItem>;
}

export const useCashupDetailReportTable = (props: Props) => {
    const columnSettingsByLocationType: Record<LocationType, any> = useMemo(
        () => ({
            [LocationType.atm]: getAtmColumnSettings,
            [LocationType.bank]: getAtmColumnSettings,
            [LocationType.gaming]: getGamingColumnSettings,
            [LocationType.keno]: getKenoColumnSettings,
            [LocationType.office]: getAtmColumnSettings,
            [LocationType.pos]: getPosColumnSettings(props),
            [LocationType.safe]: getAtmColumnSettings,
            [LocationType.tab]: getTabColumnSettings,
            [LocationType.trade_till]: getAtmColumnSettings,
        }),
        [props]
    );

    return {
        getMandatoryColumnSettings,
        columnSettingsByLocationType,
    };
};
