import { Parser } from "json2csv";
import { chain, round, sumBy } from "lodash";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import { formatForBackend } from "utils/date-utils";
import {
    Cashup,
    CashupDetail,
    CashupReportItem,
    ClassSplit,
    LocationType,
    RemoteAccountSplit,
    RemoteCashupDetail,
    RemoteTransferTransaction,
    TransferTransaction,
} from "./CashupReportModel";

export enum ClassTypeEnum {
    FOOD = 1,
    BEVERAGE = 2,
    ACCOMMODATION = 3,
    GAMING = 4,
    OTHER = 5,
}
export const transformCashupDetailForClient = ({
    pos_data,
    gaming_data,
    atm_data,
    cash_count,
    keno_data,
    tab_data,
    transfer_transactions,
    transfer_transactions_to,
    payment_transactions,
    sales_count_transactions,
    deposit_in_transactions,
    deposit_out_transactions,
    eftpos_count_transactions,
}: RemoteCashupDetail): CashupDetail => {
    const { jackpot = 0, total_wins = 0, turnover = 0 } = gaming_data ?? {};
    const { amount: posDataAmount = 0 } = pos_data ?? {};
    const {
        opening_balance: openingBalance,
        variance = 0,
        actual,
        expected,
    } = cash_count ?? {};
    const { refills = 0, withdrawls = 0 } = atm_data ?? {};
    const {
        cash_pays = 0,
        heads_tails = 0,
        keno_bet = 0,
        magic_millions = 0,
        maintainence = 0,
        paid_to_keno = 0,
        stationary = 0,
        commission_after = 0,
    } = keno_data ?? {};

    const {
        bet_types,
        commission,
        costs,
        money_in: tab_money_in,
        money_out: tab_money_out,
        paid_to_tab,
        commission_after: tab_commission_after,
    } = tab_data ?? {};

    return {
        posData: {
            amount: posDataAmount,
            accountSplit: transformAccountSplitForClient(pos_data.account_split),
            classSplit: pos_data.class_split?.map(
                ({ class_id: classId, amount }) => ({
                    classId,
                    amount,
                })
            ),
        },
        gamingData: {
            turnover,
            jackpot,
            totalWins: total_wins,
        },
        atmData: { refills, withdrawls },
        kenoData: {
            cashPays: cash_pays,
            headsTails: heads_tails,
            kenoBet: keno_bet,
            magicMillions: magic_millions,
            maintainence: maintainence,
            paidToKeno: paid_to_keno,
            commissionAfter: commission_after,
            stationary,
        },
        tabData: {
            betTypes: {
                sBet: bet_types?.s_bet ?? 0,
                tracksideSales: bet_types?.trackside_sales ?? 0,
            },
            commission,
            costs,
            moneyIn: tab_money_in,
            moneyOut: tab_money_out,
            paidToTab: paid_to_tab,
            commissionAfter: tab_commission_after,
        },
        cashCount: {
            actual,
            expected,
            openingBalance,
            variance,
        },
        transferTransactions: transformTransferTransactionForClient(
            transfer_transactions ?? []
        ),
        transferTransactionsTo: transformTransferTransactionForClient(
            transfer_transactions_to ?? []
        ),
        paymentTransactions: payment_transactions.map(
            ({ account_split, payment_type_id, ...others }) => ({
                ...others,
                paymentType: payment_type_id,
                accountSplit: transformAccountSplitForClient(account_split) ?? [],
            })
        ),
        salesCountTransactions: [...sales_count_transactions],
        eftposCountTransactions: [...eftpos_count_transactions],
        depositInTransactions: [...deposit_in_transactions],
        depositOutTransactions: [...deposit_out_transactions],
    };
};

const transformAccountSplitForClient = (
    remoteAccountSplits?: RemoteAccountSplit[]
) =>
    remoteAccountSplits?.map(({ account_id: accountId, amount }) => ({
        accountId,
        amount,
    }));

const transformTransferTransactionForClient = (
    remoteTransferTransaction: RemoteTransferTransaction[]
): TransferTransaction[] =>
    remoteTransferTransaction.map(
        ({
            amount,
            from_location_id: fromLocationId,
            to_location_id: toLocationId,
        }) => ({
            amount,
            fromLocationId,
            toLocationId,
        })
    );

export const calculateCashupReport = ({
    cashups,
    classTypeById,
}: {
    cashups: Cashup[];
    classTypeById: Record<string, string>;
}) =>
    chain(cashups)
        .groupBy(({ date }) => formatForBackend(date))
        .map((cashups, date) => {
            return cashups.reduce<CashupReportItem>(
                (result, { locationType, body: { posData, gamingData } }) => {
                    if (locationType === LocationType.pos) {
                        result.total += posData.amount;
                        posData.classSplit?.forEach(({ amount, classId }) => {
                            if (
                                Number(classTypeById[classId]) === ClassTypeEnum.FOOD
                            ) {
                                result.food += amount;
                            } else if (
                                Number(classTypeById[classId]) ===
                                ClassTypeEnum.BEVERAGE
                            ) {
                                result.beverage += amount;
                            } else if (
                                Number(classTypeById[classId]) ===
                                ClassTypeEnum.ACCOMMODATION
                            ) {
                                result.accommodation! += amount;
                            } else {
                                result.other += amount;
                            }
                        });

                        return result;
                    }

                    if (locationType === LocationType.gaming) {
                        result.turnover! += gamingData.turnover;
                        result.net_profit! +=
                            gamingData.turnover -
                            gamingData.totalWins -
                            gamingData.jackpot;
                        return result;
                    }

                    return result;
                },
                {
                    date,
                    key: date,
                    total: 0,
                    food: 0,
                    beverage: 0,
                    other: 0,
                    accommodation: 0,
                    turnover: 0,
                    net_profit: 0,
                }
            );
        })
        .value();

// returnZeroOnRunTimeNullOrUndefined will ensure any run time null / undefined become $0
export const formatCellForDisplay = (
    cellValue: number,
    returnZeroOnRunTimeNullOrUndefined = false
) => {
    // Use == to capture both null and undefined
    if (returnZeroOnRunTimeNullOrUndefined && cellValue == null) {
        return "$0";
    }
    return Number.isNaN(Number(cellValue)) ? cellValue : `$${round(cellValue, 2)}`;
};

export const parseLocationCashupsForDailyReportExport =
    ({
        cashupsByLocationId,
        locationType,
        classes,
        singleShift,
    }: {
        cashupsByLocationId: Record<string, Cashup[]>;
        locationType: LocationType;
        classes: ExtendedClassItem[];
        singleShift: boolean;
    }) =>
    (location: ExtendedLocationItemWithChildren) => {
        const { sub_locations, name: locationName } = location;
        const cashups = sub_locations
            .filter((subLocation) => cashupsByLocationId[subLocation.location_id])
            .flatMap((subLocation) => cashupsByLocationId[subLocation.location_id]);

        if (cashups.length === 0)
            return {
                section: locationType,
                content: `No cashup has been recorded for ${locationName}`,
            };

        const commonProps = {
            locationName,
            cashups,
        };
        switch (locationType) {
            case LocationType.gaming:
                return parseGamingCashupsForDailyReportExport(commonProps);
            case LocationType.tab:
                return parseTabCashupsForDailyReportExport(commonProps);
            case LocationType.keno:
                return parseKenoCashupsForDailyReportExport(commonProps);
            case LocationType.pos:
                return parsePosCashupsForDailyReportExport({
                    ...commonProps,
                    classes,
                    singleShift,
                });
            default:
                throw new Error(
                    `Unsupported location type ${locationType} when preparing data for daily cashup export`
                );
        }
    };

const parsePosCashupsForDailyReportExport = (params: {
    cashups: Cashup[];
    classes: ExtendedClassItem[];
    singleShift: boolean;
    locationName: string;
}) => {
    const { cashups, classes, singleShift, locationName } = params;

    if (singleShift) {
        const classSplits = cashups.flatMap(
            ({ body }) =>
                body.posData.classSplit?.map(({ classId, amount }) => ({
                    classId,
                    amount,
                })) ?? []
        );

        const data = classes.map(({ class_id, name }) => {
            return {
                title: name,
                value: formatCellForDisplay(
                    sumBy(classSplits, ({ classId, amount }) =>
                        classId === class_id ? amount : 0
                    ),
                    true
                ),
            };
        });

        return {
            section: LocationType.pos,
            content: new Parser({
                header: false,
            }).parse([
                {
                    title: locationName,
                    value: "",
                },
                ...data,
                {
                    title: "Total",
                    value: formatCellForDisplay(sumBy(classSplits, "amount"), true),
                },
            ]),
        };
    }

    const classSplits = chain(cashups)
        .groupBy("timePeriod")
        .reduce<Record<any, ClassSplit[]>>((result, cashups, timePeriod) => {
            result[timePeriod] = cashups.flatMap(
                ({ body }) => body.posData.classSplit ?? []
            );
            return result;
        }, {})
        .value();

    const data = classes.map(({ class_id, name }) => {
        const classSplitAm = classSplits["1"];
        const classSplitPm = classSplits["2"];

        return {
            title: name,
            valueAm: formatCellForDisplay(
                sumBy(classSplitAm, ({ classId, amount }) =>
                    classId === class_id ? amount : 0
                ),
                true
            ),
            valuePm: formatCellForDisplay(
                sumBy(classSplitPm, ({ classId, amount }) =>
                    classId === class_id ? amount : 0
                ),
                true
            ),
        };
    });
    return {
        section: LocationType.pos,
        content: new Parser({
            header: false,
        }).parse([
            {
                title: locationName,
                valueAm: "AM",
                valuePm: "PM",
            },
            ...data,
            {
                title: "Total",
                valueAm: formatCellForDisplay(
                    sumBy(classSplits["1"], "amount"),
                    true
                ),
                valuePm: formatCellForDisplay(
                    sumBy(classSplits["2"], "amount"),
                    true
                ),
            },
        ]),
    };
};

const parseKenoCashupsForDailyReportExport = ({
    cashups,
    locationName,
}: {
    cashups: Cashup[];
    locationName: string;
}) => {
    return {
        section: LocationType.keno,
        content: new Parser({
            header: true,
        }).parse({
            Location: locationName,
            Commission: formatCellForDisplay(
                sumBy(cashups, "body.kenoData.commissionAfter"),
                true
            ),
            Payout: formatCellForDisplay(
                sumBy(cashups, "body.kenoData.paidToKeno"),
                true
            ),
        }),
    };
};

const parseTabCashupsForDailyReportExport = ({
    cashups,
    locationName,
}: {
    cashups: Cashup[];
    locationName: string;
}) => {
    return {
        section: LocationType.tab,
        content: new Parser({
            header: true,
        }).parse({
            Location: locationName,
            Commission: formatCellForDisplay(
                sumBy(cashups, "body.tabData.commissionAfter"),
                true
            ),
            Payout: formatCellForDisplay(
                sumBy(cashups, "body.tabData.paidToTab"),
                true
            ),
        }),
    };
};

const parseGamingCashupsForDailyReportExport = ({
    cashups,
    locationName,
}: {
    cashups: Cashup[];
    locationName: string;
}) => {
    return {
        section: LocationType.gaming,
        content: new Parser({
            header: true,
        }).parse({
            Location: locationName,
            "Cash on hand": formatCellForDisplay(
                sumBy(cashups, "body.cashCount.actual"),
                true
            ),
            Payout: formatCellForDisplay(
                sumBy(cashups, "body.gamingData.moneyOut"),
                true
            ),
            Turnover: formatCellForDisplay(
                cashups.reduce((result, cashup) => {
                    result += sumBy(cashup.body.salesCountTransactions, "turnover");

                    return result;
                }, 0),
                true
            ),
        }),
    };
};
