import React, { ReactElement } from "react";
import { Result, Space, Typography } from "antd";

import { RoutePaths } from "../../Router/RoutesEnum";
import { useHistory } from "react-router";
import { RouteStringWithParam, useQuery } from "../../utils/routing";

export default function UnAuthorised(): ReactElement {
    const history = useHistory();
    const query = useQuery();

    const goToLogin = () => {
        const REFERRING_PAGE: string | null = query.get("referrer");

        history.push(
            RouteStringWithParam(RoutePaths.LOGIN, { referrer: REFERRING_PAGE })
        );
    };

    const Title = () => (
        <>
            <Space direction={"vertical"}>
                <Typography.Text type={"secondary"}>
                    {"You need to login to continue"}
                </Typography.Text>

                <Typography.Link onClick={goToLogin}>
                    {"Click here to back to the login screen."}
                </Typography.Link>
            </Space>
        </>
    );
    return <Result status="403" title="403" subTitle={<Title />} />;
}
