import { Modal } from "antd";
import React, { useCallback, useMemo } from "react";
import { InitDataResponseType } from "../POS/@types";
import { UserItem } from "Redux/StateSlices/GroupData";
import { EmptyDiv, StyledInput, StyledButton } from "./SafeTab";

export const ApprovedBySection = ({
    tableData,
    users,
    tableLength,
}: {
    tableData: InitDataResponseType[];
    users: UserItem[];
    tableLength: number;
}) => {
    const fetchUserNameById = useCallback(
        (id: string) => {
            const user = users.find((user) => user.id === id);
            return user?.username ?? "";
        },
        [users]
    );

    const emptyArray = useMemo(() => {
        const tempArray = new Array(tableLength + 1);
        tempArray.fill(0);
        return tempArray;
    }, [tableLength]);

    return (
        <div
            style={{
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                gap: 24,
            }}
        >
            {emptyArray.map((item, key) => {
                if (key === 0)
                    return (
                        <EmptyDiv numberOfItems={emptyArray.length} key={key}>
                            Signed off by
                        </EmptyDiv>
                    );
                return (
                    <StyledInput
                        numberOfItems={emptyArray.length}
                        key={key}
                        autoSize
                        disabled
                        value={
                            tableData[key - 1]?.safe_data?.signed_off_by_user_id
                                ? fetchUserNameById(
                                      tableData[key - 1]?.safe_data
                                          ?.signed_off_by_user_id
                                  )
                                : ""
                        }
                    />
                );
            })}
        </div>
    );
};
export const ApproveButtonSection = ({
    onApprove,
    tableLength,
    tableData,
}: {
    onApprove: (index: number) => void;
    tableLength: number;
    tableData: InitDataResponseType[];
}) => {
    const onButtonPress = (key: number) => {
        Modal.confirm({
            title: "Are you sure you want to sign off on this Cashup?",
            content: "This action cannot be undone",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                onApprove(key);
            },
            centered: true,
            // CENTER THIS
        });
    };

    const emptyArray = useMemo(() => {
        const tempArray = new Array(tableLength + 1);
        tempArray.fill(0);
        return tempArray;
    }, [tableLength]);

    return (
        <div
            style={{
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                gap: 24,
            }}
        >
            {emptyArray.map((item, key) => {
                if (key === 0)
                    return <EmptyDiv numberOfItems={emptyArray.length} key={key} />;
                return (
                    <StyledButton
                        type="primary"
                        key={key}
                        numberOfItems={emptyArray.length}
                        onClick={() => onButtonPress(key - 1)}
                        disabled={
                            tableData[key - 1]?.safe_data?.signed_off_by_user_id !==
                            undefined
                        }
                    >
                        {tableData[key - 1]?.safe_data?.signed_off_by_user_id !==
                        undefined
                            ? "Signed off"
                            : "Sign off"}
                    </StyledButton>
                );
            })}
        </div>
    );
};
