import { AntDFormState, AntDFormStateWithoutSplit, TreeSelectData } from "@types";
import { Collapse, Typography } from "antd";
import React, { useMemo } from "react";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    ExtendedVenueItem,
    GlAccountByType,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import {
    PaymentTransactionInit,
    TransferTransactionInit,
} from "../CashupTabs/POS/@types";
import { extractSubLocation } from "../../../utils/utilities";
import { Field } from "./TransactionForms/SalesCountModalFormV2";
import {
    checkForSublocations,
    TransferModalFormV2,
} from "./TransactionForms/TransferModalFormV2";
import { TransfersTransactionParser } from "./TransactionsFormGaming";
import { appendVenueName } from "./utils";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { PaymentModalFormV2 } from "./TransactionForms/PaymentModalFormV2";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { level2Spacing } from "utils/style-utils";
import {
    initialPaymentSplitData,
    PaymentTransactionParser,
} from "./TransactionsFormPOS";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { ModalFooter } from "Components/Form/ModalFooter";

const { Panel } = Collapse;

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

export type ExtendedTreeData = TreeSelectData & {
    venue?: string;
};
type TransformLocationsArgs = {
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    excludeFloatLocations?: boolean;
    includeOnlySafes?: boolean;
    displayLocationName?: boolean;
    extraConsoleInfo?: string;
};

interface Props {
    onModalClose?: () => void;
    locations: ExtendedLocationItem[];
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    TransferTransactions: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID?: string;
    TransferTransactionsReadOnly: TransferTransactionInit[];
    venuesData: ExtendedVenueItem[];
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    hideTransferTo?: boolean;
    disabled?: boolean;
    TransactionsForFloat?: boolean;
    forceIncludeBank?: boolean;
    PaymentTransactionData: PaymentTransactionInit[];
    onPaymentTransactionSubmission: (data: AntDFormState[]) => void;
    onPaymentTransactionRemove: (data: AntDFormState[]) => void;
}

const TransactionsFormTransfersOfficeComponent: React.FC<Props> = ({
    onModalClose,
    onTransferTransactionSubmission,
    TransferTransactions,
    hierarchicalLocations,
    currentLocationID,
    TransferTransactionsReadOnly,
    venuesData,
    accountsData,
    classesData,
    hideTransferTo,
    disabled,
    PaymentTransactionData,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
    TransactionsForFloat = false,
    forceIncludeBank = true,
}) => {
    const [selectedVenue] = useLocalStorage<string | null>("selectedVenue", null);

    const venueNameById = useMemo(
        () =>
            venuesData?.reduce<{ [venueID: string]: string }>(
                (accumulator, current) => {
                    accumulator[current.venue_id] = current.name;
                    return accumulator;
                },
                {}
            ),
        [venuesData]
    );

    const initialTransfersTransactionsDataWithAppendedFormID = useMemo(
        () =>
            TransferTransactions.length > 0
                ? TransfersTransactionParser(TransferTransactions, currentLocationID)
                : [
                      {
                          formData: [{ name: "From", value: currentLocationID }],
                          formID: Math.random().toString(),
                      },
                  ],
        []
    );

    const initialTransfersReadOnlyTransactionsDataWithAppendedFormID = useMemo(
        () =>
            TransferTransactionsReadOnly.length > 0
                ? TransfersTransactionParser(
                      TransferTransactionsReadOnly,
                      currentLocationID,
                      true
                  )
                : [
                      {
                          formData: [{ name: "From", value: currentLocationID }],
                          formID: Math.random().toString(),
                      },
                  ],
        []
    );

    const tenderAccounts = useMemo(
        () =>
            accountsData.filter(
                (currentAccountItem) => currentAccountItem.payout_tender_type
            ),
        [accountsData]
    );

    const currentVenue = useMemo(() => {
        if (
            selectedVenue &&
            venuesData.find(({ venue_id }) => venue_id === selectedVenue)
        ) {
            return venuesData.find(({ venue_id }) => venue_id === selectedVenue);
        } else {
            return undefined;
        }
    }, [venuesData]);

    // Appends a randomly generated ID to each form to ensure uniqueIDs.
    const initialPaymentTransactionsDataWithAppendedFormID: AntDFormState[] =
        useMemo(
            () =>
                PaymentTransactionData.length > 0
                    ? PaymentTransactionParser(
                          PaymentTransactionData,
                          tenderAccounts
                      )
                    : [
                          {
                              formData: [],
                              formID: Math.random().toString(),
                              SplitData: initialPaymentSplitData(tenderAccounts),
                          },
                      ],
            []
        );

    const createTransformLocationsHelper = (args: TransformLocationsArgs) => {
        const {
            hierarchicalLocations,
            excludeFloatLocations,
            includeOnlySafes,
            displayLocationName,
        } = args;

        const result: ExtendedTreeData[] = [];
        const floatLocations: ExtendedLocationItemWithChildren[] = [];

        const processLocation = (
            location: ExtendedLocationItemWithChildren,
            index: number
        ) => {
            const shouldExclude =
                excludeFloatLocations && location.location_type === 14;

            const shouldInclude = includeOnlySafes
                ? location.name.includes("Safe") ||
                  (forceIncludeBank && location.location_type === 5)
                : true;

            const allowBank = location.location_type === 5;

            const shouldAllow = location.venue === selectedVenue || allowBank;

            if (!shouldExclude && shouldInclude && shouldAllow) {
                if (displayLocationName) {
                    const previousTitle = location.name;
                    result.push({
                        key: index,
                        title: appendVenueName(location, venueNameById),
                        value: location.location_id,
                        children: location.sub_locations
                            ? extractSubLocation(
                                  location.sub_locations,
                                  previousTitle
                              ).sort((subLocationA, subLocationB) =>
                                  subLocationA.title.localeCompare(
                                      subLocationB.title
                                  )
                              )
                            : [],
                        disabled: checkForSublocations(location),
                        venue: location.venue,
                    });
                } else {
                    result.push({
                        key: index,
                        title: appendVenueName(location, venueNameById),
                        value: location.location_id,
                        children: location.sub_locations
                            ? extractSubLocation(location.sub_locations).sort(
                                  (subLocationA, subLocationB) =>
                                      subLocationA.title.localeCompare(
                                          subLocationB.title
                                      )
                              )
                            : [],
                        disabled: checkForSublocations(location),
                        venue: location.venue,
                    });
                }
            } else if (location.location_type === 14) {
                floatLocations.push(location);
            }
        };

        const processFloatLocations = () => {
            if (floatLocations.length !== 0) {
                const floatLocationsChildren = extractSubLocation(
                    floatLocations
                ).sort((subLocationA, subLocationB) =>
                    subLocationA.title.localeCompare(subLocationB.title)
                );

                result.forEach((location) => {
                    if (location.title.includes("Safe")) {
                        const itemsWithMatchingVenueId =
                            floatLocationsChildren.filter(
                                (item) => item.venue === location.venue
                            );
                        location.children = location.children?.concat(
                            itemsWithMatchingVenueId
                        );
                        location.disabled = true;
                    }
                });
            }
        };

        hierarchicalLocations.forEach(processLocation);
        processFloatLocations();

        return result.sort((locationA, locationB) =>
            locationA.title.localeCompare(locationB.title)
        );
    };

    const transformLocations = useMemo(
        () =>
            createTransformLocationsHelper({
                hierarchicalLocations,
                excludeFloatLocations: true,
                displayLocationName: true,
                extraConsoleInfo: "Check me ",
            }),
        [hierarchicalLocations]
    );

    const transformLocationsReadonly = useMemo(
        () =>
            createTransformLocationsHelper({
                hierarchicalLocations,
                excludeFloatLocations: true,
                displayLocationName: true,
                extraConsoleInfo: "Check me ",
            }),
        [hierarchicalLocations]
    );

    const transformToLocation = useMemo(
        () =>
            createTransformLocationsHelper({
                hierarchicalLocations,
                excludeFloatLocations: true,
                displayLocationName: true,
            }),
        [hierarchicalLocations]
    );

    const transformLocationsFromForFloat = useMemo(
        () =>
            createTransformLocationsHelper({
                hierarchicalLocations,
                includeOnlySafes: true,
                displayLocationName: true,
            }),
        [hierarchicalLocations]
    );
    //@ts-ignore
    const transferFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                type: {
                    name: currentLocationID ? "TreeSelectLocked" : "TreeSelect",
                    locations: TransactionsForFloat
                        ? transformLocationsFromForFloat.sort(
                              (locationA, locationB) =>
                                  locationA.title.localeCompare(locationB.title)
                          )
                        : transformLocations.sort((locationA, locationB) =>
                              locationA.title.localeCompare(locationB.title)
                          ),
                    key: currentLocationID,
                },
                colSpan: 8,
            },
            {
                name: "To",
                required: true,
                type: {
                    name: "TreeSelect",
                    locations: transformToLocation.sort((locationA, locationB) =>
                        locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
            { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
        ],
        [
            TransactionsForFloat,
            currentLocationID,
            transformLocations,
            transformLocationsFromForFloat,
            transformToLocation,
        ]
    );
    const transferReadOnlyFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                disabled: true,
                type: {
                    name: "TreeSelect",
                    // Note this needs it's own that just has every possible item in it.
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },

                colSpan: 8,
            },
            {
                name: "To",
                disabled: true,
                required: true,

                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                disabled: true,
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
        ],
        [currentLocationID, transformLocationsReadonly]
    );

    const paymentFields: Field[] = [
        {
            name: "GL Code",
            required: true,
            type: {
                name: "DropdownOrNull",
            },
            colSpan: 8,
        },
        {
            name: "Class",
            type: {
                name: "Dropdown",
                options: classesData.map((currentClass) => ({
                    label: currentClass.name,
                    value: currentClass.class_id,
                })),
            },
            colSpan: 4,
        },
        {
            name: "Description",
            required: true,
            type: {
                name: "Text",
            },
            colSpan: 6,
        },
        {
            name: "Amount",
            required: true,
            type: { name: "Money" },
            colSpan: 4,
            changeGST: true,
        },
        {
            name: "GST",
            type: { name: "GST" },
            colSpan: 3,
        },

        {
            name: "Tender split",
            type: {
                name: "Modal",
            },
            colSpan: 4,
        },
        { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
    ];

    const glAccountGroupsByType = currentVenue?.venueglaccount_set.reduce<
        GlAccountByType[]
    >((acc, currentGlAccount, index) => {
        const matchGroup = acc.find(
            (acc) => acc.type === currentGlAccount.gl_account.type
        );
        if (index === 0 || !matchGroup) {
            acc.push({
                type: currentGlAccount.gl_account.type,
                glAccounts: [currentGlAccount.gl_account],
            });
        } else {
            matchGroup.glAccounts.push(currentGlAccount.gl_account);
        }
        return acc;
    }, []);

    return (
        <StyledFormContainer>
            <Collapse accordion expandIconPosition="end">
                <Panel
                    header={<StyledPanelHeader>Transfers From</StyledPanelHeader>}
                    key="1"
                >
                    <TransferModalFormV2
                        currentLocationID={currentLocationID}
                        fields={transferFields}
                        initialDataForForms={
                            initialTransfersTransactionsDataWithAppendedFormID
                        }
                        onChange={onTransferTransactionSubmission}
                        disabled={disabled}
                    />
                </Panel>
                {hideTransferTo !== true && (
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="2"
                    >
                        <TransferModalFormV2
                            fields={transferReadOnlyFields}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                        />
                    </Panel>
                )}

                <Panel
                    header={<StyledPanelHeader>Payments</StyledPanelHeader>}
                    key="3"
                >
                    <Typography
                        style={{ color: "#626E84", marginBottom: level2Spacing }}
                    >
                        Payments will include petty cash and other expenses
                    </Typography>

                    <PaymentModalFormV2
                        fields={paymentFields}
                        onModalClose={() => null}
                        initialDataForForms={
                            initialPaymentTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onSubmit={onPaymentTransactionSubmission}
                        onRemove={onPaymentTransactionRemove}
                        currentVenue={currentVenue}
                        glAccountsByType={glAccountGroupsByType!}
                    />
                </Panel>
            </Collapse>
            <ModalFooter
                onClose={onModalClose}
                onSave={onModalClose}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
        </StyledFormContainer>
    );
};

export const TransactionsFormTransfersOffice = React.memo(
    TransactionsFormTransfersOfficeComponent
);
