import { BASE_URL } from "@Constants";
import { getAuthTokens } from "utils/auth0/auth0API";

interface CreateShiftReportPromptRecordPayload {
    date: string;
    body: string;
    promptId: string;
}

interface UpdateShiftReportPromptRecordPayload {
    body: string;
    promptRecordId: string;
}
export class ShiftReportPromptRecordService {
    static async create(payload: CreateShiftReportPromptRecordPayload) {
        const data = await getAuthTokens();
        const access_token = data?.access_token;
        if (!access_token) {
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({ ...payload, prompt: payload.promptId }),
        };

        const url = `${BASE_URL}/v1/shiftreport/record`;
        return fetch(url, requestOptions).then((r) => r.json());
    }

    static async update(payload: UpdateShiftReportPromptRecordPayload) {
        const data = await getAuthTokens();
        const access_token = data?.access_token;
        if (!access_token) {
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);
        myHeaders.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "PUT",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({ body: payload.body }),
        };

        const url = `${BASE_URL}/v1/shiftreport/record/${payload.promptRecordId}`;
        return fetch(url, requestOptions).then((r) => r.json());
    }
}
