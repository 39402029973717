import styled, { css } from "styled-components";

interface ColumnWidthDistribution {
    numberOfItems: number;
    borderRight?: boolean;
    centerText?: boolean;
    evenDistribution?: boolean;
}

const columnWidthDistributionCSS = css<ColumnWidthDistribution>`
    padding: 4px 12px;
    flex-basis: calc(
        ${({ numberOfItems }) => 81 / (numberOfItems - 3)}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );
    width: calc(
        ${({ numberOfItems }) => 81 / (numberOfItems - 3)}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );

    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}

    &:first-child {
        flex-basis: 7%;
        width: 7%;
    }

    &:nth-last-child(-n + 2) {
        flex-basis: 6%;
        width: 6%;
    }
`;

const columnEvenWidthDistributionCSS = css<ColumnWidthDistribution>`
    padding: 4px 12px;
    flex-basis: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );
    width: calc(
        ${({ numberOfItems }) => 100 / numberOfItems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );

    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}
`;

const truncateTextCSS = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledTableHeader = styled.div`
    display: flex;
    background: #f6f8fa;
    color: #1e2024;
    font-size: 12px;
    line-height: 16px;
    height: 36px;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid #dadfe7;
`;

export const StyledTableFooter = styled.div`
    display: flex;
    background: white;
    color: #1e2024;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    height: 36px;
    align-items: center;
    border-top: 1px solid #dadfe7;
`;

export const StyledTableCell = styled.div<ColumnWidthDistribution>`
    ${({ evenDistribution }) =>
        evenDistribution
            ? columnEvenWidthDistributionCSS
            : columnWidthDistributionCSS}
    word-break: break-word;
    ${({ borderRight }) =>
        borderRight
            ? `
                border-right: 2px solid #dadfe7;
                padding-top: 0;
                padding-bottom: 0;
                height: 100%;
                display: flex;
                align-items: center;
            `
            : ""}
`;

export const StyledTableRow = styled.div`
    display: flex;
    font-size: 12px;
    align-items: center;
    height: 50px;
`;

export const StyledTable = styled.div`
    border: 1px solid #dadfe7;
    border-radius: 2px;
    min-width: 1200px;
`;

export const StyledCell = styled.div<{
    readOnly: boolean;
    highlightBorder: boolean;
    titleCell: boolean;
    numberOfItems: number;
    borderRight?: boolean;
    whiteBackground?: boolean;
    evenDistribution?: boolean;
}>`
    padding: 6px;
    ${({ evenDistribution }) =>
        evenDistribution
            ? columnEvenWidthDistributionCSS
            : columnWidthDistributionCSS}

    ${({ readOnly }) =>
        readOnly
            ? `
background: #F9FAFB;
height: 100%;
color: #353940;
display: flex;
align-items: center;
`
            : ""}

    ${({ titleCell }) => (titleCell ? `background: white;` : "")}

${({ borderRight }) =>
        borderRight
            ? `
border-right: 2px solid #dadfe7;
padding-top: 0;
padding-bottom: 0;
height: 100%;
display: flex;
align-items: center;
`
            : ""}
    ${({ highlightBorder }) =>
        highlightBorder
            ? `border: 1px solid #1a81c7;
box-shadow: 0 0 1px 2px #dbe7fa;`
            : ""}
`;

export const StyledCellValue = styled.div<{
    highlightBorder: boolean;
    readOnly: boolean;
    titleCell: boolean;
    noPaddingLeft: boolean;
    errorHighlighting?: boolean;
    isVariance?: boolean;
    isCellValueValid?: boolean;
}>`
    background: #f9fafb;
    border: 1px solid #dadfe7;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: ${({ noPaddingLeft }) => (noPaddingLeft ? "0px" : "6px")};
    padding-right: 8px;
    width: 100%;
    position: relative;

    ${({ errorHighlighting }) =>
        errorHighlighting &&
        `border: 1px solid #FF5C5C;
        box-shadow: 0 0 1px 2px #FF8A8A;
    `}
    ${({ isCellValueValid }) =>
        isCellValueValid !== undefined &&
        !isCellValueValid &&
        `animation: borderanimation 5s linear;
    @keyframes borderanimation {
        0% {border: 1px solid #FF5C5C;
            box-shadow: 0 0 1px 2px #FF8A8A;}
        50% {
            border: 1px solid #FF5C5C;
            box-shadow: 0 0 1px 2px #FF8A8A;
        }
        100% {border: 1px solid #dadfe7;
        box-shadow:none;}
    }
`}

    ${({ highlightBorder }) =>
        highlightBorder
            ? `border: 1px solid #1a81c7;
                box-shadow: 0 0 1px 2px #dbe7fa;`
            : ""}

    ${({ readOnly }) =>
        readOnly
            ? `
                border: none;
                border-radius: 0;
                `
            : ""}
    ${({ isVariance }) => (isVariance ? `color: #FF5C5C;` : "")}
    ${({ titleCell }) => (titleCell ? `background: white` : "")}

    ${({ titleCell }) =>
        !titleCell
            ? `   
            & > span {
                ${truncateTextCSS}
            }`
            : ""}
`;

export const StyledSpan = styled.span`
    opacity: 0;
    width: auto;
    background-color: rgba(255, 255, 255, 1);
    color: #ff5c5c;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -40%;
    animation: tooltipanimation 5s linear;
    @keyframes tooltipanimation {
        0% {
            opacity: 1;
        }
        %50 {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

export const StyledInput = styled.input`
    width: 100%;
    border: 1px solid #1a81c7;
    box-shadow: 0 0 1px 2px #dbe7fa;
    border-radius: 4px;
    height: 32px;

    &:focus {
        outline: none;
    }
`;

export const StyledModalEditingContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > span:first-child {
        ${truncateTextCSS}
    }
`;
