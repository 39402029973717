import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetUsersQuery } from "../../Redux/StateSlices/GroupData/UsersAPI";
import { ExtendedUserType } from "../../@types/reduxStore";
import { getAuthTokens } from "utils/auth0/auth0API";

export const useGetCurrentUser = (): {
    user: ExtendedUserType | null;
    isLoading: boolean;
    isError: boolean;
} => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<null | ExtendedUserType>(null);

    const {
        data,
        isLoading: UsersLoading,
        refetch,
        isError: isGetUsersError,
    } = useGetUsersQuery(null);

    const getTokens = useCallback(async () => {
        const data = await getAuthTokens();

        return data?.email;
    }, []);

    useEffect(() => {
        getTokens()
            .then((auth0User) => {
                if (UsersLoading === false && data) {
                    if (auth0User) {
                        const CurrentUser = data.find(
                            (user) =>
                                user.email?.toLowerCase() === auth0User.toLowerCase()
                        );
                        if (CurrentUser) {
                            setUser(CurrentUser);
                            setIsLoading(false);
                        } else {
                            // For events where the Endpoints returns the default user dataset (rather than the authenticated user dataset)
                            refetch();
                            // TODO: Add a retry tolerance (5 tries then put this hook into an error state)
                        }
                    }
                }
            })
            .catch((error) => {
                console.log({ error });
            });
    }, [data, UsersLoading, refetch, getTokens]);

    const isError = isGetUsersError;

    return useMemo(() => ({ user, isLoading, isError }), [user, isLoading, isError]);
};
