import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { FieldData } from "@interfaces";
import { AntDFormStateWithSplitFlag } from "@types";
import { Button, Col, Form, Input, Radio, Row, Select } from "antd";
import { StyledAntDInput } from "Components/MoneyInput/MoneyInputV2";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import styled from "styled-components";
import { ValueLabelOption } from "types";
import {
    clearLastFormFieldIfEmpty,
    convertFormDataToKeyValueWithTouched,
    extractGSTValue,
} from "utils/utilities";
import { Field } from "./SalesCountModalFormV2";
import { updateSpecificFormField } from "../utils";
import { useForm } from "antd/lib/form/Form";
import { MoneyFormField } from "../Forms/Components/MoneyFormFields";
import { StyledEmptyLabel } from "./PaymentModalFormV2";
import { SplitModalField } from "Components/SplitModalField/SplitModalField";
import { extractFieldName } from "Pages/CashupHome/CashupTabs/POS/utils";

export const StyledTitleRow = styled(Row)`
    & > div {
        font-size: 12px;
        font-weight: bold;
        color: #626e84;
    }
`;

export const StyledInput = styled(Input)`
    border-radius: 4px;
    background: #f9fafb;
    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }
`;

export const StyledRadioGroups = styled(Radio.Group)`
    width: 100%;

    .ant-radio-button-wrapper {
        box-sizing: border-box;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #1a81c7 !important;
        background: #fffff;
        width: 50%;
        color: #1a81c7;
        font-size: 12px;
        padding: 0px;
        text-align: center;

        &:nth-child(2) {
            border-radius: 0px 4px 4px 0px;
        }
    }

    .ant-radio-button-wrapper-checked {
        background: #dbe7fa !important;
    }
`;

const StyledRow = styled(Row)`
    .ant-form-item {
        margin-bottom: 4px;
    }
`;

const StyledSelect = styled(Select)<{ disabled: boolean }>`
    ${(props) =>
        props.disabled === false &&
        `.ant-select-selector {
        color: #353940 !important;
        background: #f9fafb !important;
        border: 1px solid #dadfe7 !important;
        border-radius: 4px !important;
    }`}

    ${(props) =>
        props.disabled === true &&
        `.ant-select-selector {
            border: 1px solid #dadfe7 !important;
            border-radius: 4px !important;
        }`}
`;

const CustomFormField = ({
    field: { type, changeGST, ...rest },
    remove,
    openModal,
    onAmountChange,
    DropdownLazyOptions,
    disabled,
    addNewRow,
    isLastItem,
}: {
    field: Field;
    remove?: () => void;
    openModal?: () => void;
    onAmountChange?: (data: any) => void;
    DropdownLazyOptions: ValueLabelOption[];
    disabled: boolean;
    addNewRow: () => void;
    isLastItem: boolean;
}) => {
    switch (type.name) {
        case "Money":
            return (
                <MoneyFormField
                    {...rest}
                    disabled={disabled}
                    onChange={changeGST ? onAmountChange : undefined}
                />
            );
        case "GST":
            return <StyledAntDInput prefix="$" disabled={disabled} />;
        case "Text":
            return <StyledInput {...rest} disabled={disabled} />;
        case "Dropdown":
            return (
                <StyledSelect disabled={disabled}>
                    {type.options.map((currentOption, index) => (
                        <Select.Option value={currentOption.value} key={index}>
                            {currentOption.label}
                        </Select.Option>
                    ))}
                </StyledSelect>
            );
        // return <DropdownSelector options={type.options} {...rest} />;
        case "Modal":
            return <SplitModalField openModal={openModal} disabled={disabled} />;
        case "DropdownLazy":
            return (
                <StyledSelect disabled={disabled}>
                    {DropdownLazyOptions.map((currentOption, index) => (
                        <Select.Option value={currentOption.value} key={index}>
                            {currentOption.label}
                        </Select.Option>
                    ))}
                </StyledSelect>
            );
        case "Radio":
            return (
                <StyledRadioGroups
                    options={type.options}
                    optionType="button"
                    disabled={disabled}
                    {...rest}
                />
            );

        case "Button":
            return (
                <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={<PlusOutlined style={{ color: "#FFF" }} />}
                        disabled={!isLastItem}
                        onClick={addNewRow}
                    />
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={<DeleteOutlined style={{ color: "#FFF" }} />}
                        onClick={remove}
                    />
                </div>
            );

        default:
            throw new Error("This should be impossible");
    }
};

const DepositInFormComponent = ({
    formIndex,
    fields,
    removeForm,
    tenderAccounts,
    formState,
    onFormChange,
    addNewRow,
    isLastItem,
}: {
    formIndex: number;
    fields: Field[];
    removeForm: () => void;
    tenderAccounts: ExtendedAccountItem[];
    formState: AntDFormStateWithSplitFlag;
    onFormChange: (data: FieldData[]) => void;

    addNewRow: () => void;
    isLastItem: boolean;
}) => {
    const [form] = useForm();
    const addNewRowWithValidation = () => {
        form.validateFields()
            .then(() => {
                addNewRow();
            })
            .catch(() => {
                return;
            });
    };
    return (
        <>
            <Form
                form={form}
                layout="vertical"
                name={`DepositInForm-${formIndex}`}
                fields={formState.formData}
                onFieldsChange={(_, allFields) => {
                    onFormChange(allFields);
                }}
                style={{ width: "100%" }}
            >
                <StyledRow gutter={8}>
                    {fields.map((field) => (
                        <Col span={field.colSpan} key={field.key}>
                            <Form.Item
                                key={field.key}
                                label={
                                    formIndex === 0 ? (
                                        field.isButton ? (
                                            <StyledEmptyLabel />
                                        ) : (
                                            field.name
                                        )
                                    ) : null
                                }
                                name={field.name}
                                rules={[
                                    {
                                        required: field.required,
                                    },
                                ]}
                            >
                                {CustomFormField({
                                    field,
                                    remove: removeForm,
                                    DropdownLazyOptions: tenderAccounts.map(
                                        (currentTender) => {
                                            return {
                                                value: currentTender.account_id,
                                                label: currentTender.name,
                                            };
                                        }
                                    ),
                                    disabled: false,
                                    addNewRow: addNewRowWithValidation,
                                    isLastItem,
                                })}
                            </Form.Item>
                        </Col>
                    ))}
                </StyledRow>
            </Form>
        </>
    );
};
const DepositInForm = React.memo(DepositInFormComponent);

interface Props {
    fields: Field[];
    initialDataForForms: AntDFormStateWithSplitFlag[];
    tenderAccounts: ExtendedAccountItem[];
    onChange: (data: AntDFormStateWithSplitFlag[]) => void;
}

export const isDepositValid = (formsData: FieldData[]) => {
    const excludedFields = ["Button"];
    let valid = true;
    if (formsData.length === 0) return false;
    formsData.forEach((formData) => {
        if (
            !excludedFields.includes(extractFieldName(formData)) &&
            formData.value === undefined
        ) {
            valid = false;
        } else if (
            (Array.isArray(formData.name) && formData.name[0] === "Amount") ||
            (!Array.isArray(formData.name) && formData.name === "Amount")
        ) {
            if (isNaN(Number(formData.value))) {
                valid = false;
            }
        }
    });
    return valid;
};

export const DepositInModalFormV2: React.FC<Props> = ({
    fields,
    onChange,
    initialDataForForms,
    tenderAccounts,
}) => {
    const [formsData, setFormsData] = useState(initialDataForForms);

    const addNewRow = () => {
        const formDataDeepClone = cloneDeep(formsData);

        formDataDeepClone.push({
            formData: [],
            formID: Math.random().toString(),
        });
        setFormsData(formDataDeepClone);
        onChange(formDataDeepClone);
    };

    /**
     * Removed a form at a specified index
     * @param formIndex
     */
    const removeForm = (formIndex: number) => {
        const formDataDeepClone = cloneDeep(formsData);
        formDataDeepClone.splice(formIndex, 1);
        const currentFormFieldsData = clearLastFormFieldIfEmpty(
            formDataDeepClone,
            false,
            true
        ) as AntDFormStateWithSplitFlag[];

        if (currentFormFieldsData.length === 0) {
            setFormsData([
                {
                    formData: [],
                    formID: Math.random().toString(),
                },
            ]);
        } else {
            setFormsData(formDataDeepClone);
        }
        onChange(currentFormFieldsData);
    };

    /**
     * Triggered on "onFieldsChange" form events.
     * @param rowIndex
     * @param formData
     */
    const updateFormState = (rowIndex: number, formData: FieldData[]) => {
        const formDataDeepClone = cloneDeep(formsData);
        const CurrentItem = formDataDeepClone[rowIndex];

        formDataDeepClone.splice(rowIndex, 1, {
            formData: formData,
            formID: CurrentItem.formID,
        });
        setFormsData(formDataDeepClone);
        if (isDepositValid(formData)) {
            onChange(formDataDeepClone);
        }
    };

    return (
        <>
            <StyledRow>
                {formsData.map((currentFormData, index) => (
                    <DepositInForm
                        key={currentFormData.formID}
                        formIndex={index}
                        removeForm={() => {
                            removeForm(index);
                        }}
                        fields={fields}
                        tenderAccounts={tenderAccounts}
                        formState={currentFormData}
                        onFormChange={(data) => updateFormState(index, data)}
                        addNewRow={addNewRow}
                        isLastItem={index == formsData.length - 1}
                    />
                ))}
            </StyledRow>
        </>
    );
};
