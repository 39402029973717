import { accountsAPI } from "Redux/StateSlices/GroupData/AccountsAPI";
import { cashupsAPI } from "Redux/StateSlices/GroupData/CashupsAPI";
import { classesAPI } from "Redux/StateSlices/GroupData/ClassesAPI";
import { departmentsAPI } from "Redux/StateSlices/GroupData/DepartmentAPI";
import { entitiesAPI } from "Redux/StateSlices/GroupData/EntitiesAPI";
import { locationsAPI } from "Redux/StateSlices/GroupData/LocationsAPI";
import { tasksAPI } from "Redux/StateSlices/GroupData/TasksAPI";
import { transactionsAPI } from "Redux/StateSlices/GroupData/TransactionsAPI";
import { usersAPI } from "Redux/StateSlices/GroupData/UsersAPI";
import { venuesAPI } from "Redux/StateSlices/GroupData/VenuesAPI";
import { groupdataAPI } from "Redux/StateSlices/GroupDataAPI";
import { resetVenue } from "Redux/StateSlices/ShiftReportState";
import { store } from "Redux/store";

export const resetCache = () => {
    store.dispatch(transactionsAPI.util.resetApiState());
    store.dispatch(usersAPI.util.resetApiState());
    store.dispatch(cashupsAPI.util.resetApiState());
    store.dispatch(groupdataAPI.util.resetApiState());
    store.dispatch(locationsAPI.util.resetApiState());
    store.dispatch(tasksAPI.util.resetApiState());
    store.dispatch(classesAPI.util.resetApiState());
    store.dispatch(venuesAPI.util.resetApiState());
    store.dispatch(accountsAPI.util.resetApiState());
    store.dispatch(entitiesAPI.util.resetApiState());
    store.dispatch(departmentsAPI.util.resetApiState());
    store.dispatch(resetVenue());
    // store.dispatch(pusherAPI.util.resetApiState());
    localStorage.clear();
};
