// import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";

export type QTCDate = Dayjs;
export interface QTCRange {
    start: QTCDate;
    end: QTCDate;
}

export const dayjsFormat: { [description: string]: dayjs.FormatObject } = {
    default: { format: "YYYY-MM-DD" },
    dayMonthYearSeparateByDash: { format: "DD-MM-YYYY" },
    dateWithWeekDay: { format: "ddd D MMMM YY" },
    dayMonthYearSlash: { format: "DD/MM/YYYY" },
    dayMonthYearSlashHourMinute: { format: "DD/MM/YYYY HH:mm" },
};

export const toQTCDate = (date: string | QTCDate, format?: dayjs.FormatObject) =>
    dayjs(date, format?.format);

export const toQTCRange = (
    startDate: string | QTCDate,
    endDate: string | QTCDate,
    format?: dayjs.FormatObject
) => {
    return { start: dayjs(startDate, format), end: dayjs(endDate, format) };
};

export const formatDateRange = (
    startDate: string | QTCDate,
    endDate: string | QTCDate,
    format: string
) => {
    return { start: dayjs(startDate).format(format), end: dayjs(endDate).format() };
};

export const formatDateStringForDisplay = (
    date: string,
    format = dayjsFormat.default
): string => dayjs(date).format(format.format);

export const formatForBackend = (date: string | QTCDate) => {
    if (typeof date === "string")
        return toQTCDate(date).format(dayjsFormat.default.format);

    return date.format(dayjsFormat.default.format);
};

export const generateDatesInRange = ({ start, end }: QTCRange): QTCDate[] => {
    const result: QTCDate[] = [];

    const currentDate = start.clone();

    while (currentDate.isSame(end) || currentDate.isBefore(end)) {
        result.push(currentDate.clone());
        currentDate.add(1, "day");
    }

    return result;
};
