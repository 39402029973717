import { Entity } from "@generated/models/EntityData";
import { KeyValuePair } from "@types";
import {
    CashCountV2Item,
    DepositInV2Item,
    DepositOutV2Item,
    EftposCountV2Item,
    PaymentV2Item,
    SaleV2Item,
    TransferV2Item,
} from "../../../../../@generated";

export enum TenderTypes {
    CASH = "Cash",
    EFTPOS = "Eftpos",
    VISA = "Visa",
    AMEX = "AMEX",
    DEPOSITS = "Deposits",
    DIRECT_DEPOSIT = "Direct Deposit",
    EXPENSES = "Expenses",
    LINK_JACKPOT = "Link Jackpot",
    WASTAGE = "Wastage",
    MEU = "Me&U",
    SALES = "Sales",
    UNCLAIMED_WINS = "Unclaimed Wins",
    UNDERS_AND_OVERS = "Unders and Overs",
    CHEQUE = "Cheque",
    BAR_PROMOS = "Bar Promos",
    FOOD_PROMOS = "Food Promos",
    GAMING_PROMOS = "Gaming Promos",
    POINTS_PAY = "Points Pay",
    GIFT_CARD = "Gift Card",
}

export enum ClassTypes {
    FOOD = "Food",
    BEVERAGE = "Beverage",
    GAMING = "Gaming",
    VISA = "Visa",
}
export type DepositInTransactionInit = DepositInV2Item & { transaction_id?: string };
export type DepositOutTransactionInit = DepositOutV2Item & {
    transaction_id?: string;
};
export type TransferTransactionInit = TransferV2Item & { transaction_id?: string };
export type CashCountTransactionInit = CashCountV2Item & { transaction_id?: string };
export type PaymentTransactionInit = PaymentV2Item & { transaction_id?: string } & {
    entity?: Entity;
};
export type EftposCountTransactionInit = EftposCountV2Item & {
    transaction_id?: string;
};
export type SalesCountTransactionInit = {
    memo: string;
    location_id: string;
    turnover: number;
    wins: number;
    unclaimed: number;
    expected_sales_balance: number;
    action_account_id?: string;
} & { transaction_id?: string };

export interface ATMData {
    refills?: number;
    refillsDenominations?: KeyValuePair[];
    withdrawls?: number;
    withdrawalsDenominations?: KeyValuePair[];
    crt_machine_counts?: GamingMachineCount;
}
export interface CCUData {
    ccu_payout?: number;
    refills?: number;
    refillsDenominations?: KeyValuePair[];
}
export type CashupTextStatus =
    | null
    | "calculating"
    | "invalid or no balances in cashup"
    | string;

export type TABData = {
    bet_types?: {
        s_bet: number;
        trackside_sales: number;
    };
    money_in: number;
    money_out: number;
    commission: number;
    costs: number;
    paid_to_tab: number;
    commission_after: number;
};

export type SafeData = {
    denominations: { [denomination: string | number]: number };
    total_denominations: number;
    denominations_comments: {
        content: string;
        mentions: string[];
    };
    floats: {
        cash_count: number;
        location_id: string;
    }[];
    floats_comments: {
        content: string;
        mentions: string[];
    };
    variance_comments: {
        content: string;
        mentions: string[];
    };
    sign_off: boolean;
    // Note: signed_off_by_user_id is a read-only field, do not adjust expecting results
    // This also locks the cashup
    signed_off_by_user_id: string;

    float_locations?: Array<{
        name: string;
        balance: number;
        location_id: string;
    }>;
    budget?: number;
    total?: number;
};

export type InitDataResponseType = {
    // sales_data: SaleV2Item & { transaction_id?: string };
    pos_data: SaleV2Item & { transaction_id?: string; userHasChanged?: boolean };
    deposit_out_transactions: DepositOutTransactionInit[];
    deposit_in_transactions: DepositInTransactionInit[];
    user_id: string;
    transfer_transactions: TransferTransactionInit[];
    transfer_transactions_to: TransferTransactionInit[];
    cash_count: CashCountTransactionInit & {
        actualDenominations?: KeyValuePair[];
    };
    cashup_id: string;
    payment_transactions: PaymentTransactionInit[];
    status: CashupTextStatus;
    eftpos_count_transactions: EftposCountTransactionInit[];
    tender_count_transactions: [];
    tito_transactions: [];
    gaming_data: GameData;
    tab_keno_data: {
        account_split: {
            amount: number;
            account_id: string;
        }[];
        amount: number;
        tax_amount?: number;
        userHasChanged?: boolean;
    };
    atm_data?: ATMData;
    ccu_data?: CCUData;
    float_data: {}; // TODO
    sales_count_transactions: SalesCountTransactionInit[];
    sales_balance: number | undefined;
    eftpos_balance: number | undefined;
    keno_data: {
        cash_pays: number;
        commission: number;
        commission_after: number;
        heads_tails: number;
        keno_bet: number;
        magic_millions: number;
        maintainence: number;
        stationary: number;
        total_charges: number;
        paid_to_keno: number;
    };
    wagering_data: {
        sales_total: number;
        maintenance?: number;
        commission: number;
    };
    tab_data: TABData;
    total_variance: number;
    float_value: number;
    tender_total: number;
    safe_data: SafeData;
    sales_total: number;
};

export type PosTotal = {
    [locationID: string]: PosTotalData;
};

export type PosTotalData = {
    class_split: {
        Food: number;
        Beverage: number;
        Other: number;
    };
    account_split: {
        Cash: number;
        Eftpos: number;
        Visa: number;
        Other: number;
    };
    transactions: number;
    sales_total: string;
    tender_total: string;
    cash_count_actual: string;
    cash_count_cash_variance: string;
    total_variance: string;
    location_float_value: number;
};

export type PosTotalProcessed = {
    float: number;
    food: number;
    beverage: number;
    other_classes: number;
    sales_total: number;
    eftpos: number;
    visa: number;
    other_tenders: number;
    cash: number;
    tenders_total: number;
    transactions: number;
    total_variance: number;
    cash_count: number;
    cash_variance: number;
};

export type GamingMachineCount = {
    cash_box_read?: number;
    cash_count?: number;
    variance?: number;
};

export type GameData = {
    turnover?: number;
    total_wins?: number;
    unclaimed_tickets?: number;
    jackpot?: number;
    net_profit?: number;
    cash_box?: number;
    tickets_in?: number;
    gaming_machine_counts?: GamingMachineCount[];
};
