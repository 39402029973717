import React, { FC } from "react";
import { StyledTableCell, StyledTableHeader } from "./Table.style";

export type TableConfigs = Array<{
    columnTitle: string;
    separateColumn?: boolean;
    hideTotal?: boolean;
    calculateTotal?: () => number;
}>;

interface Props {
    tableConfigs: TableConfigs;
    evenDistribution?: boolean;
}

export const TableHeader: FC<Props> = ({ tableConfigs, evenDistribution }) => {
    return (
        <StyledTableHeader>
            {tableConfigs.map(({ columnTitle }, i) => (
                <StyledTableCell
                    numberOfItems={tableConfigs.length}
                    key={columnTitle}
                    borderRight={tableConfigs[i]?.separateColumn ?? false}
                    evenDistribution={evenDistribution ?? false}
                >
                    <span data-testid={`${columnTitle}-header`}>{columnTitle}</span>
                </StyledTableCell>
            ))}
        </StyledTableHeader>
    );
};
