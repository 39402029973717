import { AccountItemSplit } from "@generated";
import {
    AntDFormState,
    AntDFormStateEftposCountOnly,
    AntDFormStateWithoutSplit,
    AntDFormStateWithSplitFlag,
} from "@types";
import { Collapse, Typography } from "antd";
import { ModalFooter } from "Components/Form/ModalFooter";
import React, { useCallback, useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import {
    ExtendedVenueItem,
    GlAccountByType,
} from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import { level2Spacing, level3Spacing } from "utils/style-utils";
import { extractGSTValue, extractSubLocation } from "utils/utilities";
import {
    DepositInTransactionInit,
    DepositOutTransactionInit,
    EftposCountTransactionInit,
    PaymentTransactionInit,
    TransferTransactionInit,
} from "../CashupTabs/POS/@types";
import { DepositInModalFormV2 } from "./TransactionForms/DepositInModalFormV2";
import { DepositOutModalFormV2 } from "./TransactionForms/DepositOutModalFormV2";
import { PaymentModalFormV2 } from "./TransactionForms/PaymentModalFormV2";
import { Field } from "./TransactionForms/SalesCountModalFormV2";
import {
    checkForSublocations,
    TransferModalFormV2,
} from "./TransactionForms/TransferModalFormV2";
import { TransfersTransactionParser } from "./TransactionsFormGaming";
import { appendVenueName } from "./utils";
import "./TransactionsFormPOS.css";
import { EntityData } from "@generated/models/EntityData";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { ExtendedTreeData } from "./TransactionsFormTransfersOffice";
import { useLocalStorage } from "@hooks/useLocalStorage";

const { Panel } = Collapse;

interface Props {
    row: number;
    col?: number; // TODO Remove (depreciated)
    onModalClose?: () => void;
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    onPaymentTransactionSubmission: (data: AntDFormState[]) => void;
    onPaymentTransactionRemove: (data: AntDFormState[]) => void;
    onDepositInTransactionSubmission: (data: AntDFormStateWithSplitFlag[]) => void;
    onDepositOutTransactionSubmission: (data: AntDFormStateWithSplitFlag[]) => void;
    onEftposCountTransactionSubmission: (
        data: AntDFormStateEftposCountOnly[]
    ) => void;
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    PaymentTransactionData: PaymentTransactionInit[];
    TransferTransactionsReadOnly: TransferTransactionInit[];

    DepositInTransactionData: DepositInTransactionInit[];
    DepositOutTransactionData: DepositOutTransactionInit[];
    EftposBalance: number | undefined;
    EftposCountTransactions: EftposCountTransactionInit[];
    locations: ExtendedLocationItem[];
    TransferTransactions: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID: string;
    venuesData: ExtendedVenueItem[];
    eftposErrorHighlighter?: boolean;
}

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

const depositInFields: Field[] = [
    {
        name: "Amount",
        required: true,
        type: { name: "Money" },
        colSpan: 6,
    },
    {
        name: "Note",
        type: { name: "Text" },
        required: true,
        colSpan: 10,
    },
    {
        name: "Tender split",
        required: true,
        type: {
            name: "DropdownLazy",
        },
        colSpan: 6,
    },
    { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
];

const depositOutFields: Field[] = [
    {
        name: "Amount",
        required: true,
        type: { name: "Money" },
        colSpan: 6,
    },
    {
        name: "Note",
        type: { name: "Text" },
        required: true,
        colSpan: 10,
    },
    {
        name: "Tender split",
        required: true,
        type: {
            name: "DropdownLazy",
        },
        colSpan: 6,
    },
    { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
];

export const parseStringToCapitalStart = (
    stringToProcess: string,
    stringToBeReplaced: string,
    stringToReplace: string
) => {
    if (!stringToProcess.trim()) throw new Error("string can't be empty");
    return stringToProcess
        .toLowerCase()
        .split(stringToBeReplaced)
        .map(
            (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
        )
        .join(stringToReplace);
};

export const entityDataParser = (entityData: EntityData | undefined) => {
    if (entityData === undefined) return [];
    const result = Object.keys(entityData).map((key) => ({
        name: key,
        value: entityData[key as keyof EntityData],
    }));
    return result;
};

const accountSplitDataParser = (
    account_split: AccountItemSplit[] | undefined,
    tenderAccounts: ExtendedAccountItem[]
) => {
    let chequeNumber: string | undefined = undefined;

    if (account_split === undefined) return [];
    const result = account_split.map((currentTenderItem) => {
        if (currentTenderItem.cheque_no) {
            chequeNumber = currentTenderItem.cheque_no;
        }
        return {
            name: tenderAccounts.find(
                (localTenderItem) =>
                    localTenderItem.account_id === currentTenderItem.account_id
            )!.name,
            value: currentTenderItem.amount,
        };
    });
    if (chequeNumber !== undefined) {
        result.push({
            name: "cheque_no",
            value: chequeNumber,
        });
    }
    return result;
};

export const PaymentTransactionParser = (
    PaymentTransactions: PaymentTransactionInit[],
    tenderAccounts: ExtendedAccountItem[]
): AntDFormState[] => {
    const ParsedPaymentTransactions: AntDFormState[] = PaymentTransactions.map(
        (currentPaymentTransaction) => {
            return {
                formData: [
                    {
                        name: "GL Code",
                        value: currentPaymentTransaction.gl_account_id,
                    },
                    {
                        name: "Description",
                        value: currentPaymentTransaction.memo,
                    },
                    {
                        name: "Class",
                        value: currentPaymentTransaction.class_id,
                    },
                    {
                        name: "Amount",
                        value: currentPaymentTransaction.amount?.toString(),
                    },
                    {
                        name: "GST",
                        value:
                            currentPaymentTransaction.tax_amount?.toString() ??
                            extractGSTValue(
                                currentPaymentTransaction.amount
                            ).toString(),
                    },
                ],
                formID: Math.random().toString(),
                SplitData: accountSplitDataParser(
                    currentPaymentTransaction.account_split,
                    tenderAccounts
                ),
            };
        }
    );
    ParsedPaymentTransactions.push({
        formData: [],
        formID: Math.random().toString(),
        SplitData: initialPaymentSplitData(tenderAccounts),
    });
    return ParsedPaymentTransactions;
};

const DepositInTransactionParse = (
    DepositInTransactions: DepositInTransactionInit[]
) => {
    const ParsedDepositInTransactions: AntDFormStateWithSplitFlag[] =
        DepositInTransactions.map((currentDepositInTransaction) => {
            return {
                formData: [
                    { name: "Amount", value: currentDepositInTransaction.amount },
                    { name: "Note", value: currentDepositInTransaction.memo },
                    {
                        name: "Tender split",
                        value: currentDepositInTransaction.account_split![0]
                            .account_id,
                    },
                ],
                formID: Math.random().toString(),
            };
        });
    ParsedDepositInTransactions.push({
        formData: [],
        formID: Math.random().toString(),
    });
    return ParsedDepositInTransactions;
};

const DepositOutTransactionParse = (
    DepositOutTransactions: DepositOutTransactionInit[]
) => {
    const ParsedDepositInTransactions: AntDFormStateWithSplitFlag[] =
        DepositOutTransactions.map((currentDepositOutTransaction) => {
            return {
                formData: [
                    { name: "Amount", value: currentDepositOutTransaction.amount },
                    { name: "Note", value: currentDepositOutTransaction.memo },
                    {
                        name: "Tender split",
                        value: currentDepositOutTransaction.account_split![0]
                            .account_id,
                    },
                ],
                formID: Math.random().toString(),
            };
        });
    ParsedDepositInTransactions.push({
        formData: [],
        formID: Math.random().toString(),
    });
    return ParsedDepositInTransactions;
};

export const initialPaymentSplitData = (tenderAccounts: ExtendedAccountItem[]) => {
    const CashTenderAccount = tenderAccounts.find(
        (currentTenderAccount) => currentTenderAccount.name.toLowerCase() === "cash"
    );
    const ChequeTenderAccount = tenderAccounts.find(
        (currentTenderAccount) =>
            currentTenderAccount.name.toLowerCase() === "cheque"
    );
    if (CashTenderAccount && ChequeTenderAccount) {
        return [
            { name: CashTenderAccount?.name, value: 0 },
            { name: ChequeTenderAccount?.name, value: 0 },
        ];
    } else if (CashTenderAccount) {
        return [{ name: CashTenderAccount?.name, value: 0 }];
    } else if (ChequeTenderAccount) {
        return [{ name: ChequeTenderAccount?.name, value: 0 }];
    } else {
        return [];
    }
};

const TransactionsFormPOSComponent: React.FC<Props> = ({
    onModalClose,
    accountsData,
    classesData,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
    onDepositInTransactionSubmission,
    onDepositOutTransactionSubmission,
    PaymentTransactionData,
    DepositInTransactionData,
    DepositOutTransactionData,
    TransferTransactionsReadOnly,
    TransferTransactions,
    onTransferTransactionSubmission,
    hierarchicalLocations,
    currentLocationID,
    venuesData,
}) => {
    const tenderAccounts = useMemo(
        () =>
            accountsData.filter(
                (currentAccountItem) => currentAccountItem.tender_type
            ),
        [accountsData]
    );
    const [selectedVenue] = useLocalStorage<string | null>("selectedVenue", null);
    const currentVenue = useMemo(() => {
        if (
            selectedVenue &&
            venuesData.find(({ venue_id }) => venue_id === selectedVenue)
        ) {
            return venuesData.find(({ venue_id }) => venue_id === selectedVenue!);
        } else {
            return undefined;
        }
    }, [selectedVenue, venuesData]);

    const glAccountGroupsByType = currentVenue?.venueglaccount_set.reduce<
        GlAccountByType[]
    >((acc, currentGlAccount, index) => {
        const matchGroup = acc.find(
            (acc) => acc.type === currentGlAccount.gl_account.type
        );
        if (index === 0 || !matchGroup) {
            acc.push({
                type: currentGlAccount.gl_account.type,
                glAccounts: [currentGlAccount.gl_account],
            });
        } else {
            matchGroup.glAccounts.push(currentGlAccount.gl_account);
        }
        return acc;
    }, []);

    const venueNameById = useMemo(() => {
        if (venuesData)
            return venuesData.reduce<{ [venueID: string]: string }>(
                (accumulator, current) => {
                    accumulator[current.venue_id] = current.name;

                    return accumulator;
                },
                {}
            );
    }, [venuesData]);

    // Appends a randomly generated ID to each form to ensure uniqueIDs.
    const initialPaymentTransactionsDataWithAppendedFormID: AntDFormState[] =
        useMemo(
            () =>
                PaymentTransactionData.length > 0
                    ? PaymentTransactionParser(
                          PaymentTransactionData,
                          tenderAccounts
                      )
                    : [
                          {
                              formData: [],
                              formID: Math.random().toString(),
                              SplitData: initialPaymentSplitData(tenderAccounts),
                          },
                      ],
            []
        );

    const initialDepositInTransactionsDataWithAppendedFormID: AntDFormStateWithSplitFlag[] =
        useMemo(
            () =>
                DepositInTransactionData.length > 0
                    ? DepositInTransactionParse(DepositInTransactionData)
                    : [
                          {
                              formData: [],
                              formID: Math.random().toString(),
                          },
                      ],
            []
        );

    const initialDepositOutTransactionsDataWithAppendedFormID: AntDFormStateWithSplitFlag[] =
        useMemo(
            () =>
                DepositOutTransactionData.length > 0
                    ? DepositOutTransactionParse(DepositOutTransactionData)
                    : [
                          {
                              formData: [],
                              formID: Math.random().toString(),
                          },
                      ],
            []
        );

    // Appends a randomly generated ID to each form to ensure uniqueIDs.
    const initialTransfersTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactions.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactions,
                          currentLocationID
                      )
                    : [
                          {
                              formData: [{ name: "From", value: currentLocationID }],
                              formID: Math.random().toString(),
                          },
                      ],
            []
        );

    const processTransformLocations = useCallback(
        (isReadonly: boolean) => {
            const result: ExtendedTreeData[] = [],
                floatLocations: ExtendedLocationItemWithChildren[] = [];
            hierarchicalLocations.forEach((location, index) => {
                if (location.location_type !== 14) {
                    if (location.venue === selectedVenue)
                        result.push({
                            key: index,
                            title: appendVenueName(location, venueNameById),
                            value: location.location_id,
                            children: location.sub_locations
                                ? isReadonly
                                    ? extractSubLocation(
                                          location.sub_locations,
                                          location.name
                                      ).sort((subLocationA, subLocationB) =>
                                          subLocationA.title.localeCompare(
                                              subLocationB.title
                                          )
                                      )
                                    : extractSubLocation(
                                          location.sub_locations
                                      ).sort((subLocationA, subLocationB) =>
                                          subLocationA.title.localeCompare(
                                              subLocationB.title
                                          )
                                      )
                                : [],
                            disabled: checkForSublocations(location),
                            venue: location.venue,
                        });
                } else {
                    floatLocations.push(location);
                }
            });
            if (floatLocations.length !== 0) {
                const floatLocationsChildren = extractSubLocation(
                    floatLocations
                ).sort((subLocationA, subLocationB) =>
                    subLocationA.title.localeCompare(subLocationB.title)
                );

                result.forEach((location) => {
                    if (location.title.includes("Safe")) {
                        const itemsWithMatchingVenueId =
                            floatLocationsChildren.filter(
                                (item) => item.venue === location.venue
                            );
                        location.children = location.children?.concat(
                            itemsWithMatchingVenueId
                        );
                        location.disabled = true;
                    }
                });
            }
            return result;
        },
        [hierarchicalLocations]
    );
    const paymentFields: Field[] = [
        {
            name: "GL Code",
            required: true,
            type: {
                name: "DropdownOrNull",
            },
            colSpan: 8,
        },
        {
            name: "Class",
            type: {
                name: "Dropdown",
                options: classesData.map((currentClass) => ({
                    label: currentClass.name,
                    value: currentClass.class_id,
                })),
            },
            colSpan: 4,
        },
        {
            name: "Description",
            required: true,
            type: {
                name: "Text",
            },
            colSpan: 6,
        },
        {
            name: "Amount",
            required: true,
            type: { name: "Money" },
            colSpan: 4,
            changeGST: true,
        },
        {
            name: "GST",
            type: { name: "GST" },
            colSpan: 3,
        },

        {
            name: "Tender split",
            type: {
                name: "Modal",
            },
            colSpan: 4,
        },
        { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
    ];

    const transformLocations = useMemo(
        () => processTransformLocations(true),
        [processTransformLocations]
    );
    const transformLocationsReadonly = useMemo(
        () => processTransformLocations(true),
        [processTransformLocations]
    );
    const transferFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                type: {
                    name: "TreeSelectLocked",
                    locations: transformLocations.sort((locationA, locationB) =>
                        locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },
                colSpan: 8,
            },
            {
                name: "To",
                required: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocations.sort((locationA, locationB) =>
                        locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
            { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
        ],
        [currentLocationID, transformLocations]
    );
    const transferReadOnlyFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                disabled: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                    key: currentLocationID,
                },

                colSpan: 8,
            },
            {
                name: "To",
                disabled: true,
                required: true,

                type: {
                    name: "TreeSelect",
                    locations: transformLocationsReadonly.sort(
                        (locationA, locationB) =>
                            locationA.title.localeCompare(locationB.title)
                    ),
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                disabled: true,
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
        ],
        [currentLocationID, transformLocationsReadonly]
    );

    const initialTransfersReadOnlyTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(
            () =>
                TransferTransactionsReadOnly.length > 0
                    ? TransfersTransactionParser(
                          TransferTransactionsReadOnly,
                          currentLocationID,
                          true
                      )
                    : [],
            []
        );
    return (
        <StyledFormContainer>
            <Collapse accordion expandIconPosition="end">
                <Panel
                    header={<StyledPanelHeader>Payments</StyledPanelHeader>}
                    key="1"
                >
                    <Typography
                        style={{ color: "#626E84", marginBottom: level2Spacing }}
                    >
                        Payments will include petty cash and other expenses
                    </Typography>

                    <PaymentModalFormV2
                        fields={paymentFields}
                        onModalClose={() => null}
                        initialDataForForms={
                            initialPaymentTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onSubmit={onPaymentTransactionSubmission}
                        onRemove={onPaymentTransactionRemove}
                        currentVenue={currentVenue}
                        glAccountsByType={glAccountGroupsByType!}
                    />
                </Panel>
                <Panel
                    header={<StyledPanelHeader>Deposits</StyledPanelHeader>}
                    key="2"
                >
                    <Typography
                        style={{ color: "#626E84", marginBottom: level2Spacing }}
                    >
                        {" "}
                        Record deposits not entered in POS here
                    </Typography>
                    <Typography
                        style={{
                            color: "#626E84",
                            marginBottom: level2Spacing,
                            fontSize: 11,
                            fontWeight: "bold",
                        }}
                    >
                        DEPOSITS IN
                    </Typography>
                    <DepositInModalFormV2
                        fields={depositInFields}
                        initialDataForForms={
                            initialDepositInTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onChange={onDepositInTransactionSubmission}
                    />
                    <Typography
                        style={{
                            color: "#626E84",
                            marginBottom: level2Spacing,
                            marginTop: level3Spacing,
                            fontSize: 11,
                            fontWeight: "bold",
                        }}
                    >
                        DEPOSITS OUT
                    </Typography>
                    <DepositOutModalFormV2
                        fields={depositOutFields}
                        initialDataForForms={
                            initialDepositOutTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onChange={onDepositOutTransactionSubmission}
                    />
                </Panel>

                <Panel
                    header={<StyledPanelHeader>Transfers From</StyledPanelHeader>}
                    key="4"
                >
                    <TransferModalFormV2
                        currentLocationID={currentLocationID}
                        fields={transferFields}
                        initialDataForForms={
                            initialTransfersTransactionsDataWithAppendedFormID
                        }
                        onChange={onTransferTransactionSubmission}
                    />
                </Panel>
                {initialTransfersReadOnlyTransactionsDataWithAppendedFormID.length >
                    0 && (
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="5"
                    >
                        <TransferModalFormV2
                            fields={transferReadOnlyFields}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                        />
                    </Panel>
                )}
            </Collapse>
            <ModalFooter
                onClose={onModalClose}
                onSave={onModalClose}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
        </StyledFormContainer>
    );
};

export const TransactionsFormPOS = React.memo(TransactionsFormPOSComponent);
